import {Component, Input, OnInit} from '@angular/core';
import {Notification} from '../../../generated/graphql';
import {SharedService} from '../shared.service';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent implements OnInit {

  @Input()
  notification: Notification;
  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
  }

  readNotification(id: string){
    this.sharedService.markNotification({ids : [id]}).subscribe(({data}) => {
      console.log(data)
    })
  }

}
