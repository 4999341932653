import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {ApolloModule, Apollo, APOLLO_OPTIONS, APOLLO_FLAGS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache, ApolloLink, ApolloClientOptions} from '@apollo/client/core';
import {setContext} from '@apollo/client/link/context';
import {environment} from '../environments/environment';
import {onError} from '@apollo/client/link/error';
import {MessageService} from 'primeng/api';

const uri = environment.apiUrl;

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));

  const auth = setContext((operation, context) => {
    const token = localStorage.getItem('token');

    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }
  });

  const error = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors)
      graphQLErrors.map(({message, locations, path}) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });
  const link = ApolloLink.from([basic, auth, error, httpLink.create({uri})]);
  const cache = new InMemoryCache();

  return {
    link,
    cache,
    defaultOptions: {
      mutate: {
        errorPolicy: 'all'
      },
      watchQuery: {
        // notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
      },
    }
  }
}

@NgModule({
  exports: [
    HttpClientModule,
    ApolloModule,
  ],
  providers: [{
    provide: APOLLO_FLAGS,
    useValue: {
      useMutationLoading: true, // enable it here
    },
  },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink]
    },
    MessageService]
})
export class GraphQLModule {

  constructor(private messageService: MessageService) {
  }
}
