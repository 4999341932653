import {Component, inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from './auth/auth.service';
import {Observable} from 'rxjs';
import {ToastModule} from 'primeng/toast';
import {RouterOutlet} from '@angular/router';
import {PrimeNGConfig} from "primeng/api";
import {Aura} from "primeng/themes/aura";
import {definePreset} from 'primeng/themes';

const MyPreset = definePreset(Aura, {
  primitive: {
    borderRadius: {
      none: 0,
      xs: 10,
      sm: 10,
      md: 10,
      lg: 10,
      xl: 10,
    }
  },
  semantic: {
    colorScheme: {
      light: {
        primary: {
          color: '#817ccd',
        },
        formField: {
          background: '#F6F7FB',
        },
        text: {
          color: '#4e4b75'
        },
      },
    },
    primary: {
      "50": "#f2f2fc",
      "100": "#e0e0f9",
      "200": "#c7c7f2",
      "300": "#afafeb",
      "400": "#9e9ee5",
      "500": "#817ccd",
      "600": "#6e6ab8",
      "700": "#5c599e",
      "800": "#4b4785",
      "900": "#3a366b",
      "950": "#292552"
    }
  }
});


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [ToastModule, RouterOutlet]
})
export class AppComponent implements OnInit{
  title = 'Covento Planning';
  isAuth$!: Observable<boolean>;
  private config: PrimeNGConfig = inject(PrimeNGConfig)
  constructor(translate: TranslateService, private authService: AuthService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('de');

    this.config.theme.set({
      preset: MyPreset,
      options: {
        prefix: 'p',
        darkModeSelector: 'system',
        cssLayer: false
      }
    })

    this.config.zIndex = {
      modal: 1100,    // dialog, sidebar
      overlay: 1000,  // dropdown, overlaypanel
      menu: 1000,     // overlay menus
      tooltip: 1100   // tooltip
    };
  }

  ngOnInit() {
    this.isAuth$ = this.authService.isAuthenticated;
  }
}
