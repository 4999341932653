import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {Notification, User} from '../../../generated/graphql';
import {SharedService} from '../../shared/shared.service';
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  me: User;
  loading = true;
  platformUrl : string = environment.marketPlaceUrl;
  myCoventoUrl : string = environment.myCoventoUrl;
  notifications: Notification[];
  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
  private router: Router,
  private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.authService.me().subscribe(({data, loading}) => {
      this.me = data.me as User;
      this.loading = loading;
    })
    this.sharedService.getNotifications().subscribe(({data}) => {
      this.notifications = [...data.notifications as Notification[]]
    })
  }

  readNotifications(notifications: Notification []){
    this.sharedService.markNotification({ids : notifications.map((value => value.id))}).subscribe(({data}) => {
      console.log(data)
    })
  }

  onLogOut(){
    this.authService.logout();
    localStorage.removeItem('token');
    this.router.navigate(['/auth/login'])
  }

}
