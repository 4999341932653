import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  UUID: any;
};

export type AcceptTermsAndConditionsPayload = {
  __typename?: 'AcceptTermsAndConditionsPayload';
  account?: Maybe<Account>;
};

export type Account = {
  __typename?: 'Account';
  aboutUs?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  branches: Array<Branch>;
  customers: Array<CoventoCustomer>;
  customersRequests: Array<CustomerRequest>;
  discriminator: Scalars['String'];
  email: Scalars['String'];
  employees: Array<Employee>;
  events: Array<Event>;
  hrAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions: Array<AccountPermission>;
  personioUser: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  platformAccess: Scalars['Boolean'];
  readChats: Array<QuoteChat>;
  status: AccountStatus;
  termsAndConditionsAccepted: Scalars['Boolean'];
  termsAndConditionsUrl?: Maybe<Scalars['String']>;
  users: Array<User>;
  ventoWorkAccess: Scalars['Boolean'];
};

export type AccountFilterInput = {
  aboutUs?: InputMaybe<StringOperationFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<AccountFilterInput>>;
  branches?: InputMaybe<ListFilterInputTypeOfBranchFilterInput>;
  customers?: InputMaybe<ListFilterInputTypeOfCoventoCustomerFilterInput>;
  customersRequests?: InputMaybe<ListFilterInputTypeOfCustomerRequestFilterInput>;
  discriminator?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  events?: InputMaybe<ListFilterInputTypeOfEventFilterInput>;
  hrAccess?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  logoUrl?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AccountFilterInput>>;
  permissions?: InputMaybe<ListFilterInputTypeOfAccountPermissionFilterInput>;
  personioUser?: InputMaybe<BooleanOperationFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  platformAccess?: InputMaybe<BooleanOperationFilterInput>;
  readChats?: InputMaybe<ListFilterInputTypeOfQuoteChatFilterInput>;
  status?: InputMaybe<AccountStatusOperationFilterInput>;
  termsAndConditionsAccepted?: InputMaybe<BooleanOperationFilterInput>;
  termsAndConditionsUrl?: InputMaybe<StringOperationFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  ventoWorkAccess?: InputMaybe<BooleanOperationFilterInput>;
};

export type AccountPermission = {
  __typename?: 'AccountPermission';
  account: Account;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  scope: AccountPermissionScope;
  target: NotificationTarget;
  updatedBy?: Maybe<User>;
};

export type AccountPermissionFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<AccountPermissionFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AccountPermissionFilterInput>>;
  scope?: InputMaybe<AccountPermissionScopeOperationFilterInput>;
  target?: InputMaybe<NotificationTargetOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export enum AccountPermissionScope {
  CreateCustomers = 'CREATE_CUSTOMERS',
  CreateEvents = 'CREATE_EVENTS',
  CreateLocations = 'CREATE_LOCATIONS',
  CreateQuotes = 'CREATE_QUOTES',
  CustomerManagement = 'CUSTOMER_MANAGEMENT',
  DocumentManagement = 'DOCUMENT_MANAGEMENT',
  EmployeeManagement = 'EMPLOYEE_MANAGEMENT',
  EventManagement = 'EVENT_MANAGEMENT',
  LocationManagement = 'LOCATION_MANAGEMENT',
  QuoteManagement = 'QUOTE_MANAGEMENT'
}

export type AccountPermissionScopeOperationFilterInput = {
  eq?: InputMaybe<AccountPermissionScope>;
  in?: InputMaybe<Array<AccountPermissionScope>>;
  neq?: InputMaybe<AccountPermissionScope>;
  nin?: InputMaybe<Array<AccountPermissionScope>>;
};

export type AccountSortInput = {
  aboutUs?: InputMaybe<SortEnumType>;
  address?: InputMaybe<AddressSortInput>;
  discriminator?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  hrAccess?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  logoUrl?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  personioUser?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  platformAccess?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  termsAndConditionsAccepted?: InputMaybe<SortEnumType>;
  termsAndConditionsUrl?: InputMaybe<SortEnumType>;
  ventoWorkAccess?: InputMaybe<SortEnumType>;
};

export enum AccountStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Inactive = 'INACTIVE',
  Invited = 'INVITED'
}

export type AccountStatusOperationFilterInput = {
  eq?: InputMaybe<AccountStatus>;
  in?: InputMaybe<Array<AccountStatus>>;
  neq?: InputMaybe<AccountStatus>;
  nin?: InputMaybe<Array<AccountStatus>>;
};

export type AddAllRounderToEmployeesPayload = {
  __typename?: 'AddAllRounderToEmployeesPayload';
  int?: Maybe<Scalars['Int']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  formattedAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<User>;
  zip?: Maybe<Scalars['String']>;
};

export type AddressFilterInput = {
  and?: InputMaybe<Array<AddressFilterInput>>;
  city?: InputMaybe<StringOperationFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  formattedAddress?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  latitude?: InputMaybe<ComparableNullableOfSingleOperationFilterInput>;
  longitude?: InputMaybe<ComparableNullableOfSingleOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AddressFilterInput>>;
  street?: InputMaybe<StringOperationFilterInput>;
  streetNumber?: InputMaybe<StringOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
  zip?: InputMaybe<StringOperationFilterInput>;
};

export type AddressSortInput = {
  city?: InputMaybe<SortEnumType>;
  country?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  formattedAddress?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  latitude?: InputMaybe<SortEnumType>;
  longitude?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  street?: InputMaybe<SortEnumType>;
  streetNumber?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
  zip?: InputMaybe<SortEnumType>;
};

export type AdminLoginInput = {
  email: Scalars['String'];
};

export type AdminLoginPayload = {
  __typename?: 'AdminLoginPayload';
  string?: Maybe<Scalars['String']>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export type AvailableShiftsModel = {
  __typename?: 'AvailableShiftsModel';
  currentQuantity: Scalars['Int'];
  requiredQuantity: Scalars['Int'];
  shifts: Array<Shift>;
  title: EmployeeSkill;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export type Branch = {
  __typename?: 'Branch';
  account: Account;
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  quoteChats: Array<QuoteChat>;
  users: Array<User>;
};


export type BranchQuoteChatsArgs = {
  where?: InputMaybe<QuoteChatFilterInput>;
};

export type BranchFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<BranchFilterInput>>;
  email?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BranchFilterInput>>;
  phone?: InputMaybe<StringOperationFilterInput>;
  quoteChats?: InputMaybe<ListFilterInputTypeOfQuoteChatFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
};

export type BranchSortInput = {
  account?: InputMaybe<AccountSortInput>;
  address?: InputMaybe<AddressSortInput>;
  email?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
};

export type CancelSettings = {
  __typename?: 'CancelSettings';
  account: Account;
  accountId: Scalars['UUID'];
  branch?: Maybe<Branch>;
  branchId?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  email: Scalars['String'];
  fromFriday?: Maybe<Scalars['DateTime']>;
  fromMonday?: Maybe<Scalars['DateTime']>;
  fromSaturday?: Maybe<Scalars['DateTime']>;
  fromSunday?: Maybe<Scalars['DateTime']>;
  fromThursday?: Maybe<Scalars['DateTime']>;
  fromTuesday?: Maybe<Scalars['DateTime']>;
  fromWednesday?: Maybe<Scalars['DateTime']>;
  hoursBeforeEvent: Scalars['Float'];
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  phone: Scalars['String'];
  toFriday?: Maybe<Scalars['DateTime']>;
  toMonday?: Maybe<Scalars['DateTime']>;
  toSaturday?: Maybe<Scalars['DateTime']>;
  toSunday?: Maybe<Scalars['DateTime']>;
  toThursday?: Maybe<Scalars['DateTime']>;
  toTuesday?: Maybe<Scalars['DateTime']>;
  toWednesday?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
};

export type CancelSettingsFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  accountId?: InputMaybe<ComparableGuidOperationFilterInput>;
  and?: InputMaybe<Array<CancelSettingsFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  fromFriday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  fromMonday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  fromSaturday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  fromSunday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  fromThursday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  fromTuesday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  fromWednesday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  hoursBeforeEvent?: InputMaybe<ComparableDoubleOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CancelSettingsFilterInput>>;
  phone?: InputMaybe<StringOperationFilterInput>;
  toFriday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  toMonday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  toSaturday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  toSunday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  toThursday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  toTuesday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  toWednesday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type CanceledShift = {
  __typename?: 'CanceledShift';
  account: Account;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  employee: Employee;
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  shift: Shift;
  updatedBy?: Maybe<User>;
};

export type CanceledShiftFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<CanceledShiftFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isRead?: InputMaybe<BooleanOperationFilterInput>;
  message?: InputMaybe<StringOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CanceledShiftFilterInput>>;
  shift?: InputMaybe<ShiftFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export enum ChatCloseReason {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Revoked = 'REVOKED'
}

export type CheckInListComment = {
  __typename?: 'CheckInListComment';
  comment: Scalars['String'];
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  event: Event;
  eventId: Scalars['UUID'];
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  section?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<User>;
};

export type CheckInListCommentFilterInput = {
  and?: InputMaybe<Array<CheckInListCommentFilterInput>>;
  comment?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  eventId?: InputMaybe<ComparableGuidOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CheckInListCommentFilterInput>>;
  section?: InputMaybe<StringOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type CheckInListCommentSortInput = {
  comment?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  event?: InputMaybe<EventSortInput>;
  eventId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  section?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export type CheckInListModel = {
  __typename?: 'CheckInListModel';
  deployments: Array<Deployment>;
  event: Event;
  listDate: Scalars['DateTime'];
};

export type ChildShiftListModel = {
  __typename?: 'ChildShiftListModel';
  account: Scalars['String'];
  branch: Scalars['String'];
  quantity: Scalars['Int'];
  shiftId: Scalars['ID'];
  type: ShiftDispatchType;
};

export type ComparableByteOperationFilterInput = {
  eq?: InputMaybe<Scalars['Byte']>;
  gt?: InputMaybe<Scalars['Byte']>;
  gte?: InputMaybe<Scalars['Byte']>;
  in?: InputMaybe<Array<Scalars['Byte']>>;
  lt?: InputMaybe<Scalars['Byte']>;
  lte?: InputMaybe<Scalars['Byte']>;
  neq?: InputMaybe<Scalars['Byte']>;
  ngt?: InputMaybe<Scalars['Byte']>;
  ngte?: InputMaybe<Scalars['Byte']>;
  nin?: InputMaybe<Array<Scalars['Byte']>>;
  nlt?: InputMaybe<Scalars['Byte']>;
  nlte?: InputMaybe<Scalars['Byte']>;
};

export type ComparableDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<Scalars['DateTime']>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<Scalars['Float']>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type ComparableGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<Scalars['UUID']>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<Scalars['UUID']>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<Scalars['Int']>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  neq?: InputMaybe<Scalars['Decimal']>;
  ngt?: InputMaybe<Scalars['Decimal']>;
  ngte?: InputMaybe<Scalars['Decimal']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  nlt?: InputMaybe<Scalars['Decimal']>;
  nlte?: InputMaybe<Scalars['Decimal']>;
};

export type ComparableNullableOfDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type ComparableNullableOfGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ComparableNullableOfSingleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type CompleteCheckInListInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CompleteCheckInListPayload = {
  __typename?: 'CompleteCheckInListPayload';
  event?: Maybe<Event>;
};

export type CompletePersonioEventInput = {
  eventId?: InputMaybe<Scalars['ID']>;
};

export type CompletePersonioEventPayload = {
  __typename?: 'CompletePersonioEventPayload';
  updateEventStatusResponseModel?: Maybe<UpdateEventStatusResponseModel>;
};

export type ConfirmCheckInListInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ConfirmCheckInListPayload = {
  __typename?: 'ConfirmCheckInListPayload';
  event?: Maybe<Event>;
};

export type CoventoCustomer = {
  __typename?: 'CoventoCustomer';
  account: Account;
  address?: Maybe<Address>;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  customerBranch: Branch;
  customerBranchId?: Maybe<Scalars['ID']>;
  customerPrices: Array<CustomerPrice>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  organizer?: Maybe<Organizer>;
  organizerId?: Maybe<Scalars['ID']>;
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviderId?: Maybe<Scalars['ID']>;
  updatedBy?: Maybe<User>;
};

export type CoventoCustomerFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<CoventoCustomerFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customerBranch?: InputMaybe<BranchFilterInput>;
  customerBranchId?: InputMaybe<ComparableGuidOperationFilterInput>;
  customerPrices?: InputMaybe<ListFilterInputTypeOfCustomerPriceFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CoventoCustomerFilterInput>>;
  organizer?: InputMaybe<OrganizerFilterInput>;
  organizerId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  serviceProvider?: InputMaybe<ServiceProviderFilterInput>;
  serviceProviderId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export enum CoventoOrigin {
  Internal = 'INTERNAL',
  Platform = 'PLATFORM',
  SelfCreated = 'SELF_CREATED'
}

export type CoventoOriginOperationFilterInput = {
  eq?: InputMaybe<CoventoOrigin>;
  in?: InputMaybe<Array<CoventoOrigin>>;
  neq?: InputMaybe<CoventoOrigin>;
  nin?: InputMaybe<Array<CoventoOrigin>>;
};

export type CreateAccountInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  hrAccess: Scalars['Boolean'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  permissionInputs: Array<CreateAccountPermissionInput>;
  platformAccess: Scalars['Boolean'];
  type: Scalars['String'];
  ventoWorkAccess: Scalars['Boolean'];
};

export type CreateAccountPayload = {
  __typename?: 'CreateAccountPayload';
  account?: Maybe<Account>;
};

export type CreateAccountPermissionInput = {
  scope: AccountPermissionScope;
  target: NotificationTarget;
};

export type CreateAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  formattedAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type CreateBranchInput = {
  address?: InputMaybe<CreateAddressInput>;
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateBranchPayload = {
  __typename?: 'CreateBranchPayload';
  branch?: Maybe<Branch>;
};

export type CreateCancelSettingsInput = {
  branch: Scalars['ID'];
  email: Scalars['String'];
  fromFriday?: InputMaybe<Scalars['DateTime']>;
  fromMonday?: InputMaybe<Scalars['DateTime']>;
  fromSaturday?: InputMaybe<Scalars['DateTime']>;
  fromSunday?: InputMaybe<Scalars['DateTime']>;
  fromThursday?: InputMaybe<Scalars['DateTime']>;
  fromTuesday?: InputMaybe<Scalars['DateTime']>;
  fromWednesday?: InputMaybe<Scalars['DateTime']>;
  hoursBeforeEvent: Scalars['Float'];
  phone: Scalars['String'];
  toFriday?: InputMaybe<Scalars['DateTime']>;
  toMonday?: InputMaybe<Scalars['DateTime']>;
  toSaturday?: InputMaybe<Scalars['DateTime']>;
  toSunday?: InputMaybe<Scalars['DateTime']>;
  toThursday?: InputMaybe<Scalars['DateTime']>;
  toTuesday?: InputMaybe<Scalars['DateTime']>;
  toWednesday?: InputMaybe<Scalars['DateTime']>;
};

export type CreateCanceledShiftInput = {
  deploymentId?: InputMaybe<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
};

export type CreateCanceledShiftPayload = {
  __typename?: 'CreateCanceledShiftPayload';
  canceledShift?: Maybe<CanceledShift>;
};

export type CreateCheckInListCommentInput = {
  comment: Scalars['String'];
  eventId: Scalars['ID'];
  section?: InputMaybe<Scalars['String']>;
};

export type CreateCheckInListCommentPayload = {
  __typename?: 'CreateCheckInListCommentPayload';
  checkInListComment?: Maybe<CheckInListComment>;
};

export type CreateCheckInMastersInput = {
  employeeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  eventId?: InputMaybe<Scalars['ID']>;
};

export type CreateCheckInMastersPayload = {
  __typename?: 'CreateCheckInMastersPayload';
  string?: Maybe<Scalars['String']>;
};

export type CreateCustomQuoteItemInput = {
  description: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
};

export type CreateCustomerInput = {
  accountRefId?: InputMaybe<Scalars['ID']>;
  branchId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  prices: Array<CreateCustomerPriceInput>;
};

export type CreateCustomerPayload = {
  __typename?: 'CreateCustomerPayload';
  customer?: Maybe<Customer>;
};

export type CreateCustomerPriceInput = {
  price: Scalars['Float'];
  skill: EmployeeSkill;
};

export type CreateCustomerRequestInput = {
  accountRefId?: InputMaybe<Scalars['ID']>;
  branchId?: InputMaybe<Scalars['ID']>;
  prices: Array<CreateCustomerPriceInput>;
};

export type CreateCustomerRequestPayload = {
  __typename?: 'CreateCustomerRequestPayload';
  customerRequest?: Maybe<CustomerRequest>;
};

export type CreateDeploymentInput = {
  employeeId?: InputMaybe<Scalars['ID']>;
  eventId?: InputMaybe<Scalars['ID']>;
  shiftId?: InputMaybe<Scalars['ID']>;
};

export type CreateDeploymentPayload = {
  __typename?: 'CreateDeploymentPayload';
  deployment?: Maybe<Deployment>;
};

export type CreateDocumentTypeInput = {
  hasExpiration: Scalars['Boolean'];
  name: Scalars['String'];
  relationships: Array<EmploymentRelationship>;
};

export type CreateDocumentTypePayload = {
  __typename?: 'CreateDocumentTypePayload';
  documentType?: Maybe<DocumentType>;
};

export type CreateEmployeeInput = {
  address?: InputMaybe<CreateAddressInput>;
  branchId?: InputMaybe<Scalars['ID']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  employmentRelationship?: InputMaybe<EmploymentRelationship>;
  firstName: Scalars['String'];
  gender?: InputMaybe<Gender>;
  lastName: Scalars['String'];
  parentEmail?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  salary?: InputMaybe<Scalars['Decimal']>;
  skills: Array<EmployeeSkill>;
  socialSecurityNumber?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type CreateEmployeePayload = {
  __typename?: 'CreateEmployeePayload';
  employee?: Maybe<Employee>;
};

export type CreateEmployeeTeamInput = {
  branchId?: InputMaybe<Scalars['ID']>;
  employees?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name: Scalars['String'];
};

export type CreateEmployeeTeamPayload = {
  __typename?: 'CreateEmployeeTeamPayload';
  employeeTeam?: Maybe<EmployeeTeam>;
};

export type CreateEventHelpRequestInput = {
  branchId?: InputMaybe<Scalars['ID']>;
  quantity: Scalars['Int'];
  shiftId?: InputMaybe<Scalars['ID']>;
};

export type CreateEventHelpRequestPayload = {
  __typename?: 'CreateEventHelpRequestPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type CreateEventInput = {
  addressInput: CreateAddressInput;
  addressNotes?: InputMaybe<Scalars['String']>;
  branchId: Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
  createShiftInputs: Array<CreateShiftInput>;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  isInternal: Scalars['Boolean'];
  locationId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  numberOfGuests: Scalars['Int'];
  orderType?: InputMaybe<EventOrderType>;
  personioProjectId?: InputMaybe<Scalars['Int']>;
  personioProjectName?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  type: EventType;
  withTemplate: Scalars['Boolean'];
};

export type CreateEventLocationInput = {
  addressInput: CreateAddressInput;
  floors: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateEventLocationPayload = {
  __typename?: 'CreateEventLocationPayload';
  eventLocation?: Maybe<EventLocation>;
};

export type CreateEventPayload = {
  __typename?: 'CreateEventPayload';
  event?: Maybe<Event>;
};

export type CreateEventRequestInput = {
  shiftId?: InputMaybe<Scalars['ID']>;
};

export type CreateEventRequestPayload = {
  __typename?: 'CreateEventRequestPayload';
  eventRequest?: Maybe<EventRequest>;
};

export type CreateEventTemplateInput = {
  addressInput: CreateAddressInput;
  addressNotes?: InputMaybe<Scalars['String']>;
  branchId?: InputMaybe<Scalars['ID']>;
  comment?: InputMaybe<Scalars['String']>;
  createShiftInputs: Array<CreateShiftInput>;
  description?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  numberOfGuests: Scalars['Int'];
  personioProjectId?: InputMaybe<Scalars['Int']>;
  personioProjectName?: InputMaybe<Scalars['String']>;
  templateName: Scalars['String'];
  type: EventType;
};

export type CreateEventTemplatePayload = {
  __typename?: 'CreateEventTemplatePayload';
  eventTemplate?: Maybe<EventTemplate>;
};

export type CreateQuoteInput = {
  branchId?: InputMaybe<Scalars['ID']>;
  createCustomQuoteItemInputs: Array<CreateCustomQuoteItemInput>;
  createQuoteItemInputs: Array<CreateQuoteItemInput>;
  eventId?: InputMaybe<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
};

export type CreateQuoteItemInput = {
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  shiftId?: InputMaybe<Scalars['ID']>;
  skill: EmployeeSkill;
};

export type CreateQuotePayload = {
  __typename?: 'CreateQuotePayload';
  quotation?: Maybe<Quotation>;
};

export type CreateQuoteRequestInput = {
  customerId?: InputMaybe<Scalars['ID']>;
  eventId?: InputMaybe<Scalars['ID']>;
  items: Array<CustomerItemsInput>;
  quoteId?: InputMaybe<Scalars['ID']>;
};

export type CreateQuoteRequestsInput = {
  inputs: Array<CreateQuoteRequestInput>;
};

export type CreateQuoteRequestsPayload = {
  __typename?: 'CreateQuoteRequestsPayload';
  quotation?: Maybe<Array<Quotation>>;
};

export type CreateShiftInput = {
  employees?: InputMaybe<Array<Scalars['ID']>>;
  endDate: Scalars['DateTime'];
  floorName?: InputMaybe<Scalars['String']>;
  requiredQuantity: Scalars['Int'];
  section?: InputMaybe<Scalars['String']>;
  skill: EmployeeSkill;
  startDate: Scalars['DateTime'];
};

export type CreateShiftsInput = {
  eventId?: InputMaybe<Scalars['ID']>;
  shiftInputs: Array<CreateShiftInput>;
};

export type CreateShiftsPayload = {
  __typename?: 'CreateShiftsPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type CreateUnsignedDocumentsInput = {
  eventId?: InputMaybe<Scalars['ID']>;
};

export type CreateUnsignedDocumentsPayload = {
  __typename?: 'CreateUnsignedDocumentsPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type CreateUserInput = {
  branchIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: Scalars['ID'];
};

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  user?: Maybe<User>;
};

export type CreateUserRoleInput = {
  name: Scalars['String'];
  permissionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateUserRolePayload = {
  __typename?: 'CreateUserRolePayload';
  userRole?: Maybe<UserRole>;
};

export type CreateUserRolePermissionInput = {
  input: CreateUserRolePermissionTargetInput;
};

export type CreateUserRolePermissionPayload = {
  __typename?: 'CreateUserRolePermissionPayload';
  userRolePermission?: Maybe<UserRolePermission>;
};

export type CreateUserRolePermissionTargetInput = {
  description: Scalars['String'];
  product: NotificationTarget;
  scope: UserRolePermissionScope;
  target: UserRolePermissionTarget;
};

export type Credentials = {
  __typename?: 'Credentials';
  id: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
};

export type CredentialsFilterInput = {
  and?: InputMaybe<Array<CredentialsFilterInput>>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<CredentialsFilterInput>>;
  password?: InputMaybe<StringOperationFilterInput>;
};

export type CredentialsSortInput = {
  id?: InputMaybe<SortEnumType>;
  password?: InputMaybe<SortEnumType>;
};

export type CustomQuoteItem = {
  __typename?: 'CustomQuoteItem';
  account: Account;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Order>;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  quotation?: Maybe<Quotation>;
  total: Scalars['Float'];
  updatedBy?: Maybe<User>;
};

export type CustomQuoteItemFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<CustomQuoteItemFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CustomQuoteItemFilterInput>>;
  order?: InputMaybe<OrderFilterInput>;
  price?: InputMaybe<ComparableDoubleOperationFilterInput>;
  quantity?: InputMaybe<ComparableInt32OperationFilterInput>;
  quotation?: InputMaybe<QuotationFilterInput>;
  total?: InputMaybe<ComparableDoubleOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type Customer = {
  __typename?: 'Customer';
  account: Account;
  address?: Maybe<Address>;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  customerPrices: Array<CustomerPrice>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<User>;
};

export type CustomerFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<CustomerFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customerPrices?: InputMaybe<ListFilterInputTypeOfCustomerPriceFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CustomerFilterInput>>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type CustomerItemsInput = {
  quantity: Scalars['Int'];
  shiftId?: InputMaybe<Scalars['ID']>;
};

export type CustomerPrice = {
  __typename?: 'CustomerPrice';
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  customers: Array<Customer>;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  price: Scalars['Float'];
  request?: Maybe<CustomerRequest>;
  skill: EmployeeSkill;
  updatedBy?: Maybe<User>;
};

export type CustomerPriceFilterInput = {
  and?: InputMaybe<Array<CustomerPriceFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customers?: InputMaybe<ListFilterInputTypeOfCustomerFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CustomerPriceFilterInput>>;
  price?: InputMaybe<ComparableDoubleOperationFilterInput>;
  request?: InputMaybe<CustomerRequestFilterInput>;
  skill?: InputMaybe<EmployeeSkillOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type CustomerRequest = {
  __typename?: 'CustomerRequest';
  account: Account;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  customerBranch?: Maybe<Branch>;
  customerPrices: Array<CustomerPrice>;
  id: Scalars['ID'];
  isChangeRequest: Scalars['Boolean'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  organizer?: Maybe<Organizer>;
  organizerCustomer?: Maybe<Customer>;
  organizerId?: Maybe<Scalars['UUID']>;
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviderCustomer?: Maybe<Customer>;
  serviceProviderId?: Maybe<Scalars['UUID']>;
  status: CustomerRequestStatus;
  updatedBy?: Maybe<User>;
};

export type CustomerRequestFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<CustomerRequestFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customerBranch?: InputMaybe<BranchFilterInput>;
  customerPrices?: InputMaybe<ListFilterInputTypeOfCustomerPriceFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isChangeRequest?: InputMaybe<BooleanOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CustomerRequestFilterInput>>;
  organizer?: InputMaybe<OrganizerFilterInput>;
  organizerCustomer?: InputMaybe<CustomerFilterInput>;
  organizerId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  serviceProvider?: InputMaybe<ServiceProviderFilterInput>;
  serviceProviderCustomer?: InputMaybe<CustomerFilterInput>;
  serviceProviderId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  status?: InputMaybe<CustomerRequestStatusOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export enum CustomerRequestStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type CustomerRequestStatusOperationFilterInput = {
  eq?: InputMaybe<CustomerRequestStatus>;
  in?: InputMaybe<Array<CustomerRequestStatus>>;
  neq?: InputMaybe<CustomerRequestStatus>;
  nin?: InputMaybe<Array<CustomerRequestStatus>>;
};

export type CustomerSortInput = {
  account?: InputMaybe<AccountSortInput>;
  address?: InputMaybe<AddressSortInput>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export type DeleteAccountSkillsInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type DeleteAccountSkillsPayload = {
  __typename?: 'DeleteAccountSkillsPayload';
  string?: Maybe<Scalars['String']>;
};

export type DeleteDeploymentInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type DeleteDeploymentPayload = {
  __typename?: 'DeleteDeploymentPayload';
  deployment?: Maybe<Deployment>;
};

export type DeleteDocumentTypeInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type DeleteDocumentTypePayload = {
  __typename?: 'DeleteDocumentTypePayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type DeleteEmployeeDocumentInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type DeleteEmployeeDocumentPayload = {
  __typename?: 'DeleteEmployeeDocumentPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type DeleteEmployeeTeamInput = {
  teamId?: InputMaybe<Scalars['ID']>;
};

export type DeleteEmployeeTeamPayload = {
  __typename?: 'DeleteEmployeeTeamPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type DeleteEventDocumentInput = {
  documentId: Scalars['ID'];
};

export type DeleteEventDocumentPayload = {
  __typename?: 'DeleteEventDocumentPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type DeleteEventRequestInput = {
  requestId?: InputMaybe<Scalars['ID']>;
};

export type DeleteEventRequestPayload = {
  __typename?: 'DeleteEventRequestPayload';
  string?: Maybe<Scalars['String']>;
};

export type DeleteEventTemplateInput = {
  templateId: Scalars['ID'];
};

export type DeleteEventTemplatePayload = {
  __typename?: 'DeleteEventTemplatePayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type DeleteShiftInput = {
  shiftId?: InputMaybe<Scalars['ID']>;
};

export type DeleteShiftPayload = {
  __typename?: 'DeleteShiftPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type Department = {
  __typename?: 'Department';
  account: Account;
  address?: Maybe<Address>;
  branch: Branch;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  employees: Array<Employee>;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedBy?: Maybe<User>;
};

export type DepartmentFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<DepartmentFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DepartmentFilterInput>>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type Deployment = {
  __typename?: 'Deployment';
  breakTime?: Maybe<Scalars['Int']>;
  checkInShift?: Maybe<Shift>;
  checkInTime?: Maybe<Scalars['DateTime']>;
  checkInTimeSetBy?: Maybe<Account>;
  checkOutTime?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  currentHours: Scalars['Float'];
  employee: Employee;
  employeeStatus: DeploymentEmployeeStatus;
  event: Event;
  eventId: Scalars['ID'];
  id: Scalars['ID'];
  isReserve: Scalars['Boolean'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  oldCheckInTime?: Maybe<Scalars['DateTime']>;
  oldCheckOutTime?: Maybe<Scalars['DateTime']>;
  parentEvent?: Maybe<Event>;
  parentEventId?: Maybe<Scalars['ID']>;
  pendingCheckInTime?: Maybe<Scalars['DateTime']>;
  pendingCheckOutTime?: Maybe<Scalars['DateTime']>;
  personioAttendanceId?: Maybe<Scalars['Int']>;
  personioContract?: Maybe<PersonioContract>;
  personioContractId?: Maybe<Scalars['UUID']>;
  providedBy: Account;
  shift: Shift;
  status: DeploymentStatus;
  updatedBy?: Maybe<User>;
  workedHours: Scalars['Float'];
};

export type DeploymentByMonthModel = {
  __typename?: 'DeploymentByMonthModel';
  month: Scalars['String'];
  totalDeployments: Scalars['Int'];
  totalHours: Scalars['Float'];
  year: Scalars['String'];
};

export enum DeploymentEmployeeStatus {
  Confirmed = 'CONFIRMED',
  NotShowUp = 'NOT_SHOW_UP',
  NoTimes = 'NO_TIMES',
  Pending = 'PENDING',
  Published = 'PUBLISHED',
  Scheduled = 'SCHEDULED'
}

export type DeploymentEmployeeStatusOperationFilterInput = {
  eq?: InputMaybe<DeploymentEmployeeStatus>;
  in?: InputMaybe<Array<DeploymentEmployeeStatus>>;
  neq?: InputMaybe<DeploymentEmployeeStatus>;
  nin?: InputMaybe<Array<DeploymentEmployeeStatus>>;
};

export type DeploymentFilterInput = {
  and?: InputMaybe<Array<DeploymentFilterInput>>;
  breakTime?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  checkInShift?: InputMaybe<ShiftFilterInput>;
  checkInTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  checkInTimeSetBy?: InputMaybe<AccountFilterInput>;
  checkOutTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  currentHours?: InputMaybe<ComparableDoubleOperationFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  employeeStatus?: InputMaybe<DeploymentEmployeeStatusOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  eventId?: InputMaybe<ComparableGuidOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isReserve?: InputMaybe<BooleanOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  oldCheckInTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  oldCheckOutTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<DeploymentFilterInput>>;
  parentEvent?: InputMaybe<EventFilterInput>;
  parentEventId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  pendingCheckInTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  pendingCheckOutTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  personioAttendanceId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  personioContract?: InputMaybe<PersonioContractFilterInput>;
  personioContractId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  providedBy?: InputMaybe<AccountFilterInput>;
  shift?: InputMaybe<ShiftFilterInput>;
  status?: InputMaybe<DeploymentStatusOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
  workedHours?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type DeploymentSortInput = {
  breakTime?: InputMaybe<SortEnumType>;
  checkInShift?: InputMaybe<ShiftSortInput>;
  checkInTime?: InputMaybe<SortEnumType>;
  checkInTimeSetBy?: InputMaybe<AccountSortInput>;
  checkOutTime?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  currentHours?: InputMaybe<SortEnumType>;
  employee?: InputMaybe<EmployeeSortInput>;
  employeeStatus?: InputMaybe<SortEnumType>;
  event?: InputMaybe<EventSortInput>;
  eventId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isReserve?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  oldCheckInTime?: InputMaybe<SortEnumType>;
  oldCheckOutTime?: InputMaybe<SortEnumType>;
  parentEvent?: InputMaybe<EventSortInput>;
  parentEventId?: InputMaybe<SortEnumType>;
  pendingCheckInTime?: InputMaybe<SortEnumType>;
  pendingCheckOutTime?: InputMaybe<SortEnumType>;
  personioAttendanceId?: InputMaybe<SortEnumType>;
  personioContract?: InputMaybe<PersonioContractSortInput>;
  personioContractId?: InputMaybe<SortEnumType>;
  providedBy?: InputMaybe<AccountSortInput>;
  shift?: InputMaybe<ShiftSortInput>;
  status?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
  workedHours?: InputMaybe<SortEnumType>;
};

export enum DeploymentStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Error = 'ERROR',
  Pending = 'PENDING',
  ReadyToTransmit = 'READY_TO_TRANSMIT',
  Released = 'RELEASED',
  Scheduled = 'SCHEDULED'
}

export type DeploymentStatusOperationFilterInput = {
  eq?: InputMaybe<DeploymentStatus>;
  in?: InputMaybe<Array<DeploymentStatus>>;
  neq?: InputMaybe<DeploymentStatus>;
  nin?: InputMaybe<Array<DeploymentStatus>>;
};

export type DeploymentsShiftModel = {
  __typename?: 'DeploymentsShiftModel';
  deployments: Array<Deployment>;
  shifts: Array<Shift>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  account: Account;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  employeeDocuments: Array<EmployeeDocument>;
  employmentRelations: Array<EmploymentRelationModel>;
  hasExpiration: Scalars['Boolean'];
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedBy?: Maybe<User>;
};

export type DocumentTypeFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<DocumentTypeFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  employeeDocuments?: InputMaybe<ListFilterInputTypeOfEmployeeDocumentFilterInput>;
  employmentRelations?: InputMaybe<ListFilterInputTypeOfEmploymentRelationModelFilterInput>;
  hasExpiration?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DocumentTypeFilterInput>>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type Employee = {
  __typename?: 'Employee';
  account: Account;
  accountId: Scalars['UUID'];
  address?: Maybe<Address>;
  branch: Branch;
  branchId: Scalars['ID'];
  checkInMasters: Array<Event>;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  credentials: Credentials;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  departments: Array<Department>;
  deployments: Array<Deployment>;
  documents: Array<EmployeeDocument>;
  email: Scalars['String'];
  employmentRelationship?: Maybe<EmploymentRelationship>;
  expoPushToken?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  gender?: Maybe<Gender>;
  id: Scalars['ID'];
  isOfAge: Scalars['Boolean'];
  lastName: Scalars['String'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  parentCode?: Maybe<Scalars['String']>;
  parentEmail?: Maybe<Scalars['String']>;
  parentSignatureUrl?: Maybe<Scalars['String']>;
  personioId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  preFilledShifts: Array<Shift>;
  requireDailyContract: Scalars['Boolean'];
  salary?: Maybe<Scalars['Decimal']>;
  signatureUrl?: Maybe<Scalars['String']>;
  skills: Array<EmployeeSkillModel>;
  socialSecurityNumber?: Maybe<Scalars['String']>;
  staffNumber: Scalars['Int'];
  status: EmployeeStatus;
  taxId?: Maybe<Scalars['String']>;
  teams: Array<EmployeeTeam>;
  updatedBy?: Maybe<User>;
};

export type EmployeeDashboardModel = {
  __typename?: 'EmployeeDashboardModel';
  completedDeployments: Scalars['Int'];
  nextDeployment?: Maybe<Deployment>;
  openRequests: Scalars['Int'];
  pendingDeployments: Scalars['Int'];
  publishedDeployments: Scalars['Int'];
  status?: Maybe<EmploymentRelationship>;
  workedHours: Scalars['Float'];
};

export type EmployeeDocument = {
  __typename?: 'EmployeeDocument';
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  employee: Employee;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  type: DocumentType;
  updatedBy?: Maybe<User>;
  url: Scalars['String'];
  validFrom?: Maybe<Scalars['DateTime']>;
  validTo?: Maybe<Scalars['DateTime']>;
};

export type EmployeeDocumentFilterInput = {
  and?: InputMaybe<Array<EmployeeDocumentFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EmployeeDocumentFilterInput>>;
  type?: InputMaybe<DocumentTypeFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
  url?: InputMaybe<StringOperationFilterInput>;
  validFrom?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  validTo?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type EmployeeDocumentModel = {
  __typename?: 'EmployeeDocumentModel';
  document?: Maybe<EmployeeDocument>;
  type: DocumentType;
};

export type EmployeeEventRequestGroupedModel = {
  __typename?: 'EmployeeEventRequestGroupedModel';
  assignmentProgress?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
  endDate?: Maybe<Scalars['DateTime']>;
  eventRequests: Array<EventRequest>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
};

export type EmployeeFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  accountId?: InputMaybe<ComparableGuidOperationFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<EmployeeFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableGuidOperationFilterInput>;
  checkInMasters?: InputMaybe<ListFilterInputTypeOfEventFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  credentials?: InputMaybe<CredentialsFilterInput>;
  dateOfBirth?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  departments?: InputMaybe<ListFilterInputTypeOfDepartmentFilterInput>;
  deployments?: InputMaybe<ListFilterInputTypeOfDeploymentFilterInput>;
  documents?: InputMaybe<ListFilterInputTypeOfEmployeeDocumentFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  employmentRelationship?: InputMaybe<NullableOfEmploymentRelationshipOperationFilterInput>;
  expoPushToken?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  gender?: InputMaybe<NullableOfGenderOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isOfAge?: InputMaybe<BooleanOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EmployeeFilterInput>>;
  parentCode?: InputMaybe<StringOperationFilterInput>;
  parentEmail?: InputMaybe<StringOperationFilterInput>;
  parentSignatureUrl?: InputMaybe<StringOperationFilterInput>;
  personioId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  pictureUrl?: InputMaybe<StringOperationFilterInput>;
  preFilledShifts?: InputMaybe<ListFilterInputTypeOfShiftFilterInput>;
  requireDailyContract?: InputMaybe<BooleanOperationFilterInput>;
  salary?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  signatureUrl?: InputMaybe<StringOperationFilterInput>;
  skills?: InputMaybe<ListFilterInputTypeOfEmployeeSkillModelFilterInput>;
  socialSecurityNumber?: InputMaybe<StringOperationFilterInput>;
  staffNumber?: InputMaybe<ComparableInt32OperationFilterInput>;
  status?: InputMaybe<EmployeeStatusOperationFilterInput>;
  taxId?: InputMaybe<StringOperationFilterInput>;
  teams?: InputMaybe<ListFilterInputTypeOfEmployeeTeamFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type EmployeeLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type EmployeeLoginPayload = {
  __typename?: 'EmployeeLoginPayload';
  string?: Maybe<Scalars['String']>;
};

export enum EmployeeSkill {
  AllRounder = 'ALL_ROUNDER',
  Barbecue = 'BARBECUE',
  Barkeeper = 'BARKEEPER',
  BarManager = 'BAR_MANAGER',
  Buffet = 'BUFFET',
  CampManager = 'CAMP_MANAGER',
  Cashier = 'CASHIER',
  Counter = 'COUNTER',
  Dishwasher = 'DISHWASHER',
  DrinkServer = 'DRINK_SERVER',
  EventManager = 'EVENT_MANAGER',
  Hostess = 'HOSTESS',
  KioskManager = 'KIOSK_MANAGER',
  KitchenHelp = 'KITCHEN_HELP',
  LodgeService = 'LODGE_SERVICE',
  Logistician = 'LOGISTICIAN',
  LogisticManager = 'LOGISTIC_MANAGER',
  Server = 'SERVER',
  ServiceA = 'SERVICE_A',
  ServiceB = 'SERVICE_B',
  ServiceC = 'SERVICE_C',
  ServiceManager = 'SERVICE_MANAGER',
  ServiceProfessional = 'SERVICE_PROFESSIONAL',
  ShopManager = 'SHOP_MANAGER',
  StandManager = 'STAND_MANAGER',
  Tapster = 'TAPSTER',
  TeamManager = 'TEAM_MANAGER',
  Trainer = 'TRAINER'
}

export type EmployeeSkillModel = {
  __typename?: 'EmployeeSkillModel';
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  document?: Maybe<EventDocument>;
  employee?: Maybe<Employee>;
  event?: Maybe<Event>;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  serviceProvider?: Maybe<ServiceProvider>;
  skill: EmployeeSkill;
  updatedBy?: Maybe<User>;
};

export type EmployeeSkillModelFilterInput = {
  and?: InputMaybe<Array<EmployeeSkillModelFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  document?: InputMaybe<EventDocumentFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EmployeeSkillModelFilterInput>>;
  serviceProvider?: InputMaybe<ServiceProviderFilterInput>;
  skill?: InputMaybe<EmployeeSkillOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type EmployeeSkillOperationFilterInput = {
  eq?: InputMaybe<EmployeeSkill>;
  in?: InputMaybe<Array<EmployeeSkill>>;
  neq?: InputMaybe<EmployeeSkill>;
  nin?: InputMaybe<Array<EmployeeSkill>>;
};

export type EmployeeSortInput = {
  account?: InputMaybe<AccountSortInput>;
  accountId?: InputMaybe<SortEnumType>;
  address?: InputMaybe<AddressSortInput>;
  branch?: InputMaybe<BranchSortInput>;
  branchId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  credentials?: InputMaybe<CredentialsSortInput>;
  dateOfBirth?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  employmentRelationship?: InputMaybe<SortEnumType>;
  expoPushToken?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  fullName?: InputMaybe<SortEnumType>;
  gender?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isOfAge?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  parentCode?: InputMaybe<SortEnumType>;
  parentEmail?: InputMaybe<SortEnumType>;
  parentSignatureUrl?: InputMaybe<SortEnumType>;
  personioId?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  pictureUrl?: InputMaybe<SortEnumType>;
  requireDailyContract?: InputMaybe<SortEnumType>;
  salary?: InputMaybe<SortEnumType>;
  signatureUrl?: InputMaybe<SortEnumType>;
  socialSecurityNumber?: InputMaybe<SortEnumType>;
  staffNumber?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  taxId?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export enum EmployeeStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Invalid = 'INVALID',
  Invited = 'INVITED',
  Pending = 'PENDING'
}

export type EmployeeStatusOperationFilterInput = {
  eq?: InputMaybe<EmployeeStatus>;
  in?: InputMaybe<Array<EmployeeStatus>>;
  neq?: InputMaybe<EmployeeStatus>;
  nin?: InputMaybe<Array<EmployeeStatus>>;
};

export type EmployeeTeam = {
  __typename?: 'EmployeeTeam';
  account: Account;
  branch: Branch;
  branchId: Scalars['UUID'];
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  employees: Array<Employee>;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  pubslishedEvents: Array<Event>;
  updatedBy?: Maybe<User>;
};

export type EmployeeTeamFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<EmployeeTeamFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableGuidOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EmployeeTeamFilterInput>>;
  pubslishedEvents?: InputMaybe<ListFilterInputTypeOfEventFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type EmploymentRelationModel = {
  __typename?: 'EmploymentRelationModel';
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  employmentRelationship: EmploymentRelationship;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
};

export type EmploymentRelationModelFilterInput = {
  and?: InputMaybe<Array<EmploymentRelationModelFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  employmentRelationship?: InputMaybe<EmploymentRelationshipOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EmploymentRelationModelFilterInput>>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export enum EmploymentRelationship {
  Freelancer = 'FREELANCER',
  Full = 'FULL',
  Midi = 'MIDI',
  Mini = 'MINI',
  Short = 'SHORT',
  Student = 'STUDENT'
}

export type EmploymentRelationshipOperationFilterInput = {
  eq?: InputMaybe<EmploymentRelationship>;
  in?: InputMaybe<Array<EmploymentRelationship>>;
  neq?: InputMaybe<EmploymentRelationship>;
  nin?: InputMaybe<Array<EmploymentRelationship>>;
};

export type Event = {
  __typename?: 'Event';
  account: Account;
  address: Address;
  addressNotes?: Maybe<Scalars['String']>;
  assignmentProgress: EventAssignmentProgressModel;
  branch: Branch;
  branchId: Scalars['ID'];
  checkInListComments: Array<CheckInListComment>;
  checkInListCompleted: Scalars['Boolean'];
  checkInListCompletedAt?: Maybe<Scalars['DateTime']>;
  checkInListCompletedBy?: Maybe<User>;
  checkInListConfirmed: Scalars['Boolean'];
  checkInListConfirmedAt?: Maybe<Scalars['DateTime']>;
  checkInListConfirmedBy?: Maybe<User>;
  checkInListDownloadUrl?: Maybe<Scalars['String']>;
  checkInListPdfId?: Maybe<Scalars['String']>;
  checkInListReleased: Scalars['Boolean'];
  checkInListReleasedAt?: Maybe<Scalars['DateTime']>;
  checkInListReleasedBy?: Maybe<User>;
  checkInMasters: Array<Employee>;
  childEvents: Array<Event>;
  comment?: Maybe<Scalars['String']>;
  contact: User;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  deployments: Array<Deployment>;
  description?: Maybe<Scalars['String']>;
  employeeLeasingUrl?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  eventNumber: Scalars['Int'];
  eventPublishCounters: Array<EventPublishCounter>;
  floors: Array<Scalars['String']>;
  hasActiveCancelRequest: Scalars['Boolean'];
  hasInternalEvents: Scalars['Boolean'];
  id: Scalars['ID'];
  isInternal: Scalars['Boolean'];
  location?: Maybe<EventLocation>;
  locationId?: Maybe<Scalars['UUID']>;
  message?: Maybe<Scalars['String']>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  numberOfGuests: Scalars['Int'];
  orderType?: Maybe<EventOrderType>;
  orders: Array<Order>;
  organizer?: Maybe<Organizer>;
  origin: CoventoOrigin;
  parentEvent?: Maybe<Event>;
  personioProjectId?: Maybe<Scalars['Int']>;
  personioProjectName?: Maybe<Scalars['String']>;
  publishedForAllEmployees: Scalars['Boolean'];
  publishedTeams: Array<EmployeeTeam>;
  quotations: Array<Quotation>;
  quoteChats: Array<QuoteChat>;
  sections: Array<Scalars['String']>;
  serviceProvider?: Maybe<ServiceProvider>;
  shifts: Array<Shift>;
  startDate: Scalars['DateTime'];
  status: EventStatus;
  type: EventType;
  updatedBy?: Maybe<User>;
};


export type EventQuoteChatsArgs = {
  where?: InputMaybe<QuoteChatFilterInput>;
};

export type EventAssignmentProgressModel = {
  __typename?: 'EventAssignmentProgressModel';
  progress: Scalars['String'];
  publishStatus: Scalars['String'];
};

export type EventAssignmentProgressModelFilterInput = {
  and?: InputMaybe<Array<EventAssignmentProgressModelFilterInput>>;
  or?: InputMaybe<Array<EventAssignmentProgressModelFilterInput>>;
  progress?: InputMaybe<StringOperationFilterInput>;
  publishStatus?: InputMaybe<StringOperationFilterInput>;
};

export type EventAssignmentProgressModelSortInput = {
  progress?: InputMaybe<SortEnumType>;
  publishStatus?: InputMaybe<SortEnumType>;
};

export type EventCancelRequest = {
  __typename?: 'EventCancelRequest';
  account: Account;
  chat: QuoteChat;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  event: Event;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  order: Order;
  status: QuoteStatus;
  updatedBy?: Maybe<User>;
};

export type EventCancelRequestFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<EventCancelRequestFilterInput>>;
  chat?: InputMaybe<QuoteChatFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EventCancelRequestFilterInput>>;
  order?: InputMaybe<OrderFilterInput>;
  status?: InputMaybe<QuoteStatusOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type EventCancelRequestSortInput = {
  account?: InputMaybe<AccountSortInput>;
  chat?: InputMaybe<QuoteChatSortInput>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  event?: InputMaybe<EventSortInput>;
  id?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  order?: InputMaybe<OrderSortInput>;
  status?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export type EventCompleteDataModel = {
  __typename?: 'EventCompleteDataModel';
  confirmedDeployments: Scalars['Int'];
  insertedDeployments: Scalars['Int'];
  totalDeployments: Scalars['Int'];
  totalRequiredDeployments: Scalars['Int'];
};

export type EventDashboardStatsModel = {
  __typename?: 'EventDashboardStatsModel';
  account?: Maybe<Scalars['String']>;
  coasts?: Maybe<Scalars['Float']>;
  employees?: Maybe<Scalars['Int']>;
  hours?: Maybe<Scalars['Float']>;
  plannedCoasts: Scalars['Float'];
  plannedEmployees: Scalars['Int'];
  plannedHours: Scalars['Float'];
  plannedTotalCoasts: Scalars['Float'];
  plannedTotalEmployees: Scalars['Int'];
  plannedTotalHours: Scalars['Float'];
  shifts: Array<EventDashboardStatsModel>;
  skill?: Maybe<EmployeeSkill>;
  totalCoasts?: Maybe<Scalars['Float']>;
  totalEmployees?: Maybe<Scalars['Int']>;
  totalHours?: Maybe<Scalars['Float']>;
};

export type EventDocument = {
  __typename?: 'EventDocument';
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  event: Event;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedBy?: Maybe<User>;
  url: Scalars['String'];
  visibleTo: Array<EmployeeSkillModel>;
};

export type EventDocumentFilterInput = {
  and?: InputMaybe<Array<EventDocumentFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EventDocumentFilterInput>>;
  updatedBy?: InputMaybe<UserFilterInput>;
  url?: InputMaybe<StringOperationFilterInput>;
  visibleTo?: InputMaybe<ListFilterInputTypeOfEmployeeSkillModelFilterInput>;
};

export type EventFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  addressNotes?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<EventFilterInput>>;
  assignmentProgress?: InputMaybe<EventAssignmentProgressModelFilterInput>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableGuidOperationFilterInput>;
  checkInListComments?: InputMaybe<ListFilterInputTypeOfCheckInListCommentFilterInput>;
  checkInListCompleted?: InputMaybe<BooleanOperationFilterInput>;
  checkInListCompletedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  checkInListCompletedBy?: InputMaybe<UserFilterInput>;
  checkInListConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  checkInListConfirmedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  checkInListConfirmedBy?: InputMaybe<UserFilterInput>;
  checkInListDownloadUrl?: InputMaybe<StringOperationFilterInput>;
  checkInListPdfId?: InputMaybe<StringOperationFilterInput>;
  checkInListReleased?: InputMaybe<BooleanOperationFilterInput>;
  checkInListReleasedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  checkInListReleasedBy?: InputMaybe<UserFilterInput>;
  checkInMasters?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  childEvents?: InputMaybe<ListFilterInputTypeOfEventFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  contact?: InputMaybe<UserFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  deployments?: InputMaybe<ListFilterInputTypeOfDeploymentFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  employeeLeasingUrl?: InputMaybe<StringOperationFilterInput>;
  endDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  eventNumber?: InputMaybe<ComparableInt32OperationFilterInput>;
  eventPublishCounters?: InputMaybe<ListFilterInputTypeOfEventPublishCounterFilterInput>;
  floors?: InputMaybe<ListStringOperationFilterInput>;
  hasActiveCancelRequest?: InputMaybe<BooleanOperationFilterInput>;
  hasInternalEvents?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isInternal?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<EventLocationFilterInput>;
  locationId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  message?: InputMaybe<StringOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  numberOfGuests?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<EventFilterInput>>;
  orderType?: InputMaybe<NullableOfEventOrderTypeOperationFilterInput>;
  orders?: InputMaybe<ListFilterInputTypeOfOrderFilterInput>;
  organizer?: InputMaybe<OrganizerFilterInput>;
  origin?: InputMaybe<CoventoOriginOperationFilterInput>;
  parentEvent?: InputMaybe<EventFilterInput>;
  personioProjectId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  personioProjectName?: InputMaybe<StringOperationFilterInput>;
  publishedForAllEmployees?: InputMaybe<BooleanOperationFilterInput>;
  publishedTeams?: InputMaybe<ListFilterInputTypeOfEmployeeTeamFilterInput>;
  quotations?: InputMaybe<ListFilterInputTypeOfQuotationFilterInput>;
  quoteChats?: InputMaybe<ListFilterInputTypeOfQuoteChatFilterInput>;
  sections?: InputMaybe<ListStringOperationFilterInput>;
  serviceProvider?: InputMaybe<ServiceProviderFilterInput>;
  shifts?: InputMaybe<ListFilterInputTypeOfShiftFilterInput>;
  startDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  status?: InputMaybe<EventStatusOperationFilterInput>;
  type?: InputMaybe<EventTypeOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type EventHelpRequest = {
  __typename?: 'EventHelpRequest';
  account: Account;
  branch: Branch;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  event: Event;
  id: Scalars['ID'];
  isCancel: Scalars['Boolean'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  quantity: Scalars['Int'];
  shift: Shift;
  status: EventRequestStatus;
  updatedBy?: Maybe<User>;
};

export type EventHelpRequestFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<EventHelpRequestFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isCancel?: InputMaybe<BooleanOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EventHelpRequestFilterInput>>;
  quantity?: InputMaybe<ComparableInt32OperationFilterInput>;
  shift?: InputMaybe<ShiftFilterInput>;
  status?: InputMaybe<EventRequestStatusOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type EventLocation = {
  __typename?: 'EventLocation';
  account: Account;
  address: Address;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  floors: Array<Scalars['String']>;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedBy?: Maybe<User>;
};

export type EventLocationFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<EventLocationFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  floors?: InputMaybe<ListStringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EventLocationFilterInput>>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type EventLocationSortInput = {
  account?: InputMaybe<AccountSortInput>;
  address?: InputMaybe<AddressSortInput>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export enum EventOrderType {
  Forecast = 'FORECAST',
  Intern = 'INTERN',
  Order = 'ORDER'
}

export type EventPublishCounter = {
  __typename?: 'EventPublishCounter';
  counter: Scalars['Int'];
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  team?: Maybe<EmployeeTeam>;
  updatedBy?: Maybe<User>;
};

export type EventPublishCounterFilterInput = {
  and?: InputMaybe<Array<EventPublishCounterFilterInput>>;
  counter?: InputMaybe<ComparableInt32OperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EventPublishCounterFilterInput>>;
  team?: InputMaybe<EmployeeTeamFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type EventRequest = {
  __typename?: 'EventRequest';
  account: Account;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  employee: Employee;
  employeeId: Scalars['UUID'];
  event: Event;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  shift: Shift;
  status: EventRequestStatus;
  updatedBy?: Maybe<User>;
};

export type EventRequestFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<EventRequestFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  employeeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EventRequestFilterInput>>;
  shift?: InputMaybe<ShiftFilterInput>;
  status?: InputMaybe<EventRequestStatusOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export enum EventRequestStatus {
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type EventRequestStatusOperationFilterInput = {
  eq?: InputMaybe<EventRequestStatus>;
  in?: InputMaybe<Array<EventRequestStatus>>;
  neq?: InputMaybe<EventRequestStatus>;
  nin?: InputMaybe<Array<EventRequestStatus>>;
};

export type EventSortInput = {
  account?: InputMaybe<AccountSortInput>;
  address?: InputMaybe<AddressSortInput>;
  addressNotes?: InputMaybe<SortEnumType>;
  assignmentProgress?: InputMaybe<EventAssignmentProgressModelSortInput>;
  branch?: InputMaybe<BranchSortInput>;
  branchId?: InputMaybe<SortEnumType>;
  checkInListCompleted?: InputMaybe<SortEnumType>;
  checkInListCompletedAt?: InputMaybe<SortEnumType>;
  checkInListCompletedBy?: InputMaybe<UserSortInput>;
  checkInListConfirmed?: InputMaybe<SortEnumType>;
  checkInListConfirmedAt?: InputMaybe<SortEnumType>;
  checkInListConfirmedBy?: InputMaybe<UserSortInput>;
  checkInListDownloadUrl?: InputMaybe<SortEnumType>;
  checkInListPdfId?: InputMaybe<SortEnumType>;
  checkInListReleased?: InputMaybe<SortEnumType>;
  checkInListReleasedAt?: InputMaybe<SortEnumType>;
  checkInListReleasedBy?: InputMaybe<UserSortInput>;
  comment?: InputMaybe<SortEnumType>;
  contact?: InputMaybe<UserSortInput>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  employeeLeasingUrl?: InputMaybe<SortEnumType>;
  endDate?: InputMaybe<SortEnumType>;
  eventNumber?: InputMaybe<SortEnumType>;
  hasActiveCancelRequest?: InputMaybe<SortEnumType>;
  hasInternalEvents?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isInternal?: InputMaybe<SortEnumType>;
  location?: InputMaybe<EventLocationSortInput>;
  locationId?: InputMaybe<SortEnumType>;
  message?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  numberOfGuests?: InputMaybe<SortEnumType>;
  orderType?: InputMaybe<SortEnumType>;
  organizer?: InputMaybe<OrganizerSortInput>;
  origin?: InputMaybe<SortEnumType>;
  parentEvent?: InputMaybe<EventSortInput>;
  personioProjectId?: InputMaybe<SortEnumType>;
  personioProjectName?: InputMaybe<SortEnumType>;
  publishedForAllEmployees?: InputMaybe<SortEnumType>;
  serviceProvider?: InputMaybe<ServiceProviderSortInput>;
  startDate?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export enum EventStatus {
  Active = 'ACTIVE',
  ActiveCancelRequest = 'ACTIVE_CANCEL_REQUEST',
  Blocked = 'BLOCKED',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  ReadyToComplete = 'READY_TO_COMPLETE',
  WaitingForComplete = 'WAITING_FOR_COMPLETE'
}

export type EventStatusOperationFilterInput = {
  eq?: InputMaybe<EventStatus>;
  in?: InputMaybe<Array<EventStatus>>;
  neq?: InputMaybe<EventStatus>;
  nin?: InputMaybe<Array<EventStatus>>;
};

export type EventTemplate = {
  __typename?: 'EventTemplate';
  account: Account;
  address: Address;
  addressNotes?: Maybe<Scalars['String']>;
  branch: Branch;
  branchId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location?: Maybe<EventLocation>;
  locationId?: Maybe<Scalars['UUID']>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  numberOfGuests: Scalars['Int'];
  personioProjectId?: Maybe<Scalars['Int']>;
  personioProjectName?: Maybe<Scalars['String']>;
  shifts: Array<Shift>;
  templateName: Scalars['String'];
  type: EventType;
  updatedBy?: Maybe<User>;
};

export type EventTemplateFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  addressNotes?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<EventTemplateFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableGuidOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  location?: InputMaybe<EventLocationFilterInput>;
  locationId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  numberOfGuests?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<EventTemplateFilterInput>>;
  personioProjectId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  personioProjectName?: InputMaybe<StringOperationFilterInput>;
  shifts?: InputMaybe<ListFilterInputTypeOfShiftFilterInput>;
  templateName?: InputMaybe<StringOperationFilterInput>;
  type?: InputMaybe<EventTypeOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type EventTemplateSortInput = {
  account?: InputMaybe<AccountSortInput>;
  address?: InputMaybe<AddressSortInput>;
  addressNotes?: InputMaybe<SortEnumType>;
  branch?: InputMaybe<BranchSortInput>;
  branchId?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  location?: InputMaybe<EventLocationSortInput>;
  locationId?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  numberOfGuests?: InputMaybe<SortEnumType>;
  personioProjectId?: InputMaybe<SortEnumType>;
  personioProjectName?: InputMaybe<SortEnumType>;
  templateName?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export enum EventType {
  Birthday = 'BIRTHDAY',
  Company = 'COMPANY',
  Concert = 'CONCERT',
  Fair = 'FAIR',
  Festival = 'FESTIVAL',
  Miscellaneous = 'MISCELLANEOUS',
  Sport = 'SPORT',
  Wedding = 'WEDDING'
}

export type EventTypeOperationFilterInput = {
  eq?: InputMaybe<EventType>;
  in?: InputMaybe<Array<EventType>>;
  neq?: InputMaybe<EventType>;
  nin?: InputMaybe<Array<EventType>>;
};

export type FinalizeQuoteInput = {
  id?: InputMaybe<Scalars['ID']>;
  status: QuoteStatus;
};

export type FinalizeQuotePayload = {
  __typename?: 'FinalizeQuotePayload';
  quotation?: Maybe<Quotation>;
};

export enum Gender {
  Diverse = 'DIVERSE',
  Female = 'FEMALE',
  Male = 'MALE'
}

export type GenerateCheckInListInput = {
  eventId?: InputMaybe<Scalars['ID']>;
  floorFilters?: InputMaybe<Array<Scalars['String']>>;
  sectionFilters?: InputMaybe<Array<Scalars['String']>>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['Int']>;
};

export type GenerateCheckInListPayload = {
  __typename?: 'GenerateCheckInListPayload';
  string?: Maybe<Scalars['String']>;
};

export type ImportEmployeeInput = {
  inputs: Array<CreateEmployeeInput>;
};

export type ImportEmployeePayload = {
  __typename?: 'ImportEmployeePayload';
  employee?: Maybe<Array<Employee>>;
};

export type InviteEmployeeInput = {
  employeeId?: InputMaybe<Scalars['ID']>;
};

export type InviteEmployeePayload = {
  __typename?: 'InviteEmployeePayload';
  employee?: Maybe<Employee>;
};

export type ListComparableByteOperationFilterInput = {
  all?: InputMaybe<ComparableByteOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ComparableByteOperationFilterInput>;
  some?: InputMaybe<ComparableByteOperationFilterInput>;
};

export type ListFilterInputTypeOfAccountFilterInput = {
  all?: InputMaybe<AccountFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AccountFilterInput>;
  some?: InputMaybe<AccountFilterInput>;
};

export type ListFilterInputTypeOfAccountPermissionFilterInput = {
  all?: InputMaybe<AccountPermissionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AccountPermissionFilterInput>;
  some?: InputMaybe<AccountPermissionFilterInput>;
};

export type ListFilterInputTypeOfBranchFilterInput = {
  all?: InputMaybe<BranchFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BranchFilterInput>;
  some?: InputMaybe<BranchFilterInput>;
};

export type ListFilterInputTypeOfCheckInListCommentFilterInput = {
  all?: InputMaybe<CheckInListCommentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CheckInListCommentFilterInput>;
  some?: InputMaybe<CheckInListCommentFilterInput>;
};

export type ListFilterInputTypeOfCoventoCustomerFilterInput = {
  all?: InputMaybe<CoventoCustomerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CoventoCustomerFilterInput>;
  some?: InputMaybe<CoventoCustomerFilterInput>;
};

export type ListFilterInputTypeOfCustomQuoteItemFilterInput = {
  all?: InputMaybe<CustomQuoteItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomQuoteItemFilterInput>;
  some?: InputMaybe<CustomQuoteItemFilterInput>;
};

export type ListFilterInputTypeOfCustomerFilterInput = {
  all?: InputMaybe<CustomerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomerFilterInput>;
  some?: InputMaybe<CustomerFilterInput>;
};

export type ListFilterInputTypeOfCustomerPriceFilterInput = {
  all?: InputMaybe<CustomerPriceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomerPriceFilterInput>;
  some?: InputMaybe<CustomerPriceFilterInput>;
};

export type ListFilterInputTypeOfCustomerRequestFilterInput = {
  all?: InputMaybe<CustomerRequestFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomerRequestFilterInput>;
  some?: InputMaybe<CustomerRequestFilterInput>;
};

export type ListFilterInputTypeOfDepartmentFilterInput = {
  all?: InputMaybe<DepartmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DepartmentFilterInput>;
  some?: InputMaybe<DepartmentFilterInput>;
};

export type ListFilterInputTypeOfDeploymentFilterInput = {
  all?: InputMaybe<DeploymentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeploymentFilterInput>;
  some?: InputMaybe<DeploymentFilterInput>;
};

export type ListFilterInputTypeOfEmployeeDocumentFilterInput = {
  all?: InputMaybe<EmployeeDocumentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EmployeeDocumentFilterInput>;
  some?: InputMaybe<EmployeeDocumentFilterInput>;
};

export type ListFilterInputTypeOfEmployeeFilterInput = {
  all?: InputMaybe<EmployeeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EmployeeFilterInput>;
  some?: InputMaybe<EmployeeFilterInput>;
};

export type ListFilterInputTypeOfEmployeeSkillModelFilterInput = {
  all?: InputMaybe<EmployeeSkillModelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EmployeeSkillModelFilterInput>;
  some?: InputMaybe<EmployeeSkillModelFilterInput>;
};

export type ListFilterInputTypeOfEmployeeTeamFilterInput = {
  all?: InputMaybe<EmployeeTeamFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EmployeeTeamFilterInput>;
  some?: InputMaybe<EmployeeTeamFilterInput>;
};

export type ListFilterInputTypeOfEmploymentRelationModelFilterInput = {
  all?: InputMaybe<EmploymentRelationModelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EmploymentRelationModelFilterInput>;
  some?: InputMaybe<EmploymentRelationModelFilterInput>;
};

export type ListFilterInputTypeOfEventFilterInput = {
  all?: InputMaybe<EventFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EventFilterInput>;
  some?: InputMaybe<EventFilterInput>;
};

export type ListFilterInputTypeOfEventPublishCounterFilterInput = {
  all?: InputMaybe<EventPublishCounterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EventPublishCounterFilterInput>;
  some?: InputMaybe<EventPublishCounterFilterInput>;
};

export type ListFilterInputTypeOfEventRequestFilterInput = {
  all?: InputMaybe<EventRequestFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EventRequestFilterInput>;
  some?: InputMaybe<EventRequestFilterInput>;
};

export type ListFilterInputTypeOfOrderFilterInput = {
  all?: InputMaybe<OrderFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<OrderFilterInput>;
  some?: InputMaybe<OrderFilterInput>;
};

export type ListFilterInputTypeOfQuotationFilterInput = {
  all?: InputMaybe<QuotationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<QuotationFilterInput>;
  some?: InputMaybe<QuotationFilterInput>;
};

export type ListFilterInputTypeOfQuoteChatFilterInput = {
  all?: InputMaybe<QuoteChatFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<QuoteChatFilterInput>;
  some?: InputMaybe<QuoteChatFilterInput>;
};

export type ListFilterInputTypeOfQuoteItemFilterInput = {
  all?: InputMaybe<QuoteItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<QuoteItemFilterInput>;
  some?: InputMaybe<QuoteItemFilterInput>;
};

export type ListFilterInputTypeOfQuoteMessageFilterInput = {
  all?: InputMaybe<QuoteMessageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<QuoteMessageFilterInput>;
  some?: InputMaybe<QuoteMessageFilterInput>;
};

export type ListFilterInputTypeOfShiftFilterInput = {
  all?: InputMaybe<ShiftFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ShiftFilterInput>;
  some?: InputMaybe<ShiftFilterInput>;
};

export type ListFilterInputTypeOfUserFilterInput = {
  all?: InputMaybe<UserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserFilterInput>;
  some?: InputMaybe<UserFilterInput>;
};

export type ListFilterInputTypeOfUserRoleFilterInput = {
  all?: InputMaybe<UserRoleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserRoleFilterInput>;
  some?: InputMaybe<UserRoleFilterInput>;
};

export type ListFilterInputTypeOfUserRolePermissionFilterInput = {
  all?: InputMaybe<UserRolePermissionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserRolePermissionFilterInput>;
  some?: InputMaybe<UserRolePermissionFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type MarkAsReserveInput = {
  deploymentId?: InputMaybe<Scalars['ID']>;
};

export type MarkAsReservePayload = {
  __typename?: 'MarkAsReservePayload';
  deployment?: Maybe<Deployment>;
};

export type MarkCanceledShiftAsReadInput = {
  shiftIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MarkCanceledShiftAsReadPayload = {
  __typename?: 'MarkCanceledShiftAsReadPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type MarkNotificationsAsReadInput = {
  ids: Array<Scalars['UUID']>;
};

export type MarkNotificationsAsReadPayload = {
  __typename?: 'MarkNotificationsAsReadPayload';
  string?: Maybe<Scalars['String']>;
};

export type MarkQuoteChatAsReadInput = {
  chatId?: InputMaybe<Scalars['ID']>;
};

export type MarkQuoteChatAsReadPayload = {
  __typename?: 'MarkQuoteChatAsReadPayload';
  quoteChat?: Maybe<QuoteChat>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTermsAndConditions: AcceptTermsAndConditionsPayload;
  addAllRounderToEmployees: AddAllRounderToEmployeesPayload;
  adminLogin: AdminLoginPayload;
  completeCheckInList: CompleteCheckInListPayload;
  completePersonioEvent: CompletePersonioEventPayload;
  confirmCheckInList: ConfirmCheckInListPayload;
  createAccount: CreateAccountPayload;
  createBranch: CreateBranchPayload;
  createCanceledShift: CreateCanceledShiftPayload;
  createCheckInListComment: CreateCheckInListCommentPayload;
  createCheckInMasters: CreateCheckInMastersPayload;
  createCustomer: CreateCustomerPayload;
  createCustomerRequest: CreateCustomerRequestPayload;
  createDeployment: CreateDeploymentPayload;
  createDocumentType: CreateDocumentTypePayload;
  createEmployee: CreateEmployeePayload;
  createEmployeeTeam: CreateEmployeeTeamPayload;
  createEvent: CreateEventPayload;
  createEventHelpRequest: CreateEventHelpRequestPayload;
  createEventLocation: CreateEventLocationPayload;
  createEventRequest: CreateEventRequestPayload;
  createEventTemplate: CreateEventTemplatePayload;
  createQuote: CreateQuotePayload;
  createQuoteRequests: CreateQuoteRequestsPayload;
  createShifts: CreateShiftsPayload;
  createUnsignedDocuments: CreateUnsignedDocumentsPayload;
  createUser: CreateUserPayload;
  createUserRole: CreateUserRolePayload;
  createUserRolePermission: CreateUserRolePermissionPayload;
  deleteAccountSkills: DeleteAccountSkillsPayload;
  deleteDeployment: DeleteDeploymentPayload;
  deleteDocumentType: DeleteDocumentTypePayload;
  deleteEmployeeDocument: DeleteEmployeeDocumentPayload;
  deleteEmployeeTeam: DeleteEmployeeTeamPayload;
  deleteEventDocument: DeleteEventDocumentPayload;
  deleteEventRequest: DeleteEventRequestPayload;
  deleteEventTemplate: DeleteEventTemplatePayload;
  deleteShift: DeleteShiftPayload;
  employeeLogin: EmployeeLoginPayload;
  finalizeQuote: FinalizeQuotePayload;
  generateCheckInList: GenerateCheckInListPayload;
  importEmployee: ImportEmployeePayload;
  inviteEmployee: InviteEmployeePayload;
  markAsReserve: MarkAsReservePayload;
  markCanceledShiftAsRead: MarkCanceledShiftAsReadPayload;
  markNotificationsAsRead: MarkNotificationsAsReadPayload;
  markQuoteChatAsRead: MarkQuoteChatAsReadPayload;
  negotiateQuote: NegotiateQuotePayload;
  personioEmployees: PersonioEmployeesPayload;
  publishDeployments: PublishDeploymentsPayload;
  publishEvent: PublishEventPayload;
  publishEventTeams: PublishEventTeamsPayload;
  releaseCheckInList: ReleaseCheckInListPayload;
  requestContact: RequestContactPayload;
  requestPasswordReset: RequestPasswordResetPayload;
  revokeQuote: RevokeQuotePayload;
  sendEventPushNotifications: SendEventPushNotificationsPayload;
  sendQuoteMessage: SendQuoteMessagePayload;
  setAccountSkills: SetAccountSkillsPayload;
  setCancelSettings: SetCancelSettingsPayload;
  setDeploymentTimes: SetDeploymentTimesPayload;
  setDeploymentTimesByEmployee: SetDeploymentTimesByEmployeePayload;
  setEmployeePassword: SetEmployeePasswordPayload;
  setEventInfoMessage: SetEventInfoMessagePayload;
  setManyDeploymentTimes: SetManyDeploymentTimesPayload;
  setPersonioTimes: SetPersonioTimesPayload;
  setPushToken: SetPushTokenPayload;
  setUserPassword: SetUserPasswordPayload;
  signDocument: SignDocumentPayload;
  signDocuments: SignDocumentsPayload;
  transmitCheckInList: TransmitCheckInListPayload;
  updateAccount: UpdateAccountPayload;
  updateAccountAddress: UpdateAccountAddressPayload;
  updateAccountStatus: UpdateAccountStatusPayload;
  updateBranch: UpdateBranchPayload;
  updateBranchStatus: UpdateBranchStatusPayload;
  updateCancelRequestStatus: UpdateCancelRequestStatusPayload;
  updateCustomerPrices: UpdateCustomerPricesPayload;
  updateCustomerRequestStatus: UpdateCustomerRequestStatusPayload;
  updateDeployment: UpdateDeploymentPayload;
  updateDocumentType: UpdateDocumentTypePayload;
  updateEmployee: UpdateEmployeePayload;
  updateEmployeePassword: UpdateEmployeePasswordPayload;
  updateEmployeeStatus: UpdateEmployeeStatusPayload;
  updateEmployeeTeam: UpdateEmployeeTeamPayload;
  updateEvent: UpdateEventPayload;
  updateEventDocument: UpdateEventDocumentPayload;
  updateEventHelpRequest: UpdateEventHelpRequestPayload;
  updateEventLocation: UpdateEventLocationPayload;
  updateEventOrderType: UpdateEventOrderTypePayload;
  updateEventRequest: UpdateEventRequestPayload;
  updateEventStatus: UpdateEventStatusPayload;
  updateEventTemplate: UpdateEventTemplatePayload;
  updateMyAccount: UpdateMyAccountPayload;
  updateShift: UpdateShiftPayload;
  updateUser: UpdateUserPayload;
  updateUserPassword: UpdateUserPasswordPayload;
  updateUserRole: UpdateUserRolePayload;
  updateUserRoleStatus: UpdateUserRoleStatusPayload;
  updateUserStatus: UpdateUserStatusPayload;
  userLogin: UserLoginPayload;
};


export type MutationAdminLoginArgs = {
  input: AdminLoginInput;
};


export type MutationCompleteCheckInListArgs = {
  input: CompleteCheckInListInput;
};


export type MutationCompletePersonioEventArgs = {
  input: CompletePersonioEventInput;
};


export type MutationConfirmCheckInListArgs = {
  input: ConfirmCheckInListInput;
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationCreateBranchArgs = {
  input: CreateBranchInput;
};


export type MutationCreateCanceledShiftArgs = {
  input: CreateCanceledShiftInput;
};


export type MutationCreateCheckInListCommentArgs = {
  input: CreateCheckInListCommentInput;
};


export type MutationCreateCheckInMastersArgs = {
  input: CreateCheckInMastersInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationCreateCustomerRequestArgs = {
  input: CreateCustomerRequestInput;
};


export type MutationCreateDeploymentArgs = {
  input: CreateDeploymentInput;
};


export type MutationCreateDocumentTypeArgs = {
  input: CreateDocumentTypeInput;
};


export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput;
};


export type MutationCreateEmployeeTeamArgs = {
  input: CreateEmployeeTeamInput;
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationCreateEventHelpRequestArgs = {
  eventId?: InputMaybe<Scalars['ID']>;
  inputs: Array<CreateEventHelpRequestInput>;
};


export type MutationCreateEventLocationArgs = {
  input: CreateEventLocationInput;
};


export type MutationCreateEventRequestArgs = {
  input: CreateEventRequestInput;
};


export type MutationCreateEventTemplateArgs = {
  input: CreateEventTemplateInput;
};


export type MutationCreateQuoteArgs = {
  input: CreateQuoteInput;
};


export type MutationCreateQuoteRequestsArgs = {
  input: CreateQuoteRequestsInput;
};


export type MutationCreateShiftsArgs = {
  input: CreateShiftsInput;
};


export type MutationCreateUnsignedDocumentsArgs = {
  input: CreateUnsignedDocumentsInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserRoleArgs = {
  input: CreateUserRoleInput;
};


export type MutationCreateUserRolePermissionArgs = {
  input: CreateUserRolePermissionInput;
};


export type MutationDeleteAccountSkillsArgs = {
  input: DeleteAccountSkillsInput;
};


export type MutationDeleteDeploymentArgs = {
  input: DeleteDeploymentInput;
};


export type MutationDeleteDocumentTypeArgs = {
  input: DeleteDocumentTypeInput;
};


export type MutationDeleteEmployeeDocumentArgs = {
  input: DeleteEmployeeDocumentInput;
};


export type MutationDeleteEmployeeTeamArgs = {
  input: DeleteEmployeeTeamInput;
};


export type MutationDeleteEventDocumentArgs = {
  input: DeleteEventDocumentInput;
};


export type MutationDeleteEventRequestArgs = {
  input: DeleteEventRequestInput;
};


export type MutationDeleteEventTemplateArgs = {
  input: DeleteEventTemplateInput;
};


export type MutationDeleteShiftArgs = {
  input: DeleteShiftInput;
};


export type MutationEmployeeLoginArgs = {
  input: EmployeeLoginInput;
};


export type MutationFinalizeQuoteArgs = {
  input: FinalizeQuoteInput;
};


export type MutationGenerateCheckInListArgs = {
  input: GenerateCheckInListInput;
};


export type MutationImportEmployeeArgs = {
  input: ImportEmployeeInput;
};


export type MutationInviteEmployeeArgs = {
  input: InviteEmployeeInput;
};


export type MutationMarkAsReserveArgs = {
  input: MarkAsReserveInput;
};


export type MutationMarkCanceledShiftAsReadArgs = {
  input: MarkCanceledShiftAsReadInput;
};


export type MutationMarkNotificationsAsReadArgs = {
  input: MarkNotificationsAsReadInput;
};


export type MutationMarkQuoteChatAsReadArgs = {
  input: MarkQuoteChatAsReadInput;
};


export type MutationNegotiateQuoteArgs = {
  input: NegotiateQuoteInput;
};


export type MutationPublishDeploymentsArgs = {
  input: PublishDeploymentsInput;
};


export type MutationPublishEventArgs = {
  input: PublishEventInput;
};


export type MutationPublishEventTeamsArgs = {
  input: PublishEventTeamsInput;
};


export type MutationReleaseCheckInListArgs = {
  input: ReleaseCheckInListInput;
};


export type MutationRequestContactArgs = {
  input: RequestContactInput;
};


export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};


export type MutationRevokeQuoteArgs = {
  input: RevokeQuoteInput;
};


export type MutationSendEventPushNotificationsArgs = {
  input: SendEventPushNotificationsInput;
};


export type MutationSendQuoteMessageArgs = {
  input: SendQuoteMessageInput;
};


export type MutationSetAccountSkillsArgs = {
  input: SetAccountSkillsInput;
};


export type MutationSetCancelSettingsArgs = {
  input: SetCancelSettingsInput;
};


export type MutationSetDeploymentTimesArgs = {
  input: SetDeploymentTimesInput;
};


export type MutationSetDeploymentTimesByEmployeeArgs = {
  input: SetDeploymentTimesByEmployeeInput;
};


export type MutationSetEmployeePasswordArgs = {
  input: SetEmployeePasswordInput;
};


export type MutationSetEventInfoMessageArgs = {
  input: SetEventInfoMessageInput;
};


export type MutationSetManyDeploymentTimesArgs = {
  input: SetManyDeploymentTimesInput;
};


export type MutationSetPersonioTimesArgs = {
  input: SetPersonioTimesInput;
};


export type MutationSetPushTokenArgs = {
  input: SetPushTokenInput;
};


export type MutationSetUserPasswordArgs = {
  input: SetUserPasswordInput;
};


export type MutationSignDocumentArgs = {
  input: SignDocumentInput;
};


export type MutationTransmitCheckInListArgs = {
  input: TransmitCheckInListInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationUpdateAccountAddressArgs = {
  input: UpdateAccountAddressInput;
};


export type MutationUpdateAccountStatusArgs = {
  input: UpdateAccountStatusInput;
};


export type MutationUpdateBranchArgs = {
  input: UpdateBranchInput;
};


export type MutationUpdateBranchStatusArgs = {
  input: UpdateBranchStatusInput;
};


export type MutationUpdateCancelRequestStatusArgs = {
  input: UpdateCancelRequestStatusInput;
};


export type MutationUpdateCustomerPricesArgs = {
  input: UpdateCustomerPricesInput;
};


export type MutationUpdateCustomerRequestStatusArgs = {
  input: UpdateCustomerRequestStatusInput;
};


export type MutationUpdateDeploymentArgs = {
  input: UpdateDeploymentInput;
};


export type MutationUpdateDocumentTypeArgs = {
  input: UpdateDocumentTypeInput;
};


export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput;
};


export type MutationUpdateEmployeePasswordArgs = {
  input: UpdateEmployeePasswordInput;
};


export type MutationUpdateEmployeeStatusArgs = {
  input: UpdateEmployeeStatusInput;
};


export type MutationUpdateEmployeeTeamArgs = {
  input: UpdateEmployeeTeamInput;
};


export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};


export type MutationUpdateEventDocumentArgs = {
  input: UpdateEventDocumentInput;
};


export type MutationUpdateEventHelpRequestArgs = {
  input: UpdateEventHelpRequestInput;
};


export type MutationUpdateEventLocationArgs = {
  input: UpdateEventLocationInput;
};


export type MutationUpdateEventOrderTypeArgs = {
  input: UpdateEventOrderTypeInput;
};


export type MutationUpdateEventRequestArgs = {
  input: UpdateEventRequestInput;
};


export type MutationUpdateEventStatusArgs = {
  input: UpdateEventStatusInput;
};


export type MutationUpdateEventTemplateArgs = {
  input: UpdateEventTemplateInput;
};


export type MutationUpdateMyAccountArgs = {
  input: UpdateMyAccountInput;
};


export type MutationUpdateShiftArgs = {
  input: UpdateShiftInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput;
};


export type MutationUpdateUserRoleArgs = {
  input: UpdateUserRoleInput;
};


export type MutationUpdateUserRoleStatusArgs = {
  input: UpdateUserRoleStatusInput;
};


export type MutationUpdateUserStatusArgs = {
  input: UpdateUserStatusInput;
};


export type MutationUserLoginArgs = {
  input: UserLoginInput;
};

export type MyCoventoDashboardModel = {
  __typename?: 'MyCoventoDashboardModel';
  branchCreated: Scalars['Boolean'];
  hasAdresss: Scalars['Boolean'];
  logoUploaded: Scalars['Boolean'];
  profileCompleted: Scalars['Boolean'];
  roleCreated: Scalars['Boolean'];
  termsAndCondtionsAccepted: Scalars['Boolean'];
  termsAndCondtionsUploaded: Scalars['Boolean'];
  user: User;
  userCreated: Scalars['Boolean'];
};

export type MyDeploymentsModel = {
  __typename?: 'MyDeploymentsModel';
  data: Array<Deployment>;
  title: Scalars['String'];
};

export type MyPersonioDocumentsModel = {
  __typename?: 'MyPersonioDocumentsModel';
  data: Array<PersonioContract>;
  title: Scalars['String'];
};

export type NavigationModel = {
  __typename?: 'NavigationModel';
  badge?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isTitle?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  subItems?: Maybe<Array<NavigationModel>>;
};

export type NegotiateQuoteInput = {
  createCustomQuoteItemInputs?: InputMaybe<Array<CreateCustomQuoteItemInput>>;
  createQuoteItemInputs: Array<CreateQuoteItemInput>;
  message?: InputMaybe<Scalars['String']>;
  quoteId?: InputMaybe<Scalars['ID']>;
};

export type NegotiateQuotePayload = {
  __typename?: 'NegotiateQuotePayload';
  quotation?: Maybe<Quotation>;
};

export type Notification = {
  __typename?: 'Notification';
  account?: Maybe<Account>;
  color: Scalars['String'];
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  employee?: Maybe<Employee>;
  event?: Maybe<Event>;
  icon: Scalars['String'];
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  target: NotificationTarget;
  text: Scalars['String'];
  title: Scalars['String'];
  updatedBy?: Maybe<User>;
  user?: Maybe<User>;
};

export type NotificationFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<NotificationFilterInput>>;
  color?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  icon?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isRead?: InputMaybe<BooleanOperationFilterInput>;
  link?: InputMaybe<StringOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<NotificationFilterInput>>;
  target?: InputMaybe<NotificationTargetOperationFilterInput>;
  text?: InputMaybe<StringOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
  user?: InputMaybe<UserFilterInput>;
};

export enum NotificationTarget {
  App = 'APP',
  MyCovento = 'MY_COVENTO',
  Planning = 'PLANNING',
  Platform = 'PLATFORM'
}

export type NotificationTargetOperationFilterInput = {
  eq?: InputMaybe<NotificationTarget>;
  in?: InputMaybe<Array<NotificationTarget>>;
  neq?: InputMaybe<NotificationTarget>;
  nin?: InputMaybe<Array<NotificationTarget>>;
};

export type NullableOfChatCloseReasonOperationFilterInput = {
  eq?: InputMaybe<ChatCloseReason>;
  in?: InputMaybe<Array<InputMaybe<ChatCloseReason>>>;
  neq?: InputMaybe<ChatCloseReason>;
  nin?: InputMaybe<Array<InputMaybe<ChatCloseReason>>>;
};

export type NullableOfEmploymentRelationshipOperationFilterInput = {
  eq?: InputMaybe<EmploymentRelationship>;
  in?: InputMaybe<Array<InputMaybe<EmploymentRelationship>>>;
  neq?: InputMaybe<EmploymentRelationship>;
  nin?: InputMaybe<Array<InputMaybe<EmploymentRelationship>>>;
};

export type NullableOfEventOrderTypeOperationFilterInput = {
  eq?: InputMaybe<EventOrderType>;
  in?: InputMaybe<Array<InputMaybe<EventOrderType>>>;
  neq?: InputMaybe<EventOrderType>;
  nin?: InputMaybe<Array<InputMaybe<EventOrderType>>>;
};

export type NullableOfGenderOperationFilterInput = {
  eq?: InputMaybe<Gender>;
  in?: InputMaybe<Array<InputMaybe<Gender>>>;
  neq?: InputMaybe<Gender>;
  nin?: InputMaybe<Array<InputMaybe<Gender>>>;
};

export type Order = {
  __typename?: 'Order';
  branch: Branch;
  branchId: Scalars['UUID'];
  cancelRequest?: Maybe<EventCancelRequest>;
  cancelRequestId?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  customItems: Array<CustomQuoteItem>;
  customer?: Maybe<Customer>;
  downloadUrl: Scalars['String'];
  event: Event;
  id: Scalars['ID'];
  items: Array<QuoteItem>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  net: Scalars['Float'];
  organizer: Organizer;
  quote: Quotation;
  quoteId: Scalars['UUID'];
  refNumber: Scalars['Int'];
  serviceProvider: ServiceProvider;
  status: OrderStatus;
  tax: Scalars['Float'];
  total: Scalars['Float'];
  updatedBy?: Maybe<User>;
  version: Scalars['Int'];
};

export type OrderFilterInput = {
  and?: InputMaybe<Array<OrderFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableGuidOperationFilterInput>;
  cancelRequest?: InputMaybe<EventCancelRequestFilterInput>;
  cancelRequestId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customItems?: InputMaybe<ListFilterInputTypeOfCustomQuoteItemFilterInput>;
  customer?: InputMaybe<CustomerFilterInput>;
  downloadUrl?: InputMaybe<StringOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  items?: InputMaybe<ListFilterInputTypeOfQuoteItemFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  net?: InputMaybe<ComparableDoubleOperationFilterInput>;
  or?: InputMaybe<Array<OrderFilterInput>>;
  organizer?: InputMaybe<OrganizerFilterInput>;
  quote?: InputMaybe<QuotationFilterInput>;
  quoteId?: InputMaybe<ComparableGuidOperationFilterInput>;
  refNumber?: InputMaybe<ComparableInt32OperationFilterInput>;
  serviceProvider?: InputMaybe<ServiceProviderFilterInput>;
  status?: InputMaybe<OrderStatusOperationFilterInput>;
  tax?: InputMaybe<ComparableDoubleOperationFilterInput>;
  total?: InputMaybe<ComparableDoubleOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
  version?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type OrderSortInput = {
  branch?: InputMaybe<BranchSortInput>;
  branchId?: InputMaybe<SortEnumType>;
  cancelRequest?: InputMaybe<EventCancelRequestSortInput>;
  cancelRequestId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  customer?: InputMaybe<CustomerSortInput>;
  downloadUrl?: InputMaybe<SortEnumType>;
  event?: InputMaybe<EventSortInput>;
  id?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  net?: InputMaybe<SortEnumType>;
  organizer?: InputMaybe<OrganizerSortInput>;
  quote?: InputMaybe<QuotationSortInput>;
  quoteId?: InputMaybe<SortEnumType>;
  refNumber?: InputMaybe<SortEnumType>;
  serviceProvider?: InputMaybe<ServiceProviderSortInput>;
  status?: InputMaybe<SortEnumType>;
  tax?: InputMaybe<SortEnumType>;
  total?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
  version?: InputMaybe<SortEnumType>;
};

export enum OrderStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Deprecated = 'DEPRECATED',
  Pending = 'PENDING'
}

export type OrderStatusOperationFilterInput = {
  eq?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  neq?: InputMaybe<OrderStatus>;
  nin?: InputMaybe<Array<OrderStatus>>;
};

export type Organizer = {
  __typename?: 'Organizer';
  aboutUs?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  branches: Array<Branch>;
  customers: Array<CoventoCustomer>;
  customersRequests: Array<CustomerRequest>;
  discriminator: Scalars['String'];
  email: Scalars['String'];
  employees: Array<Employee>;
  events: Array<Event>;
  hrAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions: Array<AccountPermission>;
  personioUser: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  platformAccess: Scalars['Boolean'];
  readChats: Array<QuoteChat>;
  status: AccountStatus;
  termsAndConditionsAccepted: Scalars['Boolean'];
  termsAndConditionsUrl?: Maybe<Scalars['String']>;
  users: Array<User>;
  ventoWorkAccess: Scalars['Boolean'];
};

export type OrganizerFilterInput = {
  aboutUs?: InputMaybe<StringOperationFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<OrganizerFilterInput>>;
  branches?: InputMaybe<ListFilterInputTypeOfBranchFilterInput>;
  customers?: InputMaybe<ListFilterInputTypeOfCoventoCustomerFilterInput>;
  customersRequests?: InputMaybe<ListFilterInputTypeOfCustomerRequestFilterInput>;
  discriminator?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  events?: InputMaybe<ListFilterInputTypeOfEventFilterInput>;
  hrAccess?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  logoUrl?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganizerFilterInput>>;
  permissions?: InputMaybe<ListFilterInputTypeOfAccountPermissionFilterInput>;
  personioUser?: InputMaybe<BooleanOperationFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  platformAccess?: InputMaybe<BooleanOperationFilterInput>;
  readChats?: InputMaybe<ListFilterInputTypeOfQuoteChatFilterInput>;
  status?: InputMaybe<AccountStatusOperationFilterInput>;
  termsAndConditionsAccepted?: InputMaybe<BooleanOperationFilterInput>;
  termsAndConditionsUrl?: InputMaybe<StringOperationFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  ventoWorkAccess?: InputMaybe<BooleanOperationFilterInput>;
};

export type OrganizerSortInput = {
  aboutUs?: InputMaybe<SortEnumType>;
  address?: InputMaybe<AddressSortInput>;
  discriminator?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  hrAccess?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  logoUrl?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  personioUser?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  platformAccess?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  termsAndConditionsAccepted?: InputMaybe<SortEnumType>;
  termsAndConditionsUrl?: InputMaybe<SortEnumType>;
  ventoWorkAccess?: InputMaybe<SortEnumType>;
};

export type PersonioContract = {
  __typename?: 'PersonioContract';
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  deployment: Deployment;
  employee: Employee;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  signedUrl?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<User>;
  url: Scalars['String'];
};

export type PersonioContractFilterInput = {
  and?: InputMaybe<Array<PersonioContractFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  deployment?: InputMaybe<DeploymentFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PersonioContractFilterInput>>;
  signedUrl?: InputMaybe<StringOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
  url?: InputMaybe<StringOperationFilterInput>;
};

export type PersonioContractSortInput = {
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  deployment?: InputMaybe<DeploymentSortInput>;
  employee?: InputMaybe<EmployeeSortInput>;
  id?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  signedUrl?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
  url?: InputMaybe<SortEnumType>;
};

export type PersonioData = {
  __typename?: 'PersonioData';
  id: Array<Scalars['Int']>;
  message: Scalars['String'];
};

export type PersonioEmployeesPayload = {
  __typename?: 'PersonioEmployeesPayload';
  employee?: Maybe<Array<Employee>>;
};

export type PersonioError = {
  __typename?: 'PersonioError';
  code: Scalars['Int'];
  detailed_message: Array<PersonioMessages>;
  message: Scalars['String'];
};

export type PersonioMessages = {
  __typename?: 'PersonioMessages';
  employee: Scalars['Int'];
  id?: Maybe<Scalars['Int']>;
  success: Scalars['Boolean'];
};

export type PersonioProjectsAttributes = {
  __typename?: 'PersonioProjectsAttributes';
  active: Scalars['Boolean'];
  created_at: Scalars['DateTime'];
  name: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type PersonioProjectsData = {
  __typename?: 'PersonioProjectsData';
  attributes: PersonioProjectsAttributes;
  id: Scalars['Int'];
  type: Scalars['String'];
};

export type PersonioProjectsRoot = {
  __typename?: 'PersonioProjectsRoot';
  data: Array<PersonioProjectsData>;
  success: Scalars['Boolean'];
};

export type PersonioResponse = {
  __typename?: 'PersonioResponse';
  data?: Maybe<PersonioData>;
  error?: Maybe<PersonioError>;
  success: Scalars['Boolean'];
};

export type PublishDeploymentsInput = {
  shiftId?: InputMaybe<Scalars['ID']>;
};

export type PublishDeploymentsPayload = {
  __typename?: 'PublishDeploymentsPayload';
  deployment?: Maybe<Array<Deployment>>;
};

export type PublishEventInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PublishEventPayload = {
  __typename?: 'PublishEventPayload';
  event?: Maybe<Event>;
};

export type PublishEventTeamsInput = {
  eventId?: InputMaybe<Scalars['ID']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type PublishEventTeamsPayload = {
  __typename?: 'PublishEventTeamsPayload';
  event?: Maybe<Event>;
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accountSkills: Array<EmployeeSkillModel>;
  accounts: Array<Account>;
  appNotifications: Array<Notification>;
  assignmentProgress: EventAssignmentProgressModel;
  availableShifts: Array<AvailableShiftsModel>;
  availableShiftsForAssignment: Array<Shift>;
  branch?: Maybe<Branch>;
  branches: Array<Branch>;
  cancelSetting?: Maybe<CancelSettings>;
  cancelSettings: Array<CancelSettings>;
  canceledShifts: Array<CanceledShift>;
  checkInDeployments: Array<Deployment>;
  checkInList: CheckInListModel;
  checkInMasters: Array<Event>;
  childShifts: Array<ChildShiftListModel>;
  completeEventData: EventCompleteDataModel;
  countAppNotifications: Scalars['Int'];
  countCustomerRequests: Scalars['Int'];
  countEventHelpRequests: Scalars['Int'];
  countEventRequests: Scalars['Int'];
  countNotifications: Scalars['Int'];
  countUnreadQuoteChats: Scalars['Int'];
  crmEmployees: Array<Employee>;
  crmUsers: Array<User>;
  customerRequests: Array<CustomerRequest>;
  customers: Array<CoventoCustomer>;
  departments: Array<Department>;
  deployment?: Maybe<Deployment>;
  deploymentShifts: DeploymentsShiftModel;
  deployments: Array<Deployment>;
  deploymentsByMonth: Array<DeploymentByMonthModel>;
  documentTypes: Array<DocumentType>;
  employee?: Maybe<Employee>;
  employeeCheckInList: CheckInListModel;
  employeeDashboard: EmployeeDashboardModel;
  employeeDocuments: Array<EmployeeDocumentModel>;
  employeeEventRequest?: Maybe<EventRequest>;
  employeeEventRequests: Array<EventRequest>;
  employeeEvents: Array<Event>;
  employeeMe: Employee;
  employeeTeam?: Maybe<EmployeeTeam>;
  employeeTeams: Array<EmployeeTeam>;
  employees: Array<Employee>;
  event?: Maybe<Event>;
  eventDashboardStats: Array<EventDashboardStatsModel>;
  eventDocuments: Array<EventDocument>;
  eventEditShifts: ShiftEditModel;
  eventHelpRequests: Array<EventHelpRequest>;
  eventLocation?: Maybe<EventLocation>;
  eventLocations: Array<EventLocation>;
  eventRequests: Array<EmployeeEventRequestGroupedModel>;
  eventTemplate?: Maybe<EventTemplate>;
  eventTemplates: Array<EventTemplate>;
  events: Array<Event>;
  eventsCheckInListComments: Array<CheckInListComment>;
  isAvailableForAssignment: Scalars['Boolean'];
  manageShifts: Array<ShiftEditModel>;
  me: User;
  myBranches: Array<Branch>;
  myCoventoDashboard: MyCoventoDashboardModel;
  myDeployments: Array<MyDeploymentsModel>;
  myDocumentTypes: Array<DocumentType>;
  myEmployeeDocuments: Array<EmployeeDocumentModel>;
  myEvents: Array<Event>;
  myPersonioDocuments: Array<MyPersonioDocumentsModel>;
  navigations: Array<NavigationModel>;
  notifications: Array<Notification>;
  orders: Array<Order>;
  organizerChats: Array<QuoteChatModel>;
  organizers: Array<Organizer>;
  personioContracts: Array<PersonioContract>;
  personioProjects: PersonioProjectsRoot;
  quotation?: Maybe<Quotation>;
  quoteChat?: Maybe<QuoteChat>;
  quoteChats: Array<QuoteChat>;
  quotes: Array<Quotation>;
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviderChats: Array<QuoteChatModel>;
  serviceProviders: Array<ServiceProvider>;
  setCard: SetCardModel;
  shift?: Maybe<Shift>;
  shiftGroupedByFloors: Array<ShiftGroupByFloorModel>;
  shiftStatusQuery: Shift;
  shifts: Array<Shift>;
  user?: Maybe<User>;
  userRolePermissions: Array<UserRolePermission>;
  userRoles: Array<UserRole>;
  users: Array<User>;
};


export type QueryAccountArgs = {
  where?: InputMaybe<AccountFilterInput>;
};


export type QueryAppNotificationsArgs = {
  where?: InputMaybe<NotificationFilterInput>;
};


export type QueryAssignmentProgressArgs = {
  eventId: Scalars['ID'];
};


export type QueryAvailableShiftsArgs = {
  eventId: Scalars['ID'];
};


export type QueryAvailableShiftsForAssignmentArgs = {
  employeeId?: InputMaybe<Scalars['ID']>;
  eventId: Scalars['ID'];
};


export type QueryBranchArgs = {
  where?: InputMaybe<BranchFilterInput>;
};


export type QueryCancelSettingArgs = {
  where?: InputMaybe<CancelSettingsFilterInput>;
};


export type QueryCanceledShiftsArgs = {
  where?: InputMaybe<CanceledShiftFilterInput>;
};


export type QueryCheckInDeploymentsArgs = {
  where?: InputMaybe<DeploymentFilterInput>;
};


export type QueryCheckInListArgs = {
  eventId?: InputMaybe<Scalars['ID']>;
};


export type QueryChildShiftsArgs = {
  shiftId?: InputMaybe<Scalars['ID']>;
};


export type QueryCompleteEventDataArgs = {
  eventId?: InputMaybe<Scalars['ID']>;
};


export type QueryCountAppNotificationsArgs = {
  target: NotificationTarget;
};


export type QueryCountNotificationsArgs = {
  target: NotificationTarget;
};


export type QueryCountUnreadQuoteChatsArgs = {
  branchId?: InputMaybe<Scalars['ID']>;
  eventId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<QuoteChatStatus>;
};


export type QueryCrmEmployeesArgs = {
  where?: InputMaybe<EmployeeFilterInput>;
};


export type QueryCrmUsersArgs = {
  where?: InputMaybe<UserFilterInput>;
};


export type QueryCustomersArgs = {
  where?: InputMaybe<CoventoCustomerFilterInput>;
};


export type QueryDeploymentArgs = {
  where?: InputMaybe<DeploymentFilterInput>;
};


export type QueryDeploymentShiftsArgs = {
  shiftId?: InputMaybe<Scalars['ID']>;
};


export type QueryDeploymentsArgs = {
  order?: InputMaybe<Array<DeploymentSortInput>>;
  where?: InputMaybe<DeploymentFilterInput>;
};


export type QueryDeploymentsByMonthArgs = {
  employeeId: Scalars['ID'];
  year: Scalars['DateTime'];
};


export type QueryDocumentTypesArgs = {
  where?: InputMaybe<DocumentTypeFilterInput>;
};


export type QueryEmployeeArgs = {
  where?: InputMaybe<EmployeeFilterInput>;
};


export type QueryEmployeeCheckInListArgs = {
  eventId?: InputMaybe<Scalars['ID']>;
};


export type QueryEmployeeDocumentsArgs = {
  employeeId: Scalars['ID'];
};


export type QueryEmployeeEventRequestArgs = {
  where?: InputMaybe<EventRequestFilterInput>;
};


export type QueryEmployeeEventRequestsArgs = {
  where?: InputMaybe<EventRequestFilterInput>;
};


export type QueryEmployeeTeamArgs = {
  where?: InputMaybe<EmployeeTeamFilterInput>;
};


export type QueryEmployeeTeamsArgs = {
  where?: InputMaybe<EmployeeTeamFilterInput>;
};


export type QueryEmployeesArgs = {
  where?: InputMaybe<EmployeeFilterInput>;
};


export type QueryEventArgs = {
  where?: InputMaybe<EventFilterInput>;
};


export type QueryEventDashboardStatsArgs = {
  eventId?: InputMaybe<Scalars['ID']>;
};


export type QueryEventDocumentsArgs = {
  where?: InputMaybe<EventDocumentFilterInput>;
};


export type QueryEventEditShiftsArgs = {
  eventId?: InputMaybe<Scalars['ID']>;
};


export type QueryEventHelpRequestsArgs = {
  where?: InputMaybe<EventHelpRequestFilterInput>;
};


export type QueryEventLocationArgs = {
  where?: InputMaybe<EventLocationFilterInput>;
};


export type QueryEventRequestsArgs = {
  viewMode?: Scalars['String'];
};


export type QueryEventTemplateArgs = {
  where?: InputMaybe<EventTemplateFilterInput>;
};


export type QueryEventsArgs = {
  order?: InputMaybe<Array<EventSortInput>>;
  where?: InputMaybe<EventFilterInput>;
};


export type QueryEventsCheckInListCommentsArgs = {
  order?: InputMaybe<Array<CheckInListCommentSortInput>>;
  where?: InputMaybe<CheckInListCommentFilterInput>;
};


export type QueryIsAvailableForAssignmentArgs = {
  employeeId: Scalars['ID'];
  shiftId: Scalars['ID'];
};


export type QueryManageShiftsArgs = {
  eventId?: InputMaybe<Scalars['ID']>;
};


export type QueryMyBranchesArgs = {
  where?: InputMaybe<BranchFilterInput>;
};


export type QueryMyDeploymentsArgs = {
  isCompleted: Scalars['Boolean'];
};


export type QueryMyDocumentTypesArgs = {
  where?: InputMaybe<DocumentTypeFilterInput>;
};


export type QueryMyEventsArgs = {
  order?: InputMaybe<Array<EventSortInput>>;
  where?: InputMaybe<EventFilterInput>;
};


export type QueryNavigationsArgs = {
  target?: InputMaybe<NotificationTarget>;
};


export type QueryNotificationsArgs = {
  where?: InputMaybe<NotificationFilterInput>;
};


export type QueryOrdersArgs = {
  order?: InputMaybe<Array<OrderSortInput>>;
  where?: InputMaybe<OrderFilterInput>;
};


export type QueryOrganizerChatsArgs = {
  type: QuoteType;
};


export type QueryPersonioContractsArgs = {
  where?: InputMaybe<PersonioContractFilterInput>;
};


export type QueryQuotationArgs = {
  where?: InputMaybe<QuotationFilterInput>;
};


export type QueryQuoteChatArgs = {
  where?: InputMaybe<QuoteChatFilterInput>;
};


export type QueryQuoteChatsArgs = {
  order?: InputMaybe<Array<QuoteChatSortInput>>;
  where?: InputMaybe<QuoteChatFilterInput>;
};


export type QueryQuotesArgs = {
  order?: InputMaybe<Array<QuotationSortInput>>;
  where?: InputMaybe<QuotationFilterInput>;
};


export type QueryServiceProviderArgs = {
  where?: InputMaybe<ServiceProviderFilterInput>;
};


export type QueryServiceProviderChatsArgs = {
  status: QuoteChatStatus;
};


export type QuerySetCardArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryShiftArgs = {
  where?: InputMaybe<ShiftFilterInput>;
};


export type QueryShiftGroupedByFloorsArgs = {
  eventId: Scalars['ID'];
};


export type QueryShiftStatusQueryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryShiftsArgs = {
  where?: InputMaybe<ShiftFilterInput>;
};


export type QueryUserArgs = {
  where?: InputMaybe<UserFilterInput>;
};

export type Quotation = {
  __typename?: 'Quotation';
  account: Account;
  branch: Branch;
  branchId: Scalars['UUID'];
  chat: QuoteChat;
  chatId: Scalars['ID'];
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  customItems: Array<CustomQuoteItem>;
  downloadUrl: Scalars['String'];
  event: Event;
  id: Scalars['ID'];
  items: Array<QuoteItem>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  net: Scalars['Float'];
  order?: Maybe<Order>;
  organizer: Organizer;
  refNumber: Scalars['Int'];
  serviceProvider: ServiceProvider;
  status: QuoteStatus;
  tax: Scalars['Float'];
  total: Scalars['Float'];
  type: QuoteType;
  updatedBy?: Maybe<User>;
  version: Scalars['Int'];
};

export type QuotationFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<QuotationFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableGuidOperationFilterInput>;
  chat?: InputMaybe<QuoteChatFilterInput>;
  chatId?: InputMaybe<ComparableGuidOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customItems?: InputMaybe<ListFilterInputTypeOfCustomQuoteItemFilterInput>;
  downloadUrl?: InputMaybe<StringOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  items?: InputMaybe<ListFilterInputTypeOfQuoteItemFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  net?: InputMaybe<ComparableDoubleOperationFilterInput>;
  or?: InputMaybe<Array<QuotationFilterInput>>;
  order?: InputMaybe<OrderFilterInput>;
  organizer?: InputMaybe<OrganizerFilterInput>;
  refNumber?: InputMaybe<ComparableInt32OperationFilterInput>;
  serviceProvider?: InputMaybe<ServiceProviderFilterInput>;
  status?: InputMaybe<QuoteStatusOperationFilterInput>;
  tax?: InputMaybe<ComparableDoubleOperationFilterInput>;
  total?: InputMaybe<ComparableDoubleOperationFilterInput>;
  type?: InputMaybe<QuoteTypeOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
  version?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type QuotationSortInput = {
  account?: InputMaybe<AccountSortInput>;
  branch?: InputMaybe<BranchSortInput>;
  branchId?: InputMaybe<SortEnumType>;
  chat?: InputMaybe<QuoteChatSortInput>;
  chatId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  downloadUrl?: InputMaybe<SortEnumType>;
  event?: InputMaybe<EventSortInput>;
  id?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  net?: InputMaybe<SortEnumType>;
  order?: InputMaybe<OrderSortInput>;
  organizer?: InputMaybe<OrganizerSortInput>;
  refNumber?: InputMaybe<SortEnumType>;
  serviceProvider?: InputMaybe<ServiceProviderSortInput>;
  status?: InputMaybe<SortEnumType>;
  tax?: InputMaybe<SortEnumType>;
  total?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
  version?: InputMaybe<SortEnumType>;
};

export type QuoteChat = {
  __typename?: 'QuoteChat';
  branch: Branch;
  branchId: Scalars['UUID'];
  closeReason?: Maybe<ChatCloseReason>;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  event: Event;
  eventId: Scalars['UUID'];
  id: Scalars['ID'];
  messages: Array<QuoteMessage>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  organizer: Organizer;
  quotes: Array<Quotation>;
  readBy: Array<Account>;
  serviceProvider: ServiceProvider;
  status: QuoteChatStatus;
  updatedBy?: Maybe<User>;
};

export type QuoteChatFilterInput = {
  and?: InputMaybe<Array<QuoteChatFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableGuidOperationFilterInput>;
  closeReason?: InputMaybe<NullableOfChatCloseReasonOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  eventId?: InputMaybe<ComparableGuidOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  messages?: InputMaybe<ListFilterInputTypeOfQuoteMessageFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<QuoteChatFilterInput>>;
  organizer?: InputMaybe<OrganizerFilterInput>;
  quotes?: InputMaybe<ListFilterInputTypeOfQuotationFilterInput>;
  readBy?: InputMaybe<ListFilterInputTypeOfAccountFilterInput>;
  serviceProvider?: InputMaybe<ServiceProviderFilterInput>;
  status?: InputMaybe<QuoteChatStatusOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type QuoteChatModel = {
  __typename?: 'QuoteChatModel';
  id: Scalars['UUID'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
};

export type QuoteChatSortInput = {
  branch?: InputMaybe<BranchSortInput>;
  branchId?: InputMaybe<SortEnumType>;
  closeReason?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  event?: InputMaybe<EventSortInput>;
  eventId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  organizer?: InputMaybe<OrganizerSortInput>;
  serviceProvider?: InputMaybe<ServiceProviderSortInput>;
  status?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export enum QuoteChatStatus {
  Accepted = 'ACCEPTED',
  Closed = 'CLOSED',
  Pending = 'PENDING'
}

export type QuoteChatStatusOperationFilterInput = {
  eq?: InputMaybe<QuoteChatStatus>;
  in?: InputMaybe<Array<QuoteChatStatus>>;
  neq?: InputMaybe<QuoteChatStatus>;
  nin?: InputMaybe<Array<QuoteChatStatus>>;
};

export type QuoteItem = {
  __typename?: 'QuoteItem';
  account: Account;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  quotation: Quotation;
  shift: Shift;
  skill: EmployeeSkill;
  total: Scalars['Float'];
  updatedBy?: Maybe<User>;
};

export type QuoteItemFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<QuoteItemFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<QuoteItemFilterInput>>;
  price?: InputMaybe<ComparableDoubleOperationFilterInput>;
  quantity?: InputMaybe<ComparableInt32OperationFilterInput>;
  quotation?: InputMaybe<QuotationFilterInput>;
  shift?: InputMaybe<ShiftFilterInput>;
  skill?: InputMaybe<EmployeeSkillOperationFilterInput>;
  total?: InputMaybe<ComparableDoubleOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type QuoteMessage = {
  __typename?: 'QuoteMessage';
  account: Account;
  chat: QuoteChat;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  id: Scalars['ID'];
  message: Scalars['String'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  sendBy: User;
  updatedBy?: Maybe<User>;
};

export type QuoteMessageFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<QuoteMessageFilterInput>>;
  chat?: InputMaybe<QuoteChatFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  message?: InputMaybe<StringOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<QuoteMessageFilterInput>>;
  sendBy?: InputMaybe<UserFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export enum QuoteStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Replaced = 'REPLACED',
  Revisioned = 'REVISIONED',
  Revoked = 'REVOKED'
}

export type QuoteStatusOperationFilterInput = {
  eq?: InputMaybe<QuoteStatus>;
  in?: InputMaybe<Array<QuoteStatus>>;
  neq?: InputMaybe<QuoteStatus>;
  nin?: InputMaybe<Array<QuoteStatus>>;
};

export enum QuoteType {
  Offer = 'OFFER',
  Request = 'REQUEST',
  Revision = 'REVISION'
}

export type QuoteTypeOperationFilterInput = {
  eq?: InputMaybe<QuoteType>;
  in?: InputMaybe<Array<QuoteType>>;
  neq?: InputMaybe<QuoteType>;
  nin?: InputMaybe<Array<QuoteType>>;
};

export type ReleaseCheckInListInput = {
  eventId?: InputMaybe<Scalars['ID']>;
};

export type ReleaseCheckInListPayload = {
  __typename?: 'ReleaseCheckInListPayload';
  event?: Maybe<Event>;
};

export type RequestContactInput = {
  input: SendEmailInput;
};

export type RequestContactPayload = {
  __typename?: 'RequestContactPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type RequestPasswordResetInput = {
  email: Scalars['String'];
  isUser: Scalars['Boolean'];
};

export type RequestPasswordResetPayload = {
  __typename?: 'RequestPasswordResetPayload';
  string?: Maybe<Scalars['String']>;
};

export type RevokeQuoteInput = {
  quoteId?: InputMaybe<Scalars['ID']>;
};

export type RevokeQuotePayload = {
  __typename?: 'RevokeQuotePayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type SendEmailInput = {
  company: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  message: Scalars['String'];
};

export type SendEventPushNotificationsInput = {
  eventId?: InputMaybe<Scalars['ID']>;
  message: Scalars['String'];
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type SendEventPushNotificationsPayload = {
  __typename?: 'SendEventPushNotificationsPayload';
  int?: Maybe<Scalars['Int']>;
};

export type SendQuoteMessageInput = {
  chatId?: InputMaybe<Scalars['ID']>;
  message: Scalars['String'];
};

export type SendQuoteMessagePayload = {
  __typename?: 'SendQuoteMessagePayload';
  quoteMessage?: Maybe<QuoteMessage>;
};

export type ServiceProvider = {
  __typename?: 'ServiceProvider';
  aboutUs?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  branches: Array<Branch>;
  customers: Array<CoventoCustomer>;
  customersRequests: Array<CustomerRequest>;
  discriminator: Scalars['String'];
  email: Scalars['String'];
  employees: Array<Employee>;
  events: Array<Event>;
  hrAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions: Array<AccountPermission>;
  personioUser: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  platformAccess: Scalars['Boolean'];
  readChats: Array<QuoteChat>;
  skills: Array<EmployeeSkillModel>;
  status: AccountStatus;
  termsAndConditionsAccepted: Scalars['Boolean'];
  termsAndConditionsUrl?: Maybe<Scalars['String']>;
  users: Array<User>;
  ventoWorkAccess: Scalars['Boolean'];
};

export type ServiceProviderFilterInput = {
  aboutUs?: InputMaybe<StringOperationFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<ServiceProviderFilterInput>>;
  branches?: InputMaybe<ListFilterInputTypeOfBranchFilterInput>;
  customers?: InputMaybe<ListFilterInputTypeOfCoventoCustomerFilterInput>;
  customersRequests?: InputMaybe<ListFilterInputTypeOfCustomerRequestFilterInput>;
  discriminator?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  events?: InputMaybe<ListFilterInputTypeOfEventFilterInput>;
  hrAccess?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  logoUrl?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ServiceProviderFilterInput>>;
  permissions?: InputMaybe<ListFilterInputTypeOfAccountPermissionFilterInput>;
  personioUser?: InputMaybe<BooleanOperationFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  platformAccess?: InputMaybe<BooleanOperationFilterInput>;
  readChats?: InputMaybe<ListFilterInputTypeOfQuoteChatFilterInput>;
  skills?: InputMaybe<ListFilterInputTypeOfEmployeeSkillModelFilterInput>;
  status?: InputMaybe<AccountStatusOperationFilterInput>;
  termsAndConditionsAccepted?: InputMaybe<BooleanOperationFilterInput>;
  termsAndConditionsUrl?: InputMaybe<StringOperationFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  ventoWorkAccess?: InputMaybe<BooleanOperationFilterInput>;
};

export type ServiceProviderSortInput = {
  aboutUs?: InputMaybe<SortEnumType>;
  address?: InputMaybe<AddressSortInput>;
  discriminator?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  hrAccess?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  logoUrl?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  personioUser?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  platformAccess?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  termsAndConditionsAccepted?: InputMaybe<SortEnumType>;
  termsAndConditionsUrl?: InputMaybe<SortEnumType>;
  ventoWorkAccess?: InputMaybe<SortEnumType>;
};

export type SetAccountSkillsInput = {
  skill: EmployeeSkill;
};

export type SetAccountSkillsPayload = {
  __typename?: 'SetAccountSkillsPayload';
  account?: Maybe<Account>;
};

export type SetCancelSettingsInput = {
  input: CreateCancelSettingsInput;
};

export type SetCancelSettingsPayload = {
  __typename?: 'SetCancelSettingsPayload';
  cancelSettings?: Maybe<CancelSettings>;
};

export type SetCardModel = {
  __typename?: 'SetCardModel';
  employee: Employee;
  next: Array<Deployment>;
  past: Array<Deployment>;
};

export type SetDeploymentTimesByEmployeeInput = {
  input: SetDeploymentTimesInput;
};

export type SetDeploymentTimesByEmployeePayload = {
  __typename?: 'SetDeploymentTimesByEmployeePayload';
  deployment?: Maybe<Deployment>;
};

export type SetDeploymentTimesInput = {
  breakTime?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  deploymentId?: InputMaybe<Scalars['ID']>;
  employeeStatus?: InputMaybe<DeploymentEmployeeStatus>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type SetDeploymentTimesPayload = {
  __typename?: 'SetDeploymentTimesPayload';
  deployment?: Maybe<Deployment>;
};

export type SetEmployeePasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
  userId?: InputMaybe<Scalars['ID']>;
};

export type SetEmployeePasswordPayload = {
  __typename?: 'SetEmployeePasswordPayload';
  employee?: Maybe<Employee>;
};

export type SetEventInfoMessageInput = {
  eventId?: InputMaybe<Scalars['ID']>;
  message: Scalars['String'];
};

export type SetEventInfoMessagePayload = {
  __typename?: 'SetEventInfoMessagePayload';
  event?: Maybe<Event>;
};

export type SetManyDeploymentTimesInput = {
  inputs: Array<SetDeploymentTimesInput>;
};

export type SetManyDeploymentTimesPayload = {
  __typename?: 'SetManyDeploymentTimesPayload';
  string?: Maybe<Scalars['String']>;
};

export type SetPersonioTimesInput = {
  deploymentId: Scalars['UUID'];
};

export type SetPersonioTimesPayload = {
  __typename?: 'SetPersonioTimesPayload';
  personioResponse?: Maybe<PersonioResponse>;
};

export type SetPushTokenInput = {
  token: Scalars['String'];
};

export type SetPushTokenPayload = {
  __typename?: 'SetPushTokenPayload';
  employee?: Maybe<Employee>;
};

export type SetUserPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
  userId?: InputMaybe<Scalars['ID']>;
};

export type SetUserPasswordPayload = {
  __typename?: 'SetUserPasswordPayload';
  user?: Maybe<User>;
};

export type Shift = {
  __typename?: 'Shift';
  account?: Maybe<Account>;
  branch?: Maybe<Branch>;
  branchId?: Maybe<Scalars['ID']>;
  childShifts: Array<Shift>;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  currentQuantity: Scalars['Int'];
  deployments: Array<Deployment>;
  endDate: Scalars['DateTime'];
  event?: Maybe<Event>;
  eventId?: Maybe<Scalars['ID']>;
  eventRequests: Array<EventRequest>;
  eventTemplate?: Maybe<EventTemplate>;
  floorName?: Maybe<Scalars['String']>;
  hours: Scalars['Float'];
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  parentEvent?: Maybe<Event>;
  parentEventId?: Maybe<Scalars['UUID']>;
  parentShift?: Maybe<Shift>;
  parentShiftId?: Maybe<Scalars['ID']>;
  preFilledEmployees: Array<Employee>;
  price?: Maybe<Scalars['Float']>;
  publishStatus: Scalars['String'];
  quoteItems: Array<QuoteItem>;
  requiredQuantity: Scalars['Int'];
  section?: Maybe<Scalars['String']>;
  shiftNumber: Scalars['Int'];
  skill: EmployeeSkill;
  startDate: Scalars['DateTime'];
  status: ShiftStatus;
  updatedBy?: Maybe<User>;
};


export type ShiftQuoteItemsArgs = {
  where?: InputMaybe<QuoteItemFilterInput>;
};

export enum ShiftDispatchType {
  Assigned = 'ASSIGNED',
  Employee = 'EMPLOYEE',
  Offer = 'OFFER',
  Request = 'REQUEST'
}

export type ShiftEditModel = {
  __typename?: 'ShiftEditModel';
  floor?: Maybe<Scalars['String']>;
  items: Array<ShiftItemModel>;
};

export type ShiftFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<ShiftFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  childShifts?: InputMaybe<ListFilterInputTypeOfShiftFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  currentQuantity?: InputMaybe<ComparableInt32OperationFilterInput>;
  deployments?: InputMaybe<ListFilterInputTypeOfDeploymentFilterInput>;
  endDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  eventId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  eventRequests?: InputMaybe<ListFilterInputTypeOfEventRequestFilterInput>;
  eventTemplate?: InputMaybe<EventTemplateFilterInput>;
  floorName?: InputMaybe<StringOperationFilterInput>;
  hours?: InputMaybe<ComparableDoubleOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ShiftFilterInput>>;
  parentEvent?: InputMaybe<EventFilterInput>;
  parentEventId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  parentShift?: InputMaybe<ShiftFilterInput>;
  parentShiftId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  preFilledEmployees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  price?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  publishStatus?: InputMaybe<StringOperationFilterInput>;
  quoteItems?: InputMaybe<ListFilterInputTypeOfQuoteItemFilterInput>;
  requiredQuantity?: InputMaybe<ComparableInt32OperationFilterInput>;
  section?: InputMaybe<StringOperationFilterInput>;
  shiftNumber?: InputMaybe<ComparableInt32OperationFilterInput>;
  skill?: InputMaybe<EmployeeSkillOperationFilterInput>;
  startDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  status?: InputMaybe<ShiftStatusOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type ShiftGroupByFloorModel = {
  __typename?: 'ShiftGroupByFloorModel';
  floor: Scalars['String'];
  shifts: Array<Shift>;
};

export type ShiftItemModel = {
  __typename?: 'ShiftItemModel';
  childrens: Array<SingleShiftItemModel>;
  parent: Shift;
};

export type ShiftSortInput = {
  account?: InputMaybe<AccountSortInput>;
  branch?: InputMaybe<BranchSortInput>;
  branchId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  currentQuantity?: InputMaybe<SortEnumType>;
  endDate?: InputMaybe<SortEnumType>;
  event?: InputMaybe<EventSortInput>;
  eventId?: InputMaybe<SortEnumType>;
  eventTemplate?: InputMaybe<EventTemplateSortInput>;
  floorName?: InputMaybe<SortEnumType>;
  hours?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  parentEvent?: InputMaybe<EventSortInput>;
  parentEventId?: InputMaybe<SortEnumType>;
  parentShift?: InputMaybe<ShiftSortInput>;
  parentShiftId?: InputMaybe<SortEnumType>;
  price?: InputMaybe<SortEnumType>;
  publishStatus?: InputMaybe<SortEnumType>;
  requiredQuantity?: InputMaybe<SortEnumType>;
  section?: InputMaybe<SortEnumType>;
  shiftNumber?: InputMaybe<SortEnumType>;
  skill?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export enum ShiftStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

export type ShiftStatusOperationFilterInput = {
  eq?: InputMaybe<ShiftStatus>;
  in?: InputMaybe<Array<ShiftStatus>>;
  neq?: InputMaybe<ShiftStatus>;
  nin?: InputMaybe<Array<ShiftStatus>>;
};

export type SignDocumentInput = {
  deploymentId?: InputMaybe<Scalars['ID']>;
  parentCode?: InputMaybe<Scalars['String']>;
};

export type SignDocumentPayload = {
  __typename?: 'SignDocumentPayload';
  personioContract?: Maybe<PersonioContract>;
};

export type SignDocumentsPayload = {
  __typename?: 'SignDocumentsPayload';
  int?: Maybe<Scalars['Int']>;
};

export type SingleShiftItemModel = {
  __typename?: 'SingleShiftItemModel';
  accountsCount?: Maybe<Scalars['Int']>;
  branchesCount?: Maybe<Scalars['Int']>;
  customer?: Maybe<Customer>;
  employee?: Maybe<Employee>;
  endDate: Scalars['DateTime'];
  eventHelpRequest?: Maybe<Scalars['Int']>;
  floorName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  quotesCount?: Maybe<Scalars['Int']>;
  section?: Maybe<Scalars['String']>;
  shiftId: Scalars['ID'];
  skill: EmployeeSkill;
  startDate: Scalars['DateTime'];
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type TransmitCheckInListInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type TransmitCheckInListPayload = {
  __typename?: 'TransmitCheckInListPayload';
  event?: Maybe<Event>;
};

export type UpdateAccountAddressInput = {
  input: CreateAddressInput;
};

export type UpdateAccountAddressPayload = {
  __typename?: 'UpdateAccountAddressPayload';
  account?: Maybe<Account>;
};

export type UpdateAccountInput = {
  email: Scalars['String'];
  hrAccess: Scalars['Boolean'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  permissionInputs: Array<CreateAccountPermissionInput>;
  platformAccess: Scalars['Boolean'];
  ventoWorkAccess: Scalars['Boolean'];
};

export type UpdateAccountPayload = {
  __typename?: 'UpdateAccountPayload';
  account?: Maybe<Account>;
};

export type UpdateAccountStatusInput = {
  accountId: Scalars['ID'];
  status: AccountStatus;
};

export type UpdateAccountStatusPayload = {
  __typename?: 'UpdateAccountStatusPayload';
  account?: Maybe<Account>;
};

export type UpdateBranchInput = {
  address?: InputMaybe<CreateAddressInput>;
  branchId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateBranchPayload = {
  __typename?: 'UpdateBranchPayload';
  branch?: Maybe<Branch>;
};

export type UpdateBranchStatusInput = {
  branchId: Scalars['ID'];
  isActive: Scalars['Boolean'];
};

export type UpdateBranchStatusPayload = {
  __typename?: 'UpdateBranchStatusPayload';
  branch?: Maybe<Branch>;
};

export type UpdateCancelRequestStatusInput = {
  requestId?: InputMaybe<Scalars['ID']>;
  status: QuoteStatus;
};

export type UpdateCancelRequestStatusPayload = {
  __typename?: 'UpdateCancelRequestStatusPayload';
  eventCancelRequest?: Maybe<EventCancelRequest>;
};

export type UpdateCustomerPricesInput = {
  customerId: Scalars['ID'];
  prices: Array<CreateCustomerPriceInput>;
};

export type UpdateCustomerPricesPayload = {
  __typename?: 'UpdateCustomerPricesPayload';
  customerRequest?: Maybe<CustomerRequest>;
};

export type UpdateCustomerRequestStatusInput = {
  id?: InputMaybe<Scalars['ID']>;
  status: CustomerRequestStatus;
};

export type UpdateCustomerRequestStatusPayload = {
  __typename?: 'UpdateCustomerRequestStatusPayload';
  customerRequest?: Maybe<CustomerRequest>;
};

export type UpdateDeploymentInput = {
  deploymentId: Scalars['UUID'];
  shiftId: Scalars['UUID'];
};

export type UpdateDeploymentPayload = {
  __typename?: 'UpdateDeploymentPayload';
  deployment?: Maybe<Deployment>;
};

export type UpdateDocumentTypeInput = {
  hasExpiration: Scalars['Boolean'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  relationships: Array<EmploymentRelationship>;
};

export type UpdateDocumentTypePayload = {
  __typename?: 'UpdateDocumentTypePayload';
  documentType?: Maybe<DocumentType>;
};

export type UpdateEmployeeInput = {
  id?: InputMaybe<Scalars['ID']>;
  input: CreateEmployeeInput;
};

export type UpdateEmployeePasswordInput = {
  newPassword: Scalars['String'];
  password: Scalars['String'];
};

export type UpdateEmployeePasswordPayload = {
  __typename?: 'UpdateEmployeePasswordPayload';
  employee?: Maybe<Employee>;
};

export type UpdateEmployeePayload = {
  __typename?: 'UpdateEmployeePayload';
  employee?: Maybe<Employee>;
};

export type UpdateEmployeeStatusInput = {
  id?: InputMaybe<Scalars['ID']>;
  status: EmployeeStatus;
};

export type UpdateEmployeeStatusPayload = {
  __typename?: 'UpdateEmployeeStatusPayload';
  employee?: Maybe<Employee>;
};

export type UpdateEmployeeTeamInput = {
  branchId?: InputMaybe<Scalars['ID']>;
  employees?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name: Scalars['String'];
  teamId?: InputMaybe<Scalars['ID']>;
};

export type UpdateEmployeeTeamPayload = {
  __typename?: 'UpdateEmployeeTeamPayload';
  employeeTeam?: Maybe<EmployeeTeam>;
};

export type UpdateEventDocumentInput = {
  documentId: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateEventDocumentPayload = {
  __typename?: 'UpdateEventDocumentPayload';
  eventDocument?: Maybe<EventDocument>;
};

export type UpdateEventHelpRequestInput = {
  requestId?: InputMaybe<Scalars['ID']>;
  status: EventRequestStatus;
};

export type UpdateEventHelpRequestPayload = {
  __typename?: 'UpdateEventHelpRequestPayload';
  eventHelpRequest?: Maybe<EventHelpRequest>;
};

export type UpdateEventInput = {
  addressInput: CreateAddressInput;
  addressNotes?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  eventId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  numberOfGuests: Scalars['Int'];
  personioProjectId?: InputMaybe<Scalars['Int']>;
  personioProjectName?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  type: EventType;
  withTemplate: Scalars['Boolean'];
};

export type UpdateEventLocationInput = {
  floors: Array<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type UpdateEventLocationPayload = {
  __typename?: 'UpdateEventLocationPayload';
  eventLocation?: Maybe<EventLocation>;
};

export type UpdateEventOrderTypeInput = {
  eventId?: InputMaybe<Scalars['ID']>;
  orderType: EventOrderType;
};

export type UpdateEventOrderTypePayload = {
  __typename?: 'UpdateEventOrderTypePayload';
  event?: Maybe<Event>;
};

export type UpdateEventPayload = {
  __typename?: 'UpdateEventPayload';
  event?: Maybe<Event>;
};

export type UpdateEventRequestInput = {
  message?: InputMaybe<Scalars['String']>;
  requestId?: InputMaybe<Scalars['ID']>;
  status: EventRequestStatus;
};

export type UpdateEventRequestPayload = {
  __typename?: 'UpdateEventRequestPayload';
  eventRequest?: Maybe<EventRequest>;
};

export type UpdateEventStatusInput = {
  eventId?: InputMaybe<Scalars['ID']>;
  status: EventStatus;
};

export type UpdateEventStatusPayload = {
  __typename?: 'UpdateEventStatusPayload';
  updateEventStatusResponseModel?: Maybe<UpdateEventStatusResponseModel>;
};

export type UpdateEventStatusResponseModel = {
  __typename?: 'UpdateEventStatusResponseModel';
  errorsCount: Scalars['Int'];
  event: Event;
  successCount: Scalars['Int'];
};

export type UpdateEventTemplateInput = {
  input: CreateEventTemplateInput;
  templateId: Scalars['ID'];
};

export type UpdateEventTemplatePayload = {
  __typename?: 'UpdateEventTemplatePayload';
  eventTemplate?: Maybe<EventTemplate>;
};

export type UpdateMyAccountInput = {
  aboutUs?: InputMaybe<Scalars['String']>;
  address: CreateAddressInput;
  email: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateMyAccountPayload = {
  __typename?: 'UpdateMyAccountPayload';
  account?: Maybe<Account>;
};

export type UpdateShiftInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  requiredQuantity: Scalars['Int'];
  section?: InputMaybe<Scalars['String']>;
  skill: EmployeeSkill;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateShiftPayload = {
  __typename?: 'UpdateShiftPayload';
  shift?: Maybe<Shift>;
};

export type UpdateUserInput = {
  branchIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  lastName: Scalars['String'];
  role: Scalars['ID'];
};

export type UpdateUserPasswordInput = {
  newPassword: Scalars['String'];
  password: Scalars['String'];
};

export type UpdateUserPasswordPayload = {
  __typename?: 'UpdateUserPasswordPayload';
  user?: Maybe<User>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user?: Maybe<User>;
};

export type UpdateUserRoleInput = {
  name: Scalars['String'];
  permissionIds?: InputMaybe<Array<Scalars['ID']>>;
  roleId: Scalars['ID'];
};

export type UpdateUserRolePayload = {
  __typename?: 'UpdateUserRolePayload';
  userRole?: Maybe<UserRole>;
};

export type UpdateUserRoleStatusInput = {
  isActive: Scalars['Boolean'];
  roleId?: InputMaybe<Scalars['ID']>;
};

export type UpdateUserRoleStatusPayload = {
  __typename?: 'UpdateUserRoleStatusPayload';
  userRole?: Maybe<UserRole>;
};

export type UpdateUserStatusInput = {
  status: AccountStatus;
  userId?: InputMaybe<Scalars['ID']>;
};

export type UpdateUserStatusPayload = {
  __typename?: 'UpdateUserStatusPayload';
  user?: Maybe<User>;
};

export type User = {
  __typename?: 'User';
  account: Account;
  branches: Array<Branch>;
  credentials: Credentials;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  marketPlaceAccess: Scalars['Boolean'];
  myCoventoAccess: Scalars['Boolean'];
  passwordResetCode?: Maybe<Array<Scalars['Byte']>>;
  passwordResetStart?: Maybe<Scalars['DateTime']>;
  planningAccess: Scalars['Boolean'];
  role?: Maybe<UserRole>;
  roleId?: Maybe<Scalars['UUID']>;
  status: AccountStatus;
};

export type UserFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<UserFilterInput>>;
  branches?: InputMaybe<ListFilterInputTypeOfBranchFilterInput>;
  credentials?: InputMaybe<CredentialsFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  marketPlaceAccess?: InputMaybe<BooleanOperationFilterInput>;
  myCoventoAccess?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  passwordResetCode?: InputMaybe<ListComparableByteOperationFilterInput>;
  passwordResetStart?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  planningAccess?: InputMaybe<BooleanOperationFilterInput>;
  role?: InputMaybe<UserRoleFilterInput>;
  roleId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  status?: InputMaybe<AccountStatusOperationFilterInput>;
};

export type UserLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UserLoginPayload = {
  __typename?: 'UserLoginPayload';
  string?: Maybe<Scalars['String']>;
};

export type UserRole = {
  __typename?: 'UserRole';
  account: Account;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  permissions: Array<UserRolePermission>;
  users: Array<User>;
};

export type UserRoleFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<UserRoleFilterInput>>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UserRoleFilterInput>>;
  permissions?: InputMaybe<ListFilterInputTypeOfUserRolePermissionFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
};

export type UserRolePermission = {
  __typename?: 'UserRolePermission';
  description: Scalars['String'];
  id: Scalars['ID'];
  product: NotificationTarget;
  roles: Array<UserRole>;
  scope: UserRolePermissionScope;
  target: UserRolePermissionTarget;
};

export type UserRolePermissionFilterInput = {
  and?: InputMaybe<Array<UserRolePermissionFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<UserRolePermissionFilterInput>>;
  product?: InputMaybe<NotificationTargetOperationFilterInput>;
  roles?: InputMaybe<ListFilterInputTypeOfUserRoleFilterInput>;
  scope?: InputMaybe<UserRolePermissionScopeOperationFilterInput>;
  target?: InputMaybe<UserRolePermissionTargetOperationFilterInput>;
};

export enum UserRolePermissionScope {
  Create = 'CREATE',
  Delete = 'DELETE',
  Edit = 'EDIT',
  Read = 'READ',
  UpdateStatus = 'UPDATE_STATUS'
}

export type UserRolePermissionScopeOperationFilterInput = {
  eq?: InputMaybe<UserRolePermissionScope>;
  in?: InputMaybe<Array<UserRolePermissionScope>>;
  neq?: InputMaybe<UserRolePermissionScope>;
  nin?: InputMaybe<Array<UserRolePermissionScope>>;
};

export enum UserRolePermissionTarget {
  Account = 'ACCOUNT',
  Branch = 'BRANCH',
  CheckInList = 'CHECK_IN_LIST',
  Customer = 'CUSTOMER',
  CustomerRequest = 'CUSTOMER_REQUEST',
  Department = 'DEPARTMENT',
  Deployment = 'DEPLOYMENT',
  DocumentType = 'DOCUMENT_TYPE',
  Employee = 'EMPLOYEE',
  EmployeeRequest = 'EMPLOYEE_REQUEST',
  EmployeeTeam = 'EMPLOYEE_TEAM',
  Event = 'EVENT',
  EventHelpRequest = 'EVENT_HELP_REQUEST',
  EventLocation = 'EVENT_LOCATION',
  Quote = 'QUOTE',
  Shift = 'SHIFT',
  User = 'USER',
  UserRole = 'USER_ROLE'
}

export type UserRolePermissionTargetOperationFilterInput = {
  eq?: InputMaybe<UserRolePermissionTarget>;
  in?: InputMaybe<Array<UserRolePermissionTarget>>;
  neq?: InputMaybe<UserRolePermissionTarget>;
  nin?: InputMaybe<Array<UserRolePermissionTarget>>;
};

export type UserRoleSortInput = {
  account?: InputMaybe<AccountSortInput>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type UserSortInput = {
  account?: InputMaybe<AccountSortInput>;
  credentials?: InputMaybe<CredentialsSortInput>;
  email?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  marketPlaceAccess?: InputMaybe<SortEnumType>;
  myCoventoAccess?: InputMaybe<SortEnumType>;
  passwordResetStart?: InputMaybe<SortEnumType>;
  planningAccess?: InputMaybe<SortEnumType>;
  role?: InputMaybe<UserRoleSortInput>;
  roleId?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

export type AccountSkillsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountSkillsQuery = { __typename?: 'Query', accountSkills: Array<{ __typename?: 'EmployeeSkillModel', id: string, skill: EmployeeSkill }> };

export type CancelSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type CancelSettingsQuery = { __typename?: 'Query', cancelSettings: Array<{ __typename?: 'CancelSettings', fromFriday?: any | null, fromMonday?: any | null, fromSaturday?: any | null, fromSunday?: any | null, fromThursday?: any | null, fromTuesday?: any | null, fromWednesday?: any | null, toFriday?: any | null, toMonday?: any | null, toSaturday?: any | null, toSunday?: any | null, toThursday?: any | null, toTuesday?: any | null, toWednesday?: any | null, phone: string, hoursBeforeEvent: number, email: string, branch?: { __typename?: 'Branch', id: string, name: string } | null }> };

export type LoginMutationVariables = Exact<{
  input: UserLoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', userLogin: { __typename?: 'UserLoginPayload', string?: string | null } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, firstName: string, lastName: string, email: string, account: { __typename?: 'Account', id: string, name: string, discriminator: string, ventoWorkAccess: boolean, personioUser: boolean, permissions: Array<{ __typename?: 'AccountPermission', id: string, scope: AccountPermissionScope }> }, role?: { __typename?: 'UserRole', id: string, permissions: Array<{ __typename?: 'UserRolePermission', id: string, target: UserRolePermissionTarget, scope: UserRolePermissionScope }> } | null } };

export type RequestPasswordResetMutationVariables = Exact<{
  input: RequestPasswordResetInput;
}>;


export type RequestPasswordResetMutation = { __typename?: 'Mutation', requestPasswordReset: { __typename?: 'RequestPasswordResetPayload', string?: string | null } };

export type SetEmployeePasswordMutationVariables = Exact<{
  input: SetEmployeePasswordInput;
}>;


export type SetEmployeePasswordMutation = { __typename?: 'Mutation', setEmployeePassword: { __typename?: 'SetEmployeePasswordPayload', employee?: { __typename?: 'Employee', id: string } | null } };

export type UpdateUserPasswordMutationVariables = Exact<{
  input: UpdateUserPasswordInput;
}>;


export type UpdateUserPasswordMutation = { __typename?: 'Mutation', updateUserPassword: { __typename?: 'UpdateUserPasswordPayload', user?: { __typename?: 'User', id: string } | null } };

export type BranchQueryVariables = Exact<{
  where?: InputMaybe<BranchFilterInput>;
}>;


export type BranchQuery = { __typename?: 'Query', branch?: { __typename?: 'Branch', id: string, name: string, email?: string | null, phone?: string | null } | null };

export type BranchesQueryVariables = Exact<{ [key: string]: never; }>;


export type BranchesQuery = { __typename?: 'Query', myBranches: Array<{ __typename?: 'Branch', id: string, name: string }> };

export type CustomerRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerRequestsQuery = { __typename?: 'Query', customerRequests: Array<{ __typename?: 'CustomerRequest', id: string, creationTime: any, customerPrices: Array<{ __typename?: 'CustomerPrice', id: string, skill: EmployeeSkill, price: number }>, customerBranch?: { __typename?: 'Branch', id: string, name: string } | null, account: { __typename?: 'Account', id: string, name: string }, organizer?: { __typename?: 'Organizer', id: string, name: string } | null, serviceProvider?: { __typename?: 'ServiceProvider', id: string, name: string } | null }> };

export type CustomersQueryVariables = Exact<{
  where?: InputMaybe<CoventoCustomerFilterInput>;
}>;


export type CustomersQuery = { __typename?: 'Query', customers: Array<{ __typename?: 'CoventoCustomer', id: string, name?: string | null, email?: string | null, serviceProvider?: { __typename?: 'ServiceProvider', id: string, name: string } | null, customerPrices: Array<{ __typename?: 'CustomerPrice', id: string, skill: EmployeeSkill, price: number }> }> };

export type UpdateCustomerPricesMutationVariables = Exact<{
  input: UpdateCustomerPricesInput;
}>;


export type UpdateCustomerPricesMutation = { __typename?: 'Mutation', updateCustomerPrices: { __typename?: 'UpdateCustomerPricesPayload', customerRequest?: { __typename?: 'CustomerRequest', id: string } | null } };

export type UpdateCustomerRequestStatusMutationVariables = Exact<{
  input: UpdateCustomerRequestStatusInput;
}>;


export type UpdateCustomerRequestStatusMutation = { __typename?: 'Mutation', updateCustomerRequestStatus: { __typename?: 'UpdateCustomerRequestStatusPayload', customerRequest?: { __typename?: 'CustomerRequest', id: string } | null } };

export type CreateDeploymentMutationVariables = Exact<{
  input: CreateDeploymentInput;
}>;


export type CreateDeploymentMutation = { __typename?: 'Mutation', createDeployment: { __typename?: 'CreateDeploymentPayload', deployment?: { __typename?: 'Deployment', id: string, shift: { __typename?: 'Shift', id: string, currentQuantity: number } } | null } };

export type DeleteDeploymentMutationVariables = Exact<{
  input: DeleteDeploymentInput;
}>;


export type DeleteDeploymentMutation = { __typename?: 'Mutation', deleteDeployment: { __typename?: 'DeleteDeploymentPayload', deployment?: { __typename?: 'Deployment', shift: { __typename?: 'Shift', id: string, currentQuantity: number } } | null } };

export type DeploymentsQueryVariables = Exact<{
  where?: InputMaybe<DeploymentFilterInput>;
  order?: InputMaybe<Array<DeploymentSortInput> | DeploymentSortInput>;
}>;


export type DeploymentsQuery = { __typename?: 'Query', deployments: Array<{ __typename?: 'Deployment', id: string, shift: { __typename?: 'Shift', id: string, startDate: any, endDate: any, skill: EmployeeSkill, floorName?: string | null, section?: string | null, event?: { __typename?: 'Event', id: string, name: string } | null } }> };

export type DeploymentsByMonthQueryVariables = Exact<{
  employeeID: Scalars['ID'];
  year: Scalars['DateTime'];
}>;


export type DeploymentsByMonthQuery = { __typename?: 'Query', deploymentsByMonth: Array<{ __typename?: 'DeploymentByMonthModel', totalHours: number, totalDeployments: number, month: string, year: string }> };

export type IsAvailableForAssignmentQueryVariables = Exact<{
  employeeId: Scalars['ID'];
  shiftId: Scalars['ID'];
}>;


export type IsAvailableForAssignmentQuery = { __typename?: 'Query', isAvailableForAssignment: boolean };

export type MarkDeploymentAsReserveMutationVariables = Exact<{
  input: MarkAsReserveInput;
}>;


export type MarkDeploymentAsReserveMutation = { __typename?: 'Mutation', markAsReserve: { __typename?: 'MarkAsReservePayload', deployment?: { __typename?: 'Deployment', id: string, isReserve: boolean } | null } };

export type PublishDeploymentMutationVariables = Exact<{
  input: PublishDeploymentsInput;
}>;


export type PublishDeploymentMutation = { __typename?: 'Mutation', publishDeployments: { __typename?: 'PublishDeploymentsPayload', deployment?: Array<{ __typename?: 'Deployment', id: string, employeeStatus: DeploymentEmployeeStatus }> | null } };

export type SetDeploymentTimesMutationVariables = Exact<{
  input: SetDeploymentTimesInput;
}>;


export type SetDeploymentTimesMutation = { __typename?: 'Mutation', setDeploymentTimes: { __typename?: 'SetDeploymentTimesPayload', deployment?: { __typename?: 'Deployment', id: string } | null } };

export type UpdateDeploymentMutationVariables = Exact<{
  input: UpdateDeploymentInput;
}>;


export type UpdateDeploymentMutation = { __typename?: 'Mutation', updateDeployment: { __typename?: 'UpdateDeploymentPayload', deployment?: { __typename?: 'Deployment', id: string } | null } };

export type CreateDocumentTypeMutationVariables = Exact<{
  input: CreateDocumentTypeInput;
}>;


export type CreateDocumentTypeMutation = { __typename?: 'Mutation', createDocumentType: { __typename?: 'CreateDocumentTypePayload', documentType?: { __typename?: 'DocumentType', id: string } | null } };

export type CreateEmployeeMutationVariables = Exact<{
  input: CreateEmployeeInput;
}>;


export type CreateEmployeeMutation = { __typename?: 'Mutation', createEmployee: { __typename?: 'CreateEmployeePayload', employee?: { __typename?: 'Employee', id: string } | null } };

export type CreateEmployeeTeamMutationVariables = Exact<{
  input: CreateEmployeeTeamInput;
}>;


export type CreateEmployeeTeamMutation = { __typename?: 'Mutation', createEmployeeTeam: { __typename?: 'CreateEmployeeTeamPayload', employeeTeam?: { __typename?: 'EmployeeTeam', id: string } | null } };

export type DeleteDocumentTypeMutationVariables = Exact<{
  input: DeleteDocumentTypeInput;
}>;


export type DeleteDocumentTypeMutation = { __typename?: 'Mutation', deleteDocumentType: { __typename?: 'DeleteDocumentTypePayload', boolean?: boolean | null } };

export type DeleteEmployeeTeamMutationVariables = Exact<{
  input: DeleteEmployeeTeamInput;
}>;


export type DeleteEmployeeTeamMutation = { __typename?: 'Mutation', deleteEmployeeTeam: { __typename?: 'DeleteEmployeeTeamPayload', boolean?: boolean | null } };

export type EmployeeDocumentTypesQueryVariables = Exact<{
  where?: InputMaybe<DocumentTypeFilterInput>;
}>;


export type EmployeeDocumentTypesQuery = { __typename?: 'Query', documentTypes: Array<{ __typename?: 'DocumentType', id: string, name: string, hasExpiration: boolean, employmentRelations: Array<{ __typename?: 'EmploymentRelationModel', id: string, employmentRelationship: EmploymentRelationship }>, employeeDocuments: Array<{ __typename?: 'EmployeeDocument', id: string }> }> };

export type EmployeeQueryVariables = Exact<{
  where: EmployeeFilterInput;
}>;


export type EmployeeQuery = { __typename?: 'Query', employee?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, parentEmail?: string | null, gender?: Gender | null, socialSecurityNumber?: string | null, phone?: string | null, taxId?: string | null, dateOfBirth?: any | null, salary?: any | null, employmentRelationship?: EmploymentRelationship | null, skills: Array<{ __typename?: 'EmployeeSkillModel', id: string, skill: EmployeeSkill }>, teams: Array<{ __typename?: 'EmployeeTeam', id: string, name: string }>, branch: { __typename?: 'Branch', id: string, name: string }, address?: { __typename?: 'Address', id: string, street?: string | null, streetNumber?: string | null, city?: string | null, zip?: string | null, formattedAddress?: string | null, latitude?: number | null, longitude?: number | null } | null } | null };

export type EmployeeDocumentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EmployeeDocumentsQuery = { __typename?: 'Query', employeeDocuments: Array<{ __typename?: 'EmployeeDocumentModel', type: { __typename?: 'DocumentType', id: string, name: string, hasExpiration: boolean }, document?: { __typename?: 'EmployeeDocument', id: string, validFrom?: any | null, validTo?: any | null, url: string } | null }> };

export type EmployeeTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type EmployeeTeamsQuery = { __typename?: 'Query', employeeTeams: Array<{ __typename?: 'EmployeeTeam', id: string, name: string, branch: { __typename?: 'Branch', id: string, name: string }, employees: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string }> }> };

export type EmployeesQueryVariables = Exact<{
  where?: InputMaybe<EmployeeFilterInput>;
}>;


export type EmployeesQuery = { __typename?: 'Query', employees: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, gender?: Gender | null, staffNumber: number, employmentRelationship?: EmploymentRelationship | null, dateOfBirth?: any | null, status: EmployeeStatus, pictureUrl?: string | null, isOfAge: boolean, requireDailyContract: boolean, salary?: any | null, branch: { __typename?: 'Branch', id: string, name: string }, skills: Array<{ __typename?: 'EmployeeSkillModel', id: string, skill: EmployeeSkill }> }> };

export type ImportEmployeesMutationVariables = Exact<{
  input: ImportEmployeeInput;
}>;


export type ImportEmployeesMutation = { __typename?: 'Mutation', importEmployee: { __typename?: 'ImportEmployeePayload', employee?: Array<{ __typename?: 'Employee', id: string }> | null } };

export type InviteEmployeeMutationVariables = Exact<{
  input: InviteEmployeeInput;
}>;


export type InviteEmployeeMutation = { __typename?: 'Mutation', inviteEmployee: { __typename?: 'InviteEmployeePayload', employee?: { __typename?: 'Employee', id: string } | null } };

export type PersonioMutationVariables = Exact<{ [key: string]: never; }>;


export type PersonioMutation = { __typename?: 'Mutation', personioEmployees: { __typename?: 'PersonioEmployeesPayload', employee?: Array<{ __typename?: 'Employee', id: string }> | null } };

export type PersonioDocumentsQueryVariables = Exact<{
  where?: InputMaybe<PersonioContractFilterInput>;
}>;


export type PersonioDocumentsQuery = { __typename?: 'Query', personioContracts: Array<{ __typename?: 'PersonioContract', id: string, signedUrl?: string | null, deployment: { __typename?: 'Deployment', id: string, shift: { __typename?: 'Shift', id: string, startDate: any }, event: { __typename?: 'Event', id: string, name: string } } }> };

export type UpdateDocumentTypeMutationVariables = Exact<{
  input: UpdateDocumentTypeInput;
}>;


export type UpdateDocumentTypeMutation = { __typename?: 'Mutation', updateDocumentType: { __typename?: 'UpdateDocumentTypePayload', documentType?: { __typename?: 'DocumentType', id: string } | null } };

export type UpdateEmployeeMutationVariables = Exact<{
  input: UpdateEmployeeInput;
}>;


export type UpdateEmployeeMutation = { __typename?: 'Mutation', updateEmployee: { __typename?: 'UpdateEmployeePayload', employee?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, gender?: Gender | null, dateOfBirth?: any | null, employmentRelationship?: EmploymentRelationship | null, status: EmployeeStatus, branch: { __typename?: 'Branch', id: string, name: string }, skills: Array<{ __typename?: 'EmployeeSkillModel', id: string, skill: EmployeeSkill }> } | null } };

export type UpdateEmployeeTeamMutationVariables = Exact<{
  input: UpdateEmployeeTeamInput;
}>;


export type UpdateEmployeeTeamMutation = { __typename?: 'Mutation', updateEmployeeTeam: { __typename?: 'UpdateEmployeeTeamPayload', employeeTeam?: { __typename?: 'EmployeeTeam', id: string } | null } };

export type UpdateEmployeeStatusMutationVariables = Exact<{
  input: UpdateEmployeeStatusInput;
}>;


export type UpdateEmployeeStatusMutation = { __typename?: 'Mutation', updateEmployeeStatus: { __typename?: 'UpdateEmployeeStatusPayload', employee?: { __typename?: 'Employee', id: string, status: EmployeeStatus } | null } };

export type EventsArchiveQueryVariables = Exact<{
  where?: InputMaybe<EventFilterInput>;
  order?: InputMaybe<Array<EventSortInput> | EventSortInput>;
}>;


export type EventsArchiveQuery = { __typename?: 'Query', myEvents: Array<{ __typename?: 'Event', id: string, eventNumber: number, name: string, type: EventType, personioProjectName?: string | null, personioProjectId?: number | null, startDate: any, endDate: any, origin: CoventoOrigin, isInternal: boolean, status: EventStatus, orderType?: EventOrderType | null, publishedForAllEmployees: boolean, organizer?: { __typename?: 'Organizer', id: string, name: string } | null, branch: { __typename?: 'Branch', id: string, name: string }, publishedTeams: Array<{ __typename?: 'EmployeeTeam', id: string }> }> };

export type AssignmentProgressQueryVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type AssignmentProgressQuery = { __typename?: 'Query', assignmentProgress: { __typename?: 'EventAssignmentProgressModel', publishStatus: string, progress: string } };

export type EventCancelDataQueryVariables = Exact<{
  where?: InputMaybe<EventFilterInput>;
}>;


export type EventCancelDataQuery = { __typename?: 'Query', event?: { __typename?: 'Event', id: string, name: string, deployments: Array<{ __typename?: 'Deployment', id: string }>, shifts: Array<{ __typename?: 'Shift', id: string, requiredQuantity: number }>, childEvents: Array<{ __typename?: 'Event', id: string, name: string, origin: CoventoOrigin, status: EventStatus, shifts: Array<{ __typename?: 'Shift', id: string, requiredQuantity: number, deployments: Array<{ __typename?: 'Deployment', id: string }> }>, branch: { __typename?: 'Branch', id: string, name: string }, account: { __typename?: 'Account', id: string, name: string } }> } | null };

export type CancelSettingsInputMutationVariables = Exact<{
  input: SetCancelSettingsInput;
}>;


export type CancelSettingsInputMutation = { __typename?: 'Mutation', setCancelSettings: { __typename?: 'SetCancelSettingsPayload', cancelSettings?: { __typename?: 'CancelSettings', id: string } | null } };

export type CanceledDeploymentsQueryVariables = Exact<{
  where?: InputMaybe<CanceledShiftFilterInput>;
}>;


export type CanceledDeploymentsQuery = { __typename?: 'Query', canceledShifts: Array<{ __typename?: 'CanceledShift', id: string, creationTime: any, isRead: boolean, message?: string | null, employee: { __typename?: 'Employee', id: string, firstName: string, lastName: string }, shift: { __typename?: 'Shift', id: string, skill: EmployeeSkill, startDate: any, endDate: any, currentQuantity: number, requiredQuantity: number, section?: string | null, floorName?: string | null, event?: { __typename?: 'Event', id: string, name: string } | null } }> };

export type CheckInListQueryVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type CheckInListQuery = { __typename?: 'Query', checkInList: { __typename?: 'CheckInListModel', listDate: any, event: { __typename?: 'Event', id: string, eventNumber: number, hasInternalEvents: boolean, name: string, startDate: any, origin: CoventoOrigin, checkInListReleasedAt?: any | null, checkInListReleased: boolean, checkInListConfirmed: boolean, checkInListConfirmedAt?: any | null, checkInListCompleted: boolean, checkInListCompletedAt?: any | null, status: EventStatus, checkInMasters: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string }>, account: { __typename?: 'Account', id: string, name: string } }, deployments: Array<{ __typename?: 'Deployment', id: string, status: DeploymentStatus, employeeStatus: DeploymentEmployeeStatus, breakTime?: number | null, checkInTime?: any | null, checkOutTime?: any | null, oldCheckInTime?: any | null, oldCheckOutTime?: any | null, pendingCheckInTime?: any | null, pendingCheckOutTime?: any | null, personioAttendanceId?: number | null, isReserve: boolean, personioContract?: { __typename?: 'PersonioContract', id: string, signedUrl?: string | null, url: string } | null, checkInTimeSetBy?: { __typename?: 'Account', id: string } | null, shift: { __typename?: 'Shift', id: string, section?: string | null, floorName?: string | null, startDate: any, endDate: any, skill: EmployeeSkill }, providedBy: { __typename?: 'Account', id: string, name: string }, employee: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, dateOfBirth?: any | null, isOfAge: boolean, personioId?: number | null, requireDailyContract: boolean } }> } };

export type CheckInListCommentsQueryVariables = Exact<{
  where: CheckInListCommentFilterInput;
}>;


export type CheckInListCommentsQuery = { __typename?: 'Query', eventsCheckInListComments: Array<{ __typename?: 'CheckInListComment', id: string, creationTime: any, comment: string, section?: string | null, createdBy?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null }> };

export type CompleteCheckInListMutationVariables = Exact<{
  input: CompleteCheckInListInput;
}>;


export type CompleteCheckInListMutation = { __typename?: 'Mutation', completeCheckInList: { __typename?: 'CompleteCheckInListPayload', event?: { __typename?: 'Event', id: string } | null } };

export type CompletePersonioMutationVariables = Exact<{
  input: CompletePersonioEventInput;
}>;


export type CompletePersonioMutation = { __typename?: 'Mutation', completePersonioEvent: { __typename?: 'CompletePersonioEventPayload', updateEventStatusResponseModel?: { __typename?: 'UpdateEventStatusResponseModel', successCount: number, errorsCount: number, event: { __typename?: 'Event', id: string } } | null } };

export type ConfirmCheckInListMutationVariables = Exact<{
  input: ConfirmCheckInListInput;
}>;


export type ConfirmCheckInListMutation = { __typename?: 'Mutation', confirmCheckInList: { __typename?: 'ConfirmCheckInListPayload', event?: { __typename?: 'Event', id: string } | null } };

export type CreateCheckInListCommentMutationVariables = Exact<{
  input: CreateCheckInListCommentInput;
}>;


export type CreateCheckInListCommentMutation = { __typename?: 'Mutation', createCheckInListComment: { __typename?: 'CreateCheckInListCommentPayload', checkInListComment?: { __typename?: 'CheckInListComment', id: string, comment: string, creationTime: any } | null } };

export type CreateCheckInMastersMutationVariables = Exact<{
  input: CreateCheckInMastersInput;
}>;


export type CreateCheckInMastersMutation = { __typename?: 'Mutation', createCheckInMasters: { __typename?: 'CreateCheckInMastersPayload', string?: string | null } };

export type CreateEventMutationVariables = Exact<{
  input: CreateEventInput;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent: { __typename?: 'CreateEventPayload', event?: { __typename?: 'Event', id: string } | null } };

export type CreateEventTemplateMutationVariables = Exact<{
  input: CreateEventTemplateInput;
}>;


export type CreateEventTemplateMutation = { __typename?: 'Mutation', createEventTemplate: { __typename?: 'CreateEventTemplatePayload', eventTemplate?: { __typename?: 'EventTemplate', id: string } | null } };

export type CreateLocationMutationVariables = Exact<{
  input: CreateEventLocationInput;
}>;


export type CreateLocationMutation = { __typename?: 'Mutation', createEventLocation: { __typename?: 'CreateEventLocationPayload', eventLocation?: { __typename?: 'EventLocation', id: string } | null } };

export type DeleteEventDocumentMutationVariables = Exact<{
  input: DeleteEventDocumentInput;
}>;


export type DeleteEventDocumentMutation = { __typename?: 'Mutation', deleteEventDocument: { __typename?: 'DeleteEventDocumentPayload', boolean?: boolean | null } };

export type DeleteEventTemplateMutationVariables = Exact<{
  input: DeleteEventTemplateInput;
}>;


export type DeleteEventTemplateMutation = { __typename?: 'Mutation', deleteEventTemplate: { __typename?: 'DeleteEventTemplatePayload', boolean?: boolean | null } };

export type EditEventMutationVariables = Exact<{
  input: UpdateEventInput;
}>;


export type EditEventMutation = { __typename?: 'Mutation', updateEvent: { __typename?: 'UpdateEventPayload', event?: { __typename?: 'Event', id: string } | null } };

export type EventQueryVariables = Exact<{
  where?: InputMaybe<EventFilterInput>;
}>;


export type EventQuery = { __typename?: 'Query', event?: { __typename?: 'Event', id: string, eventNumber: number, name: string, startDate: any, endDate: any, status: EventStatus, type: EventType, addressNotes?: string | null, comment?: string | null, description?: string | null, numberOfGuests: number, branchId: string, origin: CoventoOrigin, personioProjectId?: number | null, personioProjectName?: string | null, orderType?: EventOrderType | null, isInternal: boolean, publishedForAllEmployees: boolean, message?: string | null, deployments: Array<{ __typename?: 'Deployment', id: string, status: DeploymentStatus }>, shifts: Array<{ __typename?: 'Shift', id: string, shiftNumber: number, skill: EmployeeSkill, startDate: any, endDate: any, section?: string | null, requiredQuantity: number, currentQuantity: number, floorName?: string | null }>, address: { __typename?: 'Address', id: string, formattedAddress?: string | null, longitude?: number | null, latitude?: number | null }, location?: { __typename?: 'EventLocation', id: string, floors: Array<string> } | null, orders: Array<{ __typename?: 'Order', id: string, creationTime: any, tax: number, net: number, total: number, status: OrderStatus, downloadUrl: string }>, childEvents: Array<{ __typename?: 'Event', id: string, origin: CoventoOrigin, checkInListReleased: boolean, checkInListConfirmed: boolean, checkInListCompleted: boolean, status: EventStatus, orders: Array<{ __typename?: 'Order', id: string, downloadUrl: string, creationTime: any, status: OrderStatus, total: number }>, account: { __typename?: 'Account', id: string, name: string } }>, publishedTeams: Array<{ __typename?: 'EmployeeTeam', id: string, name: string }> } | null };

export type EventCompleteDataQueryVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type EventCompleteDataQuery = { __typename?: 'Query', completeEventData: { __typename?: 'EventCompleteDataModel', totalDeployments: number, totalRequiredDeployments: number, confirmedDeployments: number, insertedDeployments: number } };

export type EventDashboardQueryVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type EventDashboardQuery = { __typename?: 'Query', eventDashboardStats: Array<{ __typename?: 'EventDashboardStatsModel', account?: string | null, plannedCoasts: number, plannedTotalCoasts: number, coasts?: number | null, totalCoasts?: number | null, plannedHours: number, plannedTotalHours: number, hours?: number | null, totalHours?: number | null, plannedEmployees: number, plannedTotalEmployees: number, employees?: number | null, totalEmployees?: number | null, shifts: Array<{ __typename?: 'EventDashboardStatsModel', skill?: EmployeeSkill | null, coasts?: number | null, employees?: number | null, hours?: number | null, plannedHours: number, plannedEmployees: number, plannedCoasts: number }> }> };

export type EventDetailsQueryVariables = Exact<{
  where?: InputMaybe<EventFilterInput>;
}>;


export type EventDetailsQuery = { __typename?: 'Query', event?: { __typename?: 'Event', id: string, eventNumber: number, name: string, startDate: any, endDate: any, status: EventStatus, type: EventType, addressNotes?: string | null, comment?: string | null, description?: string | null, numberOfGuests: number, branchId: string, origin: CoventoOrigin, personioProjectId?: number | null, personioProjectName?: string | null, isInternal: boolean, orderType?: EventOrderType | null, publishedForAllEmployees: boolean, message?: string | null, address: { __typename?: 'Address', id: string, formattedAddress?: string | null, longitude?: number | null, latitude?: number | null }, childEvents: Array<{ __typename?: 'Event', id: string }>, publishedTeams: Array<{ __typename?: 'EmployeeTeam', id: string, name: string }>, location?: { __typename?: 'EventLocation', id: string, floors: Array<string> } | null, shifts: Array<{ __typename?: 'Shift', id: string, shiftNumber: number, skill: EmployeeSkill, startDate: any, endDate: any, section?: string | null, floorName?: string | null, requiredQuantity: number, currentQuantity: number }>, eventPublishCounters: Array<{ __typename?: 'EventPublishCounter', id: string, counter: number, team?: { __typename?: 'EmployeeTeam', id: string, name: string } | null }> } | null };

export type EventDocumentsQueryVariables = Exact<{
  where?: InputMaybe<EventDocumentFilterInput>;
}>;


export type EventDocumentsQuery = { __typename?: 'Query', eventDocuments: Array<{ __typename?: 'EventDocument', id: string, name: string, url: string, visibleTo: Array<{ __typename?: 'EmployeeSkillModel', id: string, skill: EmployeeSkill }> }> };

export type EventEditQueryVariables = Exact<{
  where?: InputMaybe<EventFilterInput>;
}>;


export type EventEditQuery = { __typename?: 'Query', event?: { __typename?: 'Event', id: string, eventNumber: number, name: string, startDate: any, endDate: any, status: EventStatus, type: EventType, addressNotes?: string | null, comment?: string | null, description?: string | null, numberOfGuests: number, branchId: string, origin: CoventoOrigin, personioProjectId?: number | null, personioProjectName?: string | null, orderType?: EventOrderType | null, address: { __typename?: 'Address', id: string, formattedAddress?: string | null, longitude?: number | null, latitude?: number | null } } | null };

export type EventHelpRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type EventHelpRequestsQuery = { __typename?: 'Query', eventHelpRequests: Array<{ __typename?: 'EventHelpRequest', id: string, quantity: number, isCancel: boolean, branch: { __typename?: 'Branch', id: string, name: string }, event: { __typename?: 'Event', id: string, name: string, branch: { __typename?: 'Branch', id: string, name: string } }, shift: { __typename?: 'Shift', id: string, section?: string | null, skill: EmployeeSkill, startDate: any, endDate: any, requiredQuantity: number } }> };

export type EventRequestsQueryVariables = Exact<{
  viewMode: Scalars['String'];
}>;


export type EventRequestsQuery = { __typename?: 'Query', eventRequests: Array<{ __typename?: 'EmployeeEventRequestGroupedModel', id: any, name: string, startDate?: any | null, endDate?: any | null, count: number, eventRequests: Array<{ __typename?: 'EventRequest', id: string, event: { __typename?: 'Event', id: string, name: string }, employee: { __typename?: 'Employee', id: string, firstName: string, lastName: string }, shift: { __typename?: 'Shift', id: string, skill: EmployeeSkill, section?: string | null, floorName?: string | null, startDate: any, endDate: any, currentQuantity: number, requiredQuantity: number } }> }> };

export type EventTemplateQueryVariables = Exact<{
  where: EventTemplateFilterInput;
}>;


export type EventTemplateQuery = { __typename?: 'Query', eventTemplate?: { __typename?: 'EventTemplate', id: string, templateName: string, name: string, type: EventType, comment?: string | null, description?: string | null, personioProjectName?: string | null, personioProjectId?: number | null, addressNotes?: string | null, numberOfGuests: number, branch: { __typename?: 'Branch', id: string, name: string }, location?: { __typename?: 'EventLocation', id: string, name: string, floors: Array<string>, address: { __typename?: 'Address', id: string, formattedAddress?: string | null, longitude?: number | null, latitude?: number | null } } | null, address: { __typename?: 'Address', id: string, formattedAddress?: string | null, longitude?: number | null, latitude?: number | null }, shifts: Array<{ __typename?: 'Shift', id: string, section?: string | null, startDate: any, endDate: any, skill: EmployeeSkill, requiredQuantity: number, floorName?: string | null, preFilledEmployees: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string }> }> } | null };

export type EventTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type EventTemplatesQuery = { __typename?: 'Query', eventTemplates: Array<{ __typename?: 'EventTemplate', id: string, name: string, templateName: string, personioProjectName?: string | null, branchId: string, location?: { __typename?: 'EventLocation', id: string, name: string, floors: Array<string>, address: { __typename?: 'Address', id: string, formattedAddress?: string | null, longitude?: number | null, latitude?: number | null } } | null, branch: { __typename?: 'Branch', id: string, name: string } }> };

export type EventsQueryVariables = Exact<{
  where?: InputMaybe<EventFilterInput>;
  order?: InputMaybe<Array<EventSortInput> | EventSortInput>;
}>;


export type EventsQuery = { __typename?: 'Query', myEvents: Array<{ __typename?: 'Event', id: string, eventNumber: number, name: string, type: EventType, personioProjectName?: string | null, personioProjectId?: number | null, startDate: any, endDate: any, origin: CoventoOrigin, isInternal: boolean, status: EventStatus, orderType?: EventOrderType | null, publishedForAllEmployees: boolean, organizer?: { __typename?: 'Organizer', id: string, name: string } | null, branch: { __typename?: 'Branch', id: string, name: string }, publishedTeams: Array<{ __typename?: 'EmployeeTeam', id: string }>, deployments: Array<{ __typename?: 'Deployment', id: string, employeeStatus: DeploymentEmployeeStatus }> }> };

export type GenerateCheckInListMutationVariables = Exact<{
  input: GenerateCheckInListInput;
}>;


export type GenerateCheckInListMutation = { __typename?: 'Mutation', generateCheckInList: { __typename?: 'GenerateCheckInListPayload', string?: string | null } };

export type GeneratePersonioContractsMutationVariables = Exact<{
  input: CreateUnsignedDocumentsInput;
}>;


export type GeneratePersonioContractsMutation = { __typename?: 'Mutation', createUnsignedDocuments: { __typename?: 'CreateUnsignedDocumentsPayload', boolean?: boolean | null } };

export type LocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type LocationsQuery = { __typename?: 'Query', eventLocations: Array<{ __typename?: 'EventLocation', id: string, name: string, floors: Array<string>, address: { __typename?: 'Address', formattedAddress?: string | null, longitude?: number | null, latitude?: number | null } }> };

export type MarkCanceledShiftAsReadMutationVariables = Exact<{
  input: MarkCanceledShiftAsReadInput;
}>;


export type MarkCanceledShiftAsReadMutation = { __typename?: 'Mutation', markCanceledShiftAsRead: { __typename?: 'MarkCanceledShiftAsReadPayload', boolean?: boolean | null } };

export type PersonioProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type PersonioProjectsQuery = { __typename?: 'Query', personioProjects: { __typename?: 'PersonioProjectsRoot', data: Array<{ __typename?: 'PersonioProjectsData', id: number, attributes: { __typename?: 'PersonioProjectsAttributes', name: string } }> } };

export type PublishEventMutationVariables = Exact<{
  input: PublishEventInput;
}>;


export type PublishEventMutation = { __typename?: 'Mutation', publishEvent: { __typename?: 'PublishEventPayload', event?: { __typename?: 'Event', id: string, isInternal: boolean } | null } };

export type PublishTeamsMutationVariables = Exact<{
  input: PublishEventTeamsInput;
}>;


export type PublishTeamsMutation = { __typename?: 'Mutation', publishEventTeams: { __typename?: 'PublishEventTeamsPayload', event?: { __typename?: 'Event', id: string, publishedForAllEmployees: boolean, publishedTeams: Array<{ __typename?: 'EmployeeTeam', id: string, name: string }>, eventPublishCounters: Array<{ __typename?: 'EventPublishCounter', id: string, counter: number, team?: { __typename?: 'EmployeeTeam', id: string, name: string } | null }> } | null } };

export type ReleaseCheckInListMutationVariables = Exact<{
  input: ReleaseCheckInListInput;
}>;


export type ReleaseCheckInListMutation = { __typename?: 'Mutation', releaseCheckInList: { __typename?: 'ReleaseCheckInListPayload', event?: { __typename?: 'Event', id: string } | null } };

export type SendEventPushNotificationsMutationVariables = Exact<{
  input: SendEventPushNotificationsInput;
}>;


export type SendEventPushNotificationsMutation = { __typename?: 'Mutation', sendEventPushNotifications: { __typename?: 'SendEventPushNotificationsPayload', int?: number | null } };

export type SetEventInfoMessageMutationVariables = Exact<{
  input: SetEventInfoMessageInput;
}>;


export type SetEventInfoMessageMutation = { __typename?: 'Mutation', setEventInfoMessage: { __typename?: 'SetEventInfoMessagePayload', event?: { __typename?: 'Event', id: string, message?: string | null } | null } };

export type TransmitTimesMutationVariables = Exact<{
  input: TransmitCheckInListInput;
}>;


export type TransmitTimesMutation = { __typename?: 'Mutation', transmitCheckInList: { __typename?: 'TransmitCheckInListPayload', event?: { __typename?: 'Event', id: string } | null } };

export type UpdateEventDocumentMutationVariables = Exact<{
  input: UpdateEventDocumentInput;
}>;


export type UpdateEventDocumentMutation = { __typename?: 'Mutation', updateEventDocument: { __typename?: 'UpdateEventDocumentPayload', eventDocument?: { __typename?: 'EventDocument', id: string } | null } };

export type UpdateEventHelpRequestMutationVariables = Exact<{
  input: UpdateEventHelpRequestInput;
}>;


export type UpdateEventHelpRequestMutation = { __typename?: 'Mutation', updateEventHelpRequest: { __typename?: 'UpdateEventHelpRequestPayload', eventHelpRequest?: { __typename?: 'EventHelpRequest', id: string } | null } };

export type UpdateLocationMutationVariables = Exact<{
  input: UpdateEventLocationInput;
}>;


export type UpdateLocationMutation = { __typename?: 'Mutation', updateEventLocation: { __typename?: 'UpdateEventLocationPayload', eventLocation?: { __typename?: 'EventLocation', id: string } | null } };

export type UpdateEventOrderTypeMutationVariables = Exact<{
  input: UpdateEventOrderTypeInput;
}>;


export type UpdateEventOrderTypeMutation = { __typename?: 'Mutation', updateEventOrderType: { __typename?: 'UpdateEventOrderTypePayload', event?: { __typename?: 'Event', id: string } | null } };

export type UpdateEventRequestMutationVariables = Exact<{
  input: UpdateEventRequestInput;
}>;


export type UpdateEventRequestMutation = { __typename?: 'Mutation', updateEventRequest: { __typename?: 'UpdateEventRequestPayload', eventRequest?: { __typename?: 'EventRequest', id: string } | null } };

export type UpdateEventStatusMutationVariables = Exact<{
  input: UpdateEventStatusInput;
}>;


export type UpdateEventStatusMutation = { __typename?: 'Mutation', updateEventStatus: { __typename?: 'UpdateEventStatusPayload', updateEventStatusResponseModel?: { __typename?: 'UpdateEventStatusResponseModel', successCount: number, errorsCount: number, event: { __typename?: 'Event', id: string } } | null } };

export type UpdateEventTemplateMutationVariables = Exact<{
  input: UpdateEventTemplateInput;
}>;


export type UpdateEventTemplateMutation = { __typename?: 'Mutation', updateEventTemplate: { __typename?: 'UpdateEventTemplatePayload', eventTemplate?: { __typename?: 'EventTemplate', id: string } | null } };

export type NavsQueryVariables = Exact<{ [key: string]: never; }>;


export type NavsQuery = { __typename?: 'Query', navigations: Array<{ __typename?: 'NavigationModel', id: string, icon?: string | null, badge?: string | null, isTitle?: boolean | null, label: string, link?: string | null }> };

export type MarkNotificationsAsReadMutationVariables = Exact<{
  input: MarkNotificationsAsReadInput;
}>;


export type MarkNotificationsAsReadMutation = { __typename?: 'Mutation', markNotificationsAsRead: { __typename?: 'MarkNotificationsAsReadPayload', string?: string | null } };

export type NotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename?: 'Notification', id: string, text: string, title: string, icon: string, color: string, link?: string | null, creationTime: any }> };

export type OrdersQueryVariables = Exact<{
  where?: InputMaybe<OrderFilterInput>;
}>;


export type OrdersQuery = { __typename?: 'Query', orders: Array<{ __typename?: 'Order', id: string, status: OrderStatus, tax: number, total: number, net: number, refNumber: number, creationTime: any, downloadUrl: string, quote: { __typename?: 'Quotation', id: string, downloadUrl: string }, serviceProvider: { __typename?: 'ServiceProvider', id: string, name: string } }> };

export type CreateAttendanceMutationVariables = Exact<{
  input: SetPersonioTimesInput;
}>;


export type CreateAttendanceMutation = { __typename?: 'Mutation', setPersonioTimes: { __typename?: 'SetPersonioTimesPayload', personioResponse?: { __typename?: 'PersonioResponse', success: boolean, data?: { __typename?: 'PersonioData', id: Array<number> } | null } | null } };

export type CreateQuoteRequestsMutationVariables = Exact<{
  input: CreateQuoteRequestsInput;
}>;


export type CreateQuoteRequestsMutation = { __typename?: 'Mutation', createQuoteRequests: { __typename?: 'CreateQuoteRequestsPayload', quotation?: Array<{ __typename?: 'Quotation', id: string }> | null } };

export type QuotationsQueryVariables = Exact<{
  where?: InputMaybe<QuotationFilterInput>;
}>;


export type QuotationsQuery = { __typename?: 'Query', quotes: Array<{ __typename?: 'Quotation', id: string, refNumber: number, version: number, status: QuoteStatus, serviceProvider: { __typename?: 'ServiceProvider', id: string, name: string }, items: Array<{ __typename?: 'QuoteItem', id: string, skill: EmployeeSkill, price: number, total: number, quantity: number, shift: { __typename?: 'Shift', id: string, shiftNumber: number, startDate: any, endDate: any, skill: EmployeeSkill, floorName?: string | null, section?: string | null, hours: number, requiredQuantity: number, currentQuantity: number } }> }> };

export type AvailableShiftsForAssignmentQueryVariables = Exact<{
  eventId: Scalars['ID'];
  employeeId: Scalars['ID'];
}>;


export type AvailableShiftsForAssignmentQuery = { __typename?: 'Query', availableShiftsForAssignment: Array<{ __typename?: 'Shift', id: string, shiftNumber: number, currentQuantity: number, requiredQuantity: number, skill: EmployeeSkill, section?: string | null, floorName?: string | null, startDate: any, endDate: any, deployments: Array<{ __typename?: 'Deployment', id: string, status: DeploymentStatus, employeeStatus: DeploymentEmployeeStatus }> }> };

export type ChildShiftsQueryVariables = Exact<{
  shiftId: Scalars['ID'];
}>;


export type ChildShiftsQuery = { __typename?: 'Query', childShifts: Array<{ __typename?: 'ChildShiftListModel', shiftId: string, account: string, branch: string, quantity: number, type: ShiftDispatchType }> };

export type CreateEventHelpRequestMutationVariables = Exact<{
  inputs: Array<CreateEventHelpRequestInput> | CreateEventHelpRequestInput;
  eventId: Scalars['ID'];
}>;


export type CreateEventHelpRequestMutation = { __typename?: 'Mutation', createEventHelpRequest: { __typename?: 'CreateEventHelpRequestPayload', boolean?: boolean | null } };

export type CreateShiftsMutationVariables = Exact<{
  input: CreateShiftsInput;
}>;


export type CreateShiftsMutation = { __typename?: 'Mutation', createShifts: { __typename?: 'CreateShiftsPayload', boolean?: boolean | null } };

export type ShiftCurrentQuantityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ShiftCurrentQuantityQuery = { __typename?: 'Query', shiftStatusQuery: { __typename?: 'Shift', currentQuantity: number, publishStatus: string, deployments: Array<{ __typename?: 'Deployment', id: string, status: DeploymentStatus }> } };

export type DeleteShiftMutationVariables = Exact<{
  input: DeleteShiftInput;
}>;


export type DeleteShiftMutation = { __typename?: 'Mutation', deleteShift: { __typename?: 'DeleteShiftPayload', boolean?: boolean | null } };

export type DeploymentShiftsQueryVariables = Exact<{
  shiftId: Scalars['ID'];
}>;


export type DeploymentShiftsQuery = { __typename?: 'Query', deploymentShifts: { __typename?: 'DeploymentsShiftModel', shifts: Array<{ __typename?: 'Shift', id: string, requiredQuantity: number, account?: { __typename?: 'Account', id: string, name: string } | null, deployments: Array<{ __typename?: 'Deployment', id: string, employee: { __typename?: 'Employee', id: string, firstName: string, lastName: string } }>, branch?: { __typename?: 'Branch', id: string, name: string } | null }>, deployments: Array<{ __typename?: 'Deployment', id: string, status: DeploymentStatus, employeeStatus: DeploymentEmployeeStatus, isReserve: boolean, shift: { __typename?: 'Shift', id: string }, employee: { __typename?: 'Employee', id: string, firstName: string, lastName: string } }> } };

export type ShiftQueryVariables = Exact<{
  where?: InputMaybe<ShiftFilterInput>;
  quoteItemWhere?: InputMaybe<QuoteItemFilterInput>;
}>;


export type ShiftQuery = { __typename?: 'Query', shift?: { __typename?: 'Shift', id: string, currentQuantity: number, quoteItems: Array<{ __typename?: 'QuoteItem', id: string, quantity: number, quotation: { __typename?: 'Quotation', id: string, serviceProvider: { __typename?: 'ServiceProvider', id: string, name: string } } }>, childShifts: Array<{ __typename?: 'Shift', id: string, requiredQuantity: number, branch?: { __typename?: 'Branch', id: string, name: string } | null, account?: { __typename?: 'Account', id: string, name: string } | null }>, deployments: Array<{ __typename?: 'Deployment', id: string, employee: { __typename?: 'Employee', id: string, firstName: string, lastName: string } }> } | null };

export type ShiftByFloorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ShiftByFloorQuery = { __typename?: 'Query', shiftGroupedByFloors: Array<{ __typename?: 'ShiftGroupByFloorModel', floor: string, shifts: Array<{ __typename?: 'Shift', id: string, shiftNumber: number, startDate: any, endDate: any, section?: string | null, skill: EmployeeSkill, requiredQuantity: number, currentQuantity: number, floorName?: string | null, eventId?: string | null, event?: { __typename?: 'Event', id: string, origin: CoventoOrigin } | null, childShifts: Array<{ __typename?: 'Shift', id: string, account?: { __typename?: 'Account', id: string, name: string } | null, branch?: { __typename?: 'Branch', id: string, name: string } | null }> }> }> };

export type ShiftsQueryVariables = Exact<{
  where?: InputMaybe<ShiftFilterInput>;
}>;


export type ShiftsQuery = { __typename?: 'Query', shifts: Array<{ __typename?: 'Shift', id: string, shiftNumber: number, currentQuantity: number, requiredQuantity: number, skill: EmployeeSkill, section?: string | null, floorName?: string | null, startDate: any, endDate: any, deployments: Array<{ __typename?: 'Deployment', id: string, status: DeploymentStatus, employeeStatus: DeploymentEmployeeStatus }> }> };

export type UpdateShiftMutationVariables = Exact<{
  input: UpdateShiftInput;
}>;


export type UpdateShiftMutation = { __typename?: 'Mutation', updateShift: { __typename?: 'UpdateShiftPayload', shift?: { __typename?: 'Shift', id: string } | null } };

export const AccountSkillsDocument = gql`
    query accountSkills {
  accountSkills {
    id
    skill
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountSkillsGQL extends Apollo.Query<AccountSkillsQuery, AccountSkillsQueryVariables> {
    override document = AccountSkillsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CancelSettingsDocument = gql`
    query cancelSettings {
  cancelSettings {
    fromFriday
    fromMonday
    fromSaturday
    fromSunday
    fromThursday
    fromTuesday
    fromWednesday
    toFriday
    toMonday
    toSaturday
    toSunday
    toThursday
    toTuesday
    toWednesday
    phone
    hoursBeforeEvent
    email
    branch {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelSettingsGQL extends Apollo.Query<CancelSettingsQuery, CancelSettingsQueryVariables> {
    override document = CancelSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation login($input: UserLoginInput!) {
  userLogin(input: $input) {
    string
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    override document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MeDocument = gql`
    query me {
  me {
    account {
      id
      name
      discriminator
      ventoWorkAccess
      personioUser
      permissions {
        id
        scope
      }
    }
    id
    firstName
    lastName
    email
    role {
      id
      permissions {
        id
        target
        scope
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MeGQL extends Apollo.Query<MeQuery, MeQueryVariables> {
    override document = MeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RequestPasswordResetDocument = gql`
    mutation requestPasswordReset($input: RequestPasswordResetInput!) {
  requestPasswordReset(input: $input) {
    string
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RequestPasswordResetGQL extends Apollo.Mutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables> {
    override document = RequestPasswordResetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetEmployeePasswordDocument = gql`
    mutation setEmployeePassword($input: SetEmployeePasswordInput!) {
  setEmployeePassword(input: $input) {
    employee {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetEmployeePasswordGQL extends Apollo.Mutation<SetEmployeePasswordMutation, SetEmployeePasswordMutationVariables> {
    override document = SetEmployeePasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserPasswordDocument = gql`
    mutation updateUserPassword($input: UpdateUserPasswordInput!) {
  updateUserPassword(input: $input) {
    user {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserPasswordGQL extends Apollo.Mutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables> {
    override document = UpdateUserPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BranchDocument = gql`
    query branch($where: BranchFilterInput) {
  branch(where: $where) {
    id
    name
    email
    phone
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BranchGQL extends Apollo.Query<BranchQuery, BranchQueryVariables> {
    override document = BranchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BranchesDocument = gql`
    query branches {
  myBranches {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BranchesGQL extends Apollo.Query<BranchesQuery, BranchesQueryVariables> {
    override document = BranchesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CustomerRequestsDocument = gql`
    query customerRequests {
  customerRequests {
    id
    customerPrices {
      id
      skill
      price
    }
    creationTime
    customerBranch {
      id
      name
    }
    account {
      id
      name
    }
    organizer {
      id
      name
    }
    serviceProvider {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CustomerRequestsGQL extends Apollo.Query<CustomerRequestsQuery, CustomerRequestsQueryVariables> {
    override document = CustomerRequestsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CustomersDocument = gql`
    query customers($where: CoventoCustomerFilterInput) {
  customers(where: $where) {
    id
    name
    email
    serviceProvider {
      id
      name
    }
    customerPrices {
      id
      skill
      price
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CustomersGQL extends Apollo.Query<CustomersQuery, CustomersQueryVariables> {
    override document = CustomersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCustomerPricesDocument = gql`
    mutation updateCustomerPrices($input: UpdateCustomerPricesInput!) {
  updateCustomerPrices(input: $input) {
    customerRequest {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCustomerPricesGQL extends Apollo.Mutation<UpdateCustomerPricesMutation, UpdateCustomerPricesMutationVariables> {
    override document = UpdateCustomerPricesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCustomerRequestStatusDocument = gql`
    mutation updateCustomerRequestStatus($input: UpdateCustomerRequestStatusInput!) {
  updateCustomerRequestStatus(input: $input) {
    customerRequest {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCustomerRequestStatusGQL extends Apollo.Mutation<UpdateCustomerRequestStatusMutation, UpdateCustomerRequestStatusMutationVariables> {
    override document = UpdateCustomerRequestStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateDeploymentDocument = gql`
    mutation createDeployment($input: CreateDeploymentInput!) {
  createDeployment(input: $input) {
    deployment {
      id
      shift {
        id
        currentQuantity
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDeploymentGQL extends Apollo.Mutation<CreateDeploymentMutation, CreateDeploymentMutationVariables> {
    override document = CreateDeploymentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDeploymentDocument = gql`
    mutation deleteDeployment($input: DeleteDeploymentInput!) {
  deleteDeployment(input: $input) {
    deployment {
      shift {
        id
        currentQuantity
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDeploymentGQL extends Apollo.Mutation<DeleteDeploymentMutation, DeleteDeploymentMutationVariables> {
    override document = DeleteDeploymentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeploymentsDocument = gql`
    query deployments($where: DeploymentFilterInput, $order: [DeploymentSortInput!]) {
  deployments(where: $where, order: $order) {
    id
    shift {
      id
      startDate
      endDate
      skill
      floorName
      section
      event {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeploymentsGQL extends Apollo.Query<DeploymentsQuery, DeploymentsQueryVariables> {
    override document = DeploymentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeploymentsByMonthDocument = gql`
    query deploymentsByMonth($employeeID: ID!, $year: DateTime!) {
  deploymentsByMonth(employeeId: $employeeID, year: $year) {
    totalHours
    totalDeployments
    month
    year
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeploymentsByMonthGQL extends Apollo.Query<DeploymentsByMonthQuery, DeploymentsByMonthQueryVariables> {
    override document = DeploymentsByMonthDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IsAvailableForAssignmentDocument = gql`
    query isAvailableForAssignment($employeeId: ID!, $shiftId: ID!) {
  isAvailableForAssignment(employeeId: $employeeId, shiftId: $shiftId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IsAvailableForAssignmentGQL extends Apollo.Query<IsAvailableForAssignmentQuery, IsAvailableForAssignmentQueryVariables> {
    override document = IsAvailableForAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MarkDeploymentAsReserveDocument = gql`
    mutation markDeploymentAsReserve($input: MarkAsReserveInput!) {
  markAsReserve(input: $input) {
    deployment {
      id
      isReserve
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkDeploymentAsReserveGQL extends Apollo.Mutation<MarkDeploymentAsReserveMutation, MarkDeploymentAsReserveMutationVariables> {
    override document = MarkDeploymentAsReserveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PublishDeploymentDocument = gql`
    mutation publishDeployment($input: PublishDeploymentsInput!) {
  publishDeployments(input: $input) {
    deployment {
      id
      employeeStatus
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PublishDeploymentGQL extends Apollo.Mutation<PublishDeploymentMutation, PublishDeploymentMutationVariables> {
    override document = PublishDeploymentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetDeploymentTimesDocument = gql`
    mutation setDeploymentTimes($input: SetDeploymentTimesInput!) {
  setDeploymentTimes(input: $input) {
    deployment {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetDeploymentTimesGQL extends Apollo.Mutation<SetDeploymentTimesMutation, SetDeploymentTimesMutationVariables> {
    override document = SetDeploymentTimesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDeploymentDocument = gql`
    mutation updateDeployment($input: UpdateDeploymentInput!) {
  updateDeployment(input: $input) {
    deployment {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDeploymentGQL extends Apollo.Mutation<UpdateDeploymentMutation, UpdateDeploymentMutationVariables> {
    override document = UpdateDeploymentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateDocumentTypeDocument = gql`
    mutation createDocumentType($input: CreateDocumentTypeInput!) {
  createDocumentType(input: $input) {
    documentType {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDocumentTypeGQL extends Apollo.Mutation<CreateDocumentTypeMutation, CreateDocumentTypeMutationVariables> {
    override document = CreateDocumentTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateEmployeeDocument = gql`
    mutation createEmployee($input: CreateEmployeeInput!) {
  createEmployee(input: $input) {
    employee {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateEmployeeGQL extends Apollo.Mutation<CreateEmployeeMutation, CreateEmployeeMutationVariables> {
    override document = CreateEmployeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateEmployeeTeamDocument = gql`
    mutation createEmployeeTeam($input: CreateEmployeeTeamInput!) {
  createEmployeeTeam(input: $input) {
    employeeTeam {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateEmployeeTeamGQL extends Apollo.Mutation<CreateEmployeeTeamMutation, CreateEmployeeTeamMutationVariables> {
    override document = CreateEmployeeTeamDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDocumentTypeDocument = gql`
    mutation deleteDocumentType($input: DeleteDocumentTypeInput!) {
  deleteDocumentType(input: $input) {
    boolean
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDocumentTypeGQL extends Apollo.Mutation<DeleteDocumentTypeMutation, DeleteDocumentTypeMutationVariables> {
    override document = DeleteDocumentTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteEmployeeTeamDocument = gql`
    mutation deleteEmployeeTeam($input: DeleteEmployeeTeamInput!) {
  deleteEmployeeTeam(input: $input) {
    boolean
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEmployeeTeamGQL extends Apollo.Mutation<DeleteEmployeeTeamMutation, DeleteEmployeeTeamMutationVariables> {
    override document = DeleteEmployeeTeamDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EmployeeDocumentTypesDocument = gql`
    query employeeDocumentTypes($where: DocumentTypeFilterInput) {
  documentTypes(where: $where) {
    id
    name
    hasExpiration
    employmentRelations {
      id
      employmentRelationship
    }
    employeeDocuments {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EmployeeDocumentTypesGQL extends Apollo.Query<EmployeeDocumentTypesQuery, EmployeeDocumentTypesQueryVariables> {
    override document = EmployeeDocumentTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EmployeeDocument = gql`
    query employee($where: EmployeeFilterInput!) {
  employee(where: $where) {
    id
    firstName
    lastName
    email
    parentEmail
    gender
    socialSecurityNumber
    phone
    taxId
    dateOfBirth
    salary
    skills {
      id
      skill
    }
    teams {
      id
      name
    }
    branch {
      id
      name
    }
    employmentRelationship
    address {
      id
      street
      streetNumber
      city
      zip
      formattedAddress
      latitude
      longitude
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EmployeeGQL extends Apollo.Query<EmployeeQuery, EmployeeQueryVariables> {
    override document = EmployeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EmployeeDocumentsDocument = gql`
    query employeeDocuments($id: ID!) {
  employeeDocuments(employeeId: $id) {
    type {
      id
      name
      hasExpiration
    }
    document {
      id
      validFrom
      validTo
      url
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EmployeeDocumentsGQL extends Apollo.Query<EmployeeDocumentsQuery, EmployeeDocumentsQueryVariables> {
    override document = EmployeeDocumentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EmployeeTeamsDocument = gql`
    query employeeTeams {
  employeeTeams {
    id
    name
    branch {
      id
      name
    }
    employees {
      id
      firstName
      lastName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EmployeeTeamsGQL extends Apollo.Query<EmployeeTeamsQuery, EmployeeTeamsQueryVariables> {
    override document = EmployeeTeamsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EmployeesDocument = gql`
    query employees($where: EmployeeFilterInput) {
  employees(where: $where) {
    id
    firstName
    lastName
    email
    gender
    staffNumber
    employmentRelationship
    dateOfBirth
    status
    pictureUrl
    isOfAge
    requireDailyContract
    salary
    branch {
      id
      name
    }
    skills {
      id
      skill
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EmployeesGQL extends Apollo.Query<EmployeesQuery, EmployeesQueryVariables> {
    override document = EmployeesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImportEmployeesDocument = gql`
    mutation importEmployees($input: ImportEmployeeInput!) {
  importEmployee(input: $input) {
    employee {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ImportEmployeesGQL extends Apollo.Mutation<ImportEmployeesMutation, ImportEmployeesMutationVariables> {
    override document = ImportEmployeesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InviteEmployeeDocument = gql`
    mutation inviteEmployee($input: InviteEmployeeInput!) {
  inviteEmployee(input: $input) {
    employee {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InviteEmployeeGQL extends Apollo.Mutation<InviteEmployeeMutation, InviteEmployeeMutationVariables> {
    override document = InviteEmployeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PersonioDocument = gql`
    mutation personio {
  personioEmployees {
    employee {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PersonioGQL extends Apollo.Mutation<PersonioMutation, PersonioMutationVariables> {
    override document = PersonioDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PersonioDocumentsDocument = gql`
    query personioDocuments($where: PersonioContractFilterInput) {
  personioContracts(where: $where) {
    id
    deployment {
      id
      shift {
        id
        startDate
      }
      event {
        id
        name
      }
    }
    signedUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PersonioDocumentsGQL extends Apollo.Query<PersonioDocumentsQuery, PersonioDocumentsQueryVariables> {
    override document = PersonioDocumentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDocumentTypeDocument = gql`
    mutation updateDocumentType($input: UpdateDocumentTypeInput!) {
  updateDocumentType(input: $input) {
    documentType {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDocumentTypeGQL extends Apollo.Mutation<UpdateDocumentTypeMutation, UpdateDocumentTypeMutationVariables> {
    override document = UpdateDocumentTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEmployeeDocument = gql`
    mutation updateEmployee($input: UpdateEmployeeInput!) {
  updateEmployee(input: $input) {
    employee {
      id
      firstName
      lastName
      email
      gender
      dateOfBirth
      branch {
        id
        name
      }
      employmentRelationship
      skills {
        id
        skill
      }
      status
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEmployeeGQL extends Apollo.Mutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables> {
    override document = UpdateEmployeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEmployeeTeamDocument = gql`
    mutation updateEmployeeTeam($input: UpdateEmployeeTeamInput!) {
  updateEmployeeTeam(input: $input) {
    employeeTeam {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEmployeeTeamGQL extends Apollo.Mutation<UpdateEmployeeTeamMutation, UpdateEmployeeTeamMutationVariables> {
    override document = UpdateEmployeeTeamDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEmployeeStatusDocument = gql`
    mutation updateEmployeeStatus($input: UpdateEmployeeStatusInput!) {
  updateEmployeeStatus(input: $input) {
    employee {
      id
      status
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEmployeeStatusGQL extends Apollo.Mutation<UpdateEmployeeStatusMutation, UpdateEmployeeStatusMutationVariables> {
    override document = UpdateEmployeeStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventsArchiveDocument = gql`
    query eventsArchive($where: EventFilterInput, $order: [EventSortInput!]) {
  myEvents(where: $where, order: $order) {
    id
    eventNumber
    name
    type
    personioProjectName
    personioProjectId
    organizer {
      id
      name
    }
    startDate
    endDate
    branch {
      id
      name
    }
    origin
    isInternal
    status
    orderType
    publishedTeams {
      id
    }
    publishedForAllEmployees
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventsArchiveGQL extends Apollo.Query<EventsArchiveQuery, EventsArchiveQueryVariables> {
    override document = EventsArchiveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssignmentProgressDocument = gql`
    query assignmentProgress($eventId: ID!) {
  assignmentProgress(eventId: $eventId) {
    publishStatus
    progress
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssignmentProgressGQL extends Apollo.Query<AssignmentProgressQuery, AssignmentProgressQueryVariables> {
    override document = AssignmentProgressDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventCancelDataDocument = gql`
    query eventCancelData($where: EventFilterInput) {
  event(where: $where) {
    id
    name
    deployments {
      id
    }
    shifts {
      id
      requiredQuantity
    }
    childEvents {
      id
      name
      origin
      status
      shifts {
        id
        requiredQuantity
        deployments {
          id
        }
      }
      branch {
        id
        name
      }
      account {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventCancelDataGQL extends Apollo.Query<EventCancelDataQuery, EventCancelDataQueryVariables> {
    override document = EventCancelDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CancelSettingsInputDocument = gql`
    mutation cancelSettingsInput($input: SetCancelSettingsInput!) {
  setCancelSettings(input: $input) {
    cancelSettings {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelSettingsInputGQL extends Apollo.Mutation<CancelSettingsInputMutation, CancelSettingsInputMutationVariables> {
    override document = CancelSettingsInputDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CanceledDeploymentsDocument = gql`
    query canceledDeployments($where: CanceledShiftFilterInput) {
  canceledShifts(where: $where) {
    id
    creationTime
    employee {
      id
      firstName
      lastName
    }
    isRead
    message
    shift {
      id
      skill
      startDate
      endDate
      currentQuantity
      requiredQuantity
      section
      floorName
      event {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CanceledDeploymentsGQL extends Apollo.Query<CanceledDeploymentsQuery, CanceledDeploymentsQueryVariables> {
    override document = CanceledDeploymentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CheckInListDocument = gql`
    query checkInList($eventId: ID!) {
  checkInList(eventId: $eventId) {
    listDate
    event {
      id
      eventNumber
      checkInMasters {
        id
        firstName
        lastName
      }
      account {
        id
        name
      }
      hasInternalEvents
      name
      startDate
      origin
      checkInListReleasedAt
      checkInListReleased
      checkInListConfirmed
      checkInListConfirmedAt
      checkInListCompleted
      checkInListCompletedAt
      status
    }
    deployments {
      id
      status
      employeeStatus
      breakTime
      checkInTime
      checkOutTime
      oldCheckInTime
      oldCheckOutTime
      pendingCheckInTime
      pendingCheckOutTime
      personioAttendanceId
      personioContract {
        id
        signedUrl
        url
      }
      isReserve
      checkInTimeSetBy {
        id
      }
      shift {
        id
        section
        floorName
        startDate
        endDate
        skill
      }
      providedBy {
        id
        name
      }
      employee {
        id
        firstName
        lastName
        email
        dateOfBirth
        isOfAge
        personioId
        requireDailyContract
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckInListGQL extends Apollo.Query<CheckInListQuery, CheckInListQueryVariables> {
    override document = CheckInListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CheckInListCommentsDocument = gql`
    query checkInListComments($where: CheckInListCommentFilterInput!) {
  eventsCheckInListComments(where: $where) {
    id
    creationTime
    comment
    section
    createdBy {
      id
      firstName
      lastName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckInListCommentsGQL extends Apollo.Query<CheckInListCommentsQuery, CheckInListCommentsQueryVariables> {
    override document = CheckInListCommentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompleteCheckInListDocument = gql`
    mutation completeCheckInList($input: CompleteCheckInListInput!) {
  completeCheckInList(input: $input) {
    event {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompleteCheckInListGQL extends Apollo.Mutation<CompleteCheckInListMutation, CompleteCheckInListMutationVariables> {
    override document = CompleteCheckInListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompletePersonioDocument = gql`
    mutation completePersonio($input: CompletePersonioEventInput!) {
  completePersonioEvent(input: $input) {
    updateEventStatusResponseModel {
      event {
        id
      }
      successCount
      errorsCount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompletePersonioGQL extends Apollo.Mutation<CompletePersonioMutation, CompletePersonioMutationVariables> {
    override document = CompletePersonioDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConfirmCheckInListDocument = gql`
    mutation confirmCheckInList($input: ConfirmCheckInListInput!) {
  confirmCheckInList(input: $input) {
    event {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfirmCheckInListGQL extends Apollo.Mutation<ConfirmCheckInListMutation, ConfirmCheckInListMutationVariables> {
    override document = ConfirmCheckInListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCheckInListCommentDocument = gql`
    mutation createCheckInListComment($input: CreateCheckInListCommentInput!) {
  createCheckInListComment(input: $input) {
    checkInListComment {
      id
      comment
      creationTime
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCheckInListCommentGQL extends Apollo.Mutation<CreateCheckInListCommentMutation, CreateCheckInListCommentMutationVariables> {
    override document = CreateCheckInListCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCheckInMastersDocument = gql`
    mutation createCheckInMasters($input: CreateCheckInMastersInput!) {
  createCheckInMasters(input: $input) {
    string
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCheckInMastersGQL extends Apollo.Mutation<CreateCheckInMastersMutation, CreateCheckInMastersMutationVariables> {
    override document = CreateCheckInMastersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateEventDocument = gql`
    mutation createEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    event {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateEventGQL extends Apollo.Mutation<CreateEventMutation, CreateEventMutationVariables> {
    override document = CreateEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateEventTemplateDocument = gql`
    mutation createEventTemplate($input: CreateEventTemplateInput!) {
  createEventTemplate(input: $input) {
    eventTemplate {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateEventTemplateGQL extends Apollo.Mutation<CreateEventTemplateMutation, CreateEventTemplateMutationVariables> {
    override document = CreateEventTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateLocationDocument = gql`
    mutation createLocation($input: CreateEventLocationInput!) {
  createEventLocation(input: $input) {
    eventLocation {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateLocationGQL extends Apollo.Mutation<CreateLocationMutation, CreateLocationMutationVariables> {
    override document = CreateLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteEventDocumentDocument = gql`
    mutation deleteEventDocument($input: DeleteEventDocumentInput!) {
  deleteEventDocument(input: $input) {
    boolean
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEventDocumentGQL extends Apollo.Mutation<DeleteEventDocumentMutation, DeleteEventDocumentMutationVariables> {
    override document = DeleteEventDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteEventTemplateDocument = gql`
    mutation deleteEventTemplate($input: DeleteEventTemplateInput!) {
  deleteEventTemplate(input: $input) {
    boolean
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEventTemplateGQL extends Apollo.Mutation<DeleteEventTemplateMutation, DeleteEventTemplateMutationVariables> {
    override document = DeleteEventTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditEventDocument = gql`
    mutation editEvent($input: UpdateEventInput!) {
  updateEvent(input: $input) {
    event {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditEventGQL extends Apollo.Mutation<EditEventMutation, EditEventMutationVariables> {
    override document = EditEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventDocument = gql`
    query event($where: EventFilterInput) {
  event(where: $where) {
    id
    eventNumber
    name
    startDate
    endDate
    status
    type
    addressNotes
    comment
    description
    numberOfGuests
    branchId
    origin
    personioProjectId
    personioProjectName
    orderType
    isInternal
    deployments {
      id
      status
    }
    shifts {
      id
      shiftNumber
      skill
      startDate
      endDate
      section
      requiredQuantity
      currentQuantity
      floorName
    }
    address {
      id
      formattedAddress
      longitude
      latitude
    }
    location {
      id
      floors
    }
    orders {
      id
      creationTime
      tax
      net
      total
      status
      downloadUrl
    }
    childEvents {
      id
      origin
      orders {
        id
        downloadUrl
        creationTime
        status
        total
      }
      checkInListReleased
      checkInListConfirmed
      checkInListCompleted
      status
      account {
        id
        name
      }
    }
    publishedForAllEmployees
    publishedTeams {
      id
      name
    }
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventGQL extends Apollo.Query<EventQuery, EventQueryVariables> {
    override document = EventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventCompleteDataDocument = gql`
    query eventCompleteData($eventId: ID!) {
  completeEventData(eventId: $eventId) {
    totalDeployments
    totalRequiredDeployments
    confirmedDeployments
    insertedDeployments
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventCompleteDataGQL extends Apollo.Query<EventCompleteDataQuery, EventCompleteDataQueryVariables> {
    override document = EventCompleteDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventDashboardDocument = gql`
    query eventDashboard($eventId: ID!) {
  eventDashboardStats(eventId: $eventId) {
    account
    plannedCoasts
    plannedTotalCoasts
    coasts
    totalCoasts
    plannedHours
    plannedTotalHours
    hours
    totalHours
    plannedEmployees
    plannedTotalEmployees
    employees
    totalEmployees
    shifts {
      skill
      coasts
      employees
      hours
      plannedHours
      plannedEmployees
      plannedCoasts
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventDashboardGQL extends Apollo.Query<EventDashboardQuery, EventDashboardQueryVariables> {
    override document = EventDashboardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventDetailsDocument = gql`
    query eventDetails($where: EventFilterInput) {
  event(where: $where) {
    id
    eventNumber
    name
    startDate
    endDate
    status
    type
    addressNotes
    comment
    description
    numberOfGuests
    branchId
    origin
    personioProjectId
    personioProjectName
    isInternal
    orderType
    address {
      id
      formattedAddress
      longitude
      latitude
    }
    childEvents {
      id
    }
    publishedForAllEmployees
    publishedTeams {
      id
      name
    }
    message
    branchId
    location {
      id
      floors
    }
    shifts {
      id
      shiftNumber
      skill
      startDate
      endDate
      section
      floorName
      requiredQuantity
      currentQuantity
    }
    eventPublishCounters {
      id
      counter
      team {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventDetailsGQL extends Apollo.Query<EventDetailsQuery, EventDetailsQueryVariables> {
    override document = EventDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventDocumentsDocument = gql`
    query EventDocuments($where: EventDocumentFilterInput) {
  eventDocuments(where: $where) {
    id
    name
    url
    visibleTo {
      id
      skill
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventDocumentsGQL extends Apollo.Query<EventDocumentsQuery, EventDocumentsQueryVariables> {
    override document = EventDocumentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventEditDocument = gql`
    query eventEdit($where: EventFilterInput) {
  event(where: $where) {
    id
    eventNumber
    name
    startDate
    endDate
    status
    type
    addressNotes
    comment
    description
    numberOfGuests
    branchId
    origin
    personioProjectId
    personioProjectName
    orderType
    address {
      id
      formattedAddress
      longitude
      latitude
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventEditGQL extends Apollo.Query<EventEditQuery, EventEditQueryVariables> {
    override document = EventEditDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventHelpRequestsDocument = gql`
    query eventHelpRequests {
  eventHelpRequests {
    id
    branch {
      id
      name
    }
    event {
      id
      name
      branch {
        id
        name
      }
    }
    shift {
      id
      section
      skill
      startDate
      endDate
      requiredQuantity
    }
    quantity
    isCancel
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventHelpRequestsGQL extends Apollo.Query<EventHelpRequestsQuery, EventHelpRequestsQueryVariables> {
    override document = EventHelpRequestsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventRequestsDocument = gql`
    query eventRequests($viewMode: String!) {
  eventRequests(viewMode: $viewMode) {
    id
    name
    startDate
    endDate
    count
    eventRequests {
      id
      event {
        id
        name
      }
      employee {
        id
        firstName
        lastName
      }
      shift {
        id
        skill
        section
        floorName
        startDate
        endDate
        currentQuantity
        requiredQuantity
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventRequestsGQL extends Apollo.Query<EventRequestsQuery, EventRequestsQueryVariables> {
    override document = EventRequestsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventTemplateDocument = gql`
    query eventTemplate($where: EventTemplateFilterInput!) {
  eventTemplate(where: $where) {
    id
    templateName
    name
    type
    comment
    description
    personioProjectName
    personioProjectId
    branch {
      id
      name
    }
    location {
      id
      name
      floors
      address {
        id
        formattedAddress
        longitude
        latitude
      }
    }
    addressNotes
    address {
      id
      formattedAddress
      longitude
      latitude
    }
    numberOfGuests
    shifts {
      id
      section
      startDate
      endDate
      skill
      requiredQuantity
      floorName
      preFilledEmployees {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventTemplateGQL extends Apollo.Query<EventTemplateQuery, EventTemplateQueryVariables> {
    override document = EventTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventTemplatesDocument = gql`
    query eventTemplates {
  eventTemplates {
    id
    name
    templateName
    personioProjectName
    location {
      id
      name
      floors
      address {
        id
        formattedAddress
        longitude
        latitude
      }
    }
    branchId
    branch {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventTemplatesGQL extends Apollo.Query<EventTemplatesQuery, EventTemplatesQueryVariables> {
    override document = EventTemplatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventsDocument = gql`
    query events($where: EventFilterInput, $order: [EventSortInput!]) {
  myEvents(where: $where, order: $order) {
    id
    eventNumber
    name
    type
    personioProjectName
    personioProjectId
    organizer {
      id
      name
    }
    startDate
    endDate
    branch {
      id
      name
    }
    origin
    isInternal
    status
    orderType
    publishedTeams {
      id
    }
    publishedForAllEmployees
    deployments {
      id
      employeeStatus
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventsGQL extends Apollo.Query<EventsQuery, EventsQueryVariables> {
    override document = EventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateCheckInListDocument = gql`
    mutation generateCheckInList($input: GenerateCheckInListInput!) {
  generateCheckInList(input: $input) {
    string
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateCheckInListGQL extends Apollo.Mutation<GenerateCheckInListMutation, GenerateCheckInListMutationVariables> {
    override document = GenerateCheckInListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GeneratePersonioContractsDocument = gql`
    mutation generatePersonioContracts($input: CreateUnsignedDocumentsInput!) {
  createUnsignedDocuments(input: $input) {
    boolean
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GeneratePersonioContractsGQL extends Apollo.Mutation<GeneratePersonioContractsMutation, GeneratePersonioContractsMutationVariables> {
    override document = GeneratePersonioContractsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LocationsDocument = gql`
    query locations {
  eventLocations {
    id
    name
    floors
    address {
      formattedAddress
      longitude
      latitude
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LocationsGQL extends Apollo.Query<LocationsQuery, LocationsQueryVariables> {
    override document = LocationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MarkCanceledShiftAsReadDocument = gql`
    mutation markCanceledShiftAsRead($input: MarkCanceledShiftAsReadInput!) {
  markCanceledShiftAsRead(input: $input) {
    boolean
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkCanceledShiftAsReadGQL extends Apollo.Mutation<MarkCanceledShiftAsReadMutation, MarkCanceledShiftAsReadMutationVariables> {
    override document = MarkCanceledShiftAsReadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PersonioProjectsDocument = gql`
    query personioProjects {
  personioProjects {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PersonioProjectsGQL extends Apollo.Query<PersonioProjectsQuery, PersonioProjectsQueryVariables> {
    override document = PersonioProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PublishEventDocument = gql`
    mutation publishEvent($input: PublishEventInput!) {
  publishEvent(input: $input) {
    event {
      id
      isInternal
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PublishEventGQL extends Apollo.Mutation<PublishEventMutation, PublishEventMutationVariables> {
    override document = PublishEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PublishTeamsDocument = gql`
    mutation publishTeams($input: PublishEventTeamsInput!) {
  publishEventTeams(input: $input) {
    event {
      id
      publishedForAllEmployees
      publishedTeams {
        id
        name
      }
      eventPublishCounters {
        id
        counter
        team {
          id
          name
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PublishTeamsGQL extends Apollo.Mutation<PublishTeamsMutation, PublishTeamsMutationVariables> {
    override document = PublishTeamsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReleaseCheckInListDocument = gql`
    mutation releaseCheckInList($input: ReleaseCheckInListInput!) {
  releaseCheckInList(input: $input) {
    event {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReleaseCheckInListGQL extends Apollo.Mutation<ReleaseCheckInListMutation, ReleaseCheckInListMutationVariables> {
    override document = ReleaseCheckInListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendEventPushNotificationsDocument = gql`
    mutation sendEventPushNotifications($input: SendEventPushNotificationsInput!) {
  sendEventPushNotifications(input: $input) {
    int
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendEventPushNotificationsGQL extends Apollo.Mutation<SendEventPushNotificationsMutation, SendEventPushNotificationsMutationVariables> {
    override document = SendEventPushNotificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetEventInfoMessageDocument = gql`
    mutation setEventInfoMessage($input: SetEventInfoMessageInput!) {
  setEventInfoMessage(input: $input) {
    event {
      id
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetEventInfoMessageGQL extends Apollo.Mutation<SetEventInfoMessageMutation, SetEventInfoMessageMutationVariables> {
    override document = SetEventInfoMessageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TransmitTimesDocument = gql`
    mutation transmitTimes($input: TransmitCheckInListInput!) {
  transmitCheckInList(input: $input) {
    event {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TransmitTimesGQL extends Apollo.Mutation<TransmitTimesMutation, TransmitTimesMutationVariables> {
    override document = TransmitTimesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEventDocumentDocument = gql`
    mutation updateEventDocument($input: UpdateEventDocumentInput!) {
  updateEventDocument(input: $input) {
    eventDocument {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEventDocumentGQL extends Apollo.Mutation<UpdateEventDocumentMutation, UpdateEventDocumentMutationVariables> {
    override document = UpdateEventDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEventHelpRequestDocument = gql`
    mutation updateEventHelpRequest($input: UpdateEventHelpRequestInput!) {
  updateEventHelpRequest(input: $input) {
    eventHelpRequest {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEventHelpRequestGQL extends Apollo.Mutation<UpdateEventHelpRequestMutation, UpdateEventHelpRequestMutationVariables> {
    override document = UpdateEventHelpRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateLocationDocument = gql`
    mutation updateLocation($input: UpdateEventLocationInput!) {
  updateEventLocation(input: $input) {
    eventLocation {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateLocationGQL extends Apollo.Mutation<UpdateLocationMutation, UpdateLocationMutationVariables> {
    override document = UpdateLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEventOrderTypeDocument = gql`
    mutation updateEventOrderType($input: UpdateEventOrderTypeInput!) {
  updateEventOrderType(input: $input) {
    event {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEventOrderTypeGQL extends Apollo.Mutation<UpdateEventOrderTypeMutation, UpdateEventOrderTypeMutationVariables> {
    override document = UpdateEventOrderTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEventRequestDocument = gql`
    mutation updateEventRequest($input: UpdateEventRequestInput!) {
  updateEventRequest(input: $input) {
    eventRequest {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEventRequestGQL extends Apollo.Mutation<UpdateEventRequestMutation, UpdateEventRequestMutationVariables> {
    override document = UpdateEventRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEventStatusDocument = gql`
    mutation updateEventStatus($input: UpdateEventStatusInput!) {
  updateEventStatus(input: $input) {
    updateEventStatusResponseModel {
      event {
        id
      }
      successCount
      errorsCount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEventStatusGQL extends Apollo.Mutation<UpdateEventStatusMutation, UpdateEventStatusMutationVariables> {
    override document = UpdateEventStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEventTemplateDocument = gql`
    mutation updateEventTemplate($input: UpdateEventTemplateInput!) {
  updateEventTemplate(input: $input) {
    eventTemplate {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEventTemplateGQL extends Apollo.Mutation<UpdateEventTemplateMutation, UpdateEventTemplateMutationVariables> {
    override document = UpdateEventTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NavsDocument = gql`
    query navs {
  navigations(target: PLANNING) {
    id
    icon
    badge
    isTitle
    label
    link
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NavsGQL extends Apollo.Query<NavsQuery, NavsQueryVariables> {
    override document = NavsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MarkNotificationsAsReadDocument = gql`
    mutation markNotificationsAsRead($input: MarkNotificationsAsReadInput!) {
  markNotificationsAsRead(input: $input) {
    string
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkNotificationsAsReadGQL extends Apollo.Mutation<MarkNotificationsAsReadMutation, MarkNotificationsAsReadMutationVariables> {
    override document = MarkNotificationsAsReadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NotificationsDocument = gql`
    query notifications {
  notifications {
    id
    text
    title
    icon
    color
    link
    creationTime
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NotificationsGQL extends Apollo.Query<NotificationsQuery, NotificationsQueryVariables> {
    override document = NotificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrdersDocument = gql`
    query orders($where: OrderFilterInput) {
  orders(where: $where) {
    id
    status
    tax
    total
    net
    refNumber
    creationTime
    downloadUrl
    quote {
      id
      downloadUrl
    }
    serviceProvider {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrdersGQL extends Apollo.Query<OrdersQuery, OrdersQueryVariables> {
    override document = OrdersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAttendanceDocument = gql`
    mutation createAttendance($input: SetPersonioTimesInput!) {
  setPersonioTimes(input: $input) {
    personioResponse {
      data {
        id
      }
      success
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAttendanceGQL extends Apollo.Mutation<CreateAttendanceMutation, CreateAttendanceMutationVariables> {
    override document = CreateAttendanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateQuoteRequestsDocument = gql`
    mutation createQuoteRequests($input: CreateQuoteRequestsInput!) {
  createQuoteRequests(input: $input) {
    quotation {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateQuoteRequestsGQL extends Apollo.Mutation<CreateQuoteRequestsMutation, CreateQuoteRequestsMutationVariables> {
    override document = CreateQuoteRequestsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QuotationsDocument = gql`
    query quotations($where: QuotationFilterInput) {
  quotes(where: $where) {
    id
    refNumber
    version
    serviceProvider {
      id
      name
    }
    status
    items {
      id
      skill
      price
      total
      quantity
      shift {
        id
        shiftNumber
        startDate
        endDate
        skill
        floorName
        section
        hours
        requiredQuantity
        currentQuantity
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QuotationsGQL extends Apollo.Query<QuotationsQuery, QuotationsQueryVariables> {
    override document = QuotationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AvailableShiftsForAssignmentDocument = gql`
    query availableShiftsForAssignment($eventId: ID!, $employeeId: ID!) {
  availableShiftsForAssignment(eventId: $eventId, employeeId: $employeeId) {
    id
    shiftNumber
    currentQuantity
    requiredQuantity
    skill
    section
    floorName
    startDate
    endDate
    deployments {
      id
      status
      employeeStatus
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AvailableShiftsForAssignmentGQL extends Apollo.Query<AvailableShiftsForAssignmentQuery, AvailableShiftsForAssignmentQueryVariables> {
    override document = AvailableShiftsForAssignmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChildShiftsDocument = gql`
    query childShifts($shiftId: ID!) {
  childShifts(shiftId: $shiftId) {
    shiftId
    account
    branch
    quantity
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChildShiftsGQL extends Apollo.Query<ChildShiftsQuery, ChildShiftsQueryVariables> {
    override document = ChildShiftsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateEventHelpRequestDocument = gql`
    mutation createEventHelpRequest($inputs: [CreateEventHelpRequestInput!]!, $eventId: ID!) {
  createEventHelpRequest(inputs: $inputs, eventId: $eventId) {
    boolean
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateEventHelpRequestGQL extends Apollo.Mutation<CreateEventHelpRequestMutation, CreateEventHelpRequestMutationVariables> {
    override document = CreateEventHelpRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateShiftsDocument = gql`
    mutation createShifts($input: CreateShiftsInput!) {
  createShifts(input: $input) {
    boolean
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateShiftsGQL extends Apollo.Mutation<CreateShiftsMutation, CreateShiftsMutationVariables> {
    override document = CreateShiftsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShiftCurrentQuantityDocument = gql`
    query shiftCurrentQuantity($id: ID!) {
  shiftStatusQuery(id: $id) {
    currentQuantity
    deployments {
      id
      status
    }
    publishStatus
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShiftCurrentQuantityGQL extends Apollo.Query<ShiftCurrentQuantityQuery, ShiftCurrentQuantityQueryVariables> {
    override document = ShiftCurrentQuantityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteShiftDocument = gql`
    mutation deleteShift($input: DeleteShiftInput!) {
  deleteShift(input: $input) {
    boolean
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteShiftGQL extends Apollo.Mutation<DeleteShiftMutation, DeleteShiftMutationVariables> {
    override document = DeleteShiftDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeploymentShiftsDocument = gql`
    query deploymentShifts($shiftId: ID!) {
  deploymentShifts(shiftId: $shiftId) {
    shifts {
      id
      requiredQuantity
      account {
        id
        name
      }
      deployments {
        id
        employee {
          id
          firstName
          lastName
        }
      }
      branch {
        id
        name
      }
    }
    deployments {
      id
      status
      employeeStatus
      isReserve
      shift {
        id
      }
      employee {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeploymentShiftsGQL extends Apollo.Query<DeploymentShiftsQuery, DeploymentShiftsQueryVariables> {
    override document = DeploymentShiftsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShiftDocument = gql`
    query shift($where: ShiftFilterInput, $quoteItemWhere: QuoteItemFilterInput) {
  shift(where: $where) {
    id
    currentQuantity
    quoteItems(where: $quoteItemWhere) {
      id
      quantity
      quotation {
        id
        serviceProvider {
          id
          name
        }
      }
    }
    childShifts {
      id
      requiredQuantity
      branch {
        id
        name
      }
      account {
        id
        name
      }
    }
    deployments {
      id
      employee {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShiftGQL extends Apollo.Query<ShiftQuery, ShiftQueryVariables> {
    override document = ShiftDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShiftByFloorDocument = gql`
    query shiftByFloor($id: ID!) {
  shiftGroupedByFloors(eventId: $id) {
    floor
    shifts {
      id
      shiftNumber
      startDate
      endDate
      section
      skill
      requiredQuantity
      currentQuantity
      floorName
      eventId
      event {
        id
        origin
      }
      childShifts {
        id
        account {
          id
          name
        }
        branch {
          id
          name
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShiftByFloorGQL extends Apollo.Query<ShiftByFloorQuery, ShiftByFloorQueryVariables> {
    override document = ShiftByFloorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShiftsDocument = gql`
    query shifts($where: ShiftFilterInput) {
  shifts(where: $where) {
    id
    shiftNumber
    currentQuantity
    requiredQuantity
    skill
    section
    floorName
    startDate
    endDate
    deployments {
      id
      status
      employeeStatus
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShiftsGQL extends Apollo.Query<ShiftsQuery, ShiftsQueryVariables> {
    override document = ShiftsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateShiftDocument = gql`
    mutation updateShift($input: UpdateShiftInput!) {
  updateShift(input: $input) {
    shift {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateShiftGQL extends Apollo.Mutation<UpdateShiftMutation, UpdateShiftMutationVariables> {
    override document = UpdateShiftDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }