import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarComponent } from './topbar/topbar.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';



@NgModule({
    declarations: [
        TopbarComponent,
        SidenavComponent
    ],
  exports: [
    TopbarComponent,
    SidenavComponent
  ],
  imports: [
    SharedModule,
    RouterModule
  ]
})
export class NavigationModule { }
