<div>
  <!--  {{isAuth$|async}}-->
  <ng-container>
    <!--        <app-topbar></app-topbar>-->
    <app-top-bar></app-top-bar>
    <app-default-layout-sidenav></app-default-layout-sidenav>
    <!--        <app-sidenav></app-sidenav>-->

  </ng-container>
  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->
  <div class="ml-[250px]">
    <div class="page-content pt-[7.25rem] pb-10 px-3 bg-surface-50 h-screen">
      <div class="w-full-px-4">
        <p-toast></p-toast>
        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- End Page-content -->
    <!--    <Footer />-->
  </div>
  <!-- end main content-->
  <!--  <RightSidebar />-->
</div>



