import { Injectable } from '@angular/core';
import {
  CancelSettingsGQL,
  CancelSettingsInputGQL,
  LoginGQL,
  MeGQL,
  RequestPasswordResetGQL, RequestPasswordResetInput,
  SetCancelSettingsInput, SetEmployeePasswordGQL, SetEmployeePasswordInput,
  UserLoginInput
} from '../../generated/graphql';
import {map, take, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {Apollo} from 'apollo-angular';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _isAuthenticated = new BehaviorSubject(false);

  constructor(
    private loginGQL: LoginGQL,
    private meGQL: MeGQL,
    private setCancelSettingsGQL: CancelSettingsInputGQL,
    private apollo: Apollo,
    private requestPasswordResetGQL: RequestPasswordResetGQL,
    private setEmployeePasswordGQL: SetEmployeePasswordGQL,
    private cancelSettingsGQL: CancelSettingsGQL
  ) { }

  me() {
    return this.meGQL.watch().valueChanges.pipe(take(1));
  }

  get isAuthenticated(): Observable<boolean> {
    return this._isAuthenticated.asObservable();
  }

  getCancelSettings() {
    return this.cancelSettingsGQL.watch().valueChanges
  }

  logout() {
    this._isAuthenticated.next(false);
    localStorage.removeItem('token');
    this.apollo.client.resetStore();
  }

  autoLogin(): boolean {
    if(localStorage.getItem('token')) {
      // @ts-ignore
      const decode: any = jwt_decode(localStorage.getItem('token'));
      const exp = decode.exp;
      const date = new Date(0);
      date.setUTCSeconds(exp);
      if(Date.now() > date.getTime()) {
        return false;
      } else {
        this._isAuthenticated.next(true);
        return true;
      }
    } else {
     return false;
    }
  }

  login(input: UserLoginInput) {
    return this.loginGQL.mutate({
      input
    }).pipe(tap(({data, errors, loading}) => {
      if(data?.userLogin?.string) {
        this._isAuthenticated.next(true);
        localStorage.setItem('token',data?.userLogin.string);
      }
      return {
        data,
        errors,
        loading
      }
    }))
  }

  setCancelSettings(input: SetCancelSettingsInput){
    return this.setCancelSettingsGQL.mutate({
      input
    }, {
      refetchQueries: [{
        query: this.meGQL.document
      }]
    })
  }

  onRequestReset(input: RequestPasswordResetInput){
    return this.requestPasswordResetGQL.mutate({
      input
    })
  }

  changePassword(input: SetEmployeePasswordInput){
    return this.setEmployeePasswordGQL.mutate({
      input
    })
  }
}
