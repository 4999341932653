import {Injectable} from '@angular/core';
import {
  CheckInListGQL,
  CompleteCheckInListGQL,
  ConfirmCheckInListGQL,
  CreateAddressInput,
  CreateCheckInMastersGQL,
  CreateCheckInMastersInput,
  CreateEventGQL,
  CreateEventHelpRequestGQL,
  CreateEventHelpRequestInput,
  CreateEventInput,
  CreateEventLocationInput,
  CreateLocationGQL,
  CreateShiftInput,
  EventCancelDataGQL,
  EventCompleteDataGQL,
  EventDashboardGQL,
  EventFilterInput,
  EventGQL,
  EventHelpRequestsGQL,
  EventLocation,
  EventRequestsGQL,
  EventsGQL,
  EventSortInput,
  EventStatus,
  EventTemplateGQL,
  EventTemplatesGQL,
  EventType,
  LocationsGQL,
  PublishEventGQL,
  ReleaseCheckInListGQL,
  ReleaseCheckInListInput,
  SendEventPushNotificationsGQL,
  SetDeploymentTimesGQL,
  SetDeploymentTimesInput,
  SetEventInfoMessageGQL,
  SetEventInfoMessageInput,
  ShiftByFloorGQL,
  SortEnumType,
  UpdateEventHelpRequestGQL,
  UpdateEventHelpRequestInput,
  UpdateEventLocationInput,
  UpdateEventRequestGQL,
  UpdateEventRequestInput,
  UpdateEventStatusGQL,
  UpdateEventStatusInput,
  UpdateLocationGQL,
  UpdateShiftGQL,
  UpdateShiftInput,
  CanceledDeploymentsGQL,
  EditEventGQL,
  UpdateEventInput,
  GenerateCheckInListInput,
  GenerateCheckInListGQL,
  TransmitTimesGQL,
  EventDocumentsGQL,
  EventDocumentFilterInput,
  AssignmentProgressGQL,
  DeleteEventDocumentGQL,
  DeleteEventDocumentInput,
  UpdateEventDocumentGQL,
  UpdateEventDocumentInput,
  MarkCanceledShiftAsReadGQL,
  MarkCanceledShiftAsReadInput,
  PersonioProjectsGQL,
  EventOrderType,
  CreateAttendanceGQL,
  SetPersonioTimesInput,
  UpdateEventOrderTypeGQL,
  UpdateEventOrderTypeInput,
  EventEditGQL,
  EventDetailsGQL,
  CanceledShiftFilterInput,
  EventsArchiveGQL,
  SendEventPushNotificationsInput,
  CreateCheckInListCommentGQL,
  CreateCheckInListCommentInput,
  CheckInListCommentsGQL,
  CheckInListCommentFilterInput, CompletePersonioGQL, GeneratePersonioContractsGQL
} from '../../generated/graphql';
import {map} from 'rxjs/operators';
import {FormGroup} from '@angular/forms';
import {EventWizzardService} from './event-wizzard/event-wizzard.service';
import {WatchQueryOptionsAlone} from 'apollo-angular/types';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  public shiftForm: any;
  public shiftForms: any[] = [];
  public baseForm: FormGroup;
  public addressData: CreateAddressInput;
  eventType: EventType = EventType.Festival;
  eventOrderType: EventOrderType| null = null;
  branch = '';
  location: EventLocation;
  personioProjectId: any;
  public template = false;

  constructor(
    private eventsGQL: EventsGQL,
    private createEventGQL: CreateEventGQL,
    private eventDashboardGQL: EventDashboardGQL,
    public shiftByFloorGQL: ShiftByFloorGQL,
    private createEventHelpRequestsGQL: CreateEventHelpRequestGQL,
    private eventHelpRequestsGQL: EventHelpRequestsGQL,
    private updateEventHelpRequestGQL: UpdateEventHelpRequestGQL,
    public checkInListGQL: CheckInListGQL,
    private setDeploymentTimesGQL: SetDeploymentTimesGQL,
    private locationsGQL: LocationsGQL,
    private createEventLocationGQL: CreateLocationGQL,
    private updateLocationGQL: UpdateLocationGQL,
    private updateShiftGQL: UpdateShiftGQL,
    public eventGQL: EventGQL,
    private cancelEventData: EventCancelDataGQL,
    private updateStatusGQL: UpdateEventStatusGQL,
    private eventRequestsGQL: EventRequestsGQL,
    private updateEventRequestGQL: UpdateEventRequestGQL,
    private eventWizardService: EventWizzardService,
    private releaseCheckINGQL: ReleaseCheckInListGQL,
    private completeEventDataGQL: EventCompleteDataGQL,
    private confirmCheckInListGQL: ConfirmCheckInListGQL,
    private completeCheckInListGQL: CompleteCheckInListGQL,
    private setEventInfoMessageGQL: SetEventInfoMessageGQL,
    private sendEventNotificationGQL: SendEventPushNotificationsGQL,
    private eventTemplatesGQL: EventTemplatesGQL,
    private eventTemplateGQL: EventTemplateGQL,
    private createCheckInMastersGQL: CreateCheckInMastersGQL,
    private publishEventGQL: PublishEventGQL,
    private canceledDeploymentsGQL: CanceledDeploymentsGQL,
    private updateEventGQL: EditEventGQL,
    private generateCheckInListGQL: GenerateCheckInListGQL,
    private transmitCheckInListGQL: TransmitTimesGQL,
    private eventDocumentsGQL: EventDocumentsGQL,
    private assignmentProgress: AssignmentProgressGQL,
    private deleteEventDocument: DeleteEventDocumentGQL,
    private updateEventDocument: UpdateEventDocumentGQL,
    private markCanceledShiftAsReadGQL : MarkCanceledShiftAsReadGQL,
    private personioProjectsGQL: PersonioProjectsGQL,
    private createAttendanceGQL: CreateAttendanceGQL,
    private updateEventOrderTypeGQL: UpdateEventOrderTypeGQL,
    private eventEditGQL: EventEditGQL,
    private eventDetailsGQL: EventDetailsGQL,
    private archiveGQL: EventsArchiveGQL,
    private createCheckInListCommentGQL: CreateCheckInListCommentGQL,
    private checkInListCommentsGQL: CheckInListCommentsGQL,
    private completePersonioGQL: CompletePersonioGQL,
    private createUnsignedPersonioDocuments: GeneratePersonioContractsGQL
  ) {
  }

  generatePersonioContracts(eventId: string) {
    return this.createUnsignedPersonioDocuments.mutate({
      input: {
        eventId
      }
    })
  }

  completePersonioEvent(eventId: string) {
    return this.completePersonioGQL.mutate({
      input: {
        eventId
      }
    })
  }


  getPersonioProjects() {
    return this.personioProjectsGQL.watch().valueChanges
  }

  getCheckInListComments(where: CheckInListCommentFilterInput) {
    return this.checkInListCommentsGQL.watch({
      where
    }).valueChanges
  }

  createCheckInListComment(input: CreateCheckInListCommentInput) {
    return this.createCheckInListCommentGQL.mutate({
      input
    },{
      refetchQueries: [
        {
          query: this.checkInListCommentsGQL.document,
          variables: {
            where: {
              eventId: {
                eq: input.eventId
              }
            }
          }
        }
      ]
    })
  }

  getEventEditData(id: string) {
    return this.eventEditGQL.watch({
      where: {
        id: {
          eq: id
        }
      }
    }).valueChanges;
  }

  getEventDetails(id: string) {
    return this.eventDetailsGQL.watch({
      where: {
        id: {
          eq: id
        }
      }
    }).valueChanges;
  }

  createAttendance(input: SetPersonioTimesInput, eventId: string) {
    return this.createAttendanceGQL.mutate({
      input
    },{
      refetchQueries: [
        {query: this.checkInListGQL.document, variables: {
            eventId
          }}
      ]
    })
  }

  updateEventOrderType(input: UpdateEventOrderTypeInput) {
    return this.updateEventOrderTypeGQL.mutate({
      input
    },{
      refetchQueries: [
        {
          query: this.eventGQL.document,
          variables: {
            where: {
              id: {
                eq: input.eventId
              }
            }
          }
        }
      ]
    })
  }

  publishEvent(id: string) {
    return this.publishEventGQL.mutate({
      input: {
        id
      }
    })
  }

  deleteDocument(eventId: string , input : DeleteEventDocumentInput ){
    return this.deleteEventDocument.mutate({
      input
    }, {
      refetchQueries: [{
        query: this.eventDocumentsGQL.document,
        variables: {
          event: {
            id: {
              eq: eventId
            }
          }
        }
      }]
    })
  }

  updateDocument( eventId: string, input : UpdateEventDocumentInput ){
    return this.updateEventDocument.mutate({
      input
    } , {
      refetchQueries: [{
        query: this.eventDocumentsGQL.document,
        variables: {
          event: {
            id: {
              eq: eventId
            }
          }
        }
      }]
    })
  }

  getAssignmentProgress(id: string) {
    return this.assignmentProgress.watch({
      eventId: id
    }).valueChanges.pipe(map(({data}) => {
      return data.assignmentProgress;
    }))
  }

  transmitCheckInList(id: string) {
    return this.transmitCheckInListGQL.mutate({
      input: {
        id
      }
    }, {
      refetchQueries: [
        {query: this.checkInListGQL.document, variables: {
            eventId: id
          }}
      ]
    })
  }

  generateCheckInList(input: GenerateCheckInListInput) {
    return this.generateCheckInListGQL.mutate({
      input
    })
  }

  clearWizard() {
    this.eventType = EventType.Festival;
    // @ts-ignore
    this.baseForm = undefined;
    this.addressData = {};
    this.shiftForms = [];
    this.shiftForm = undefined;
    // @ts-ignore
    this.eventWizardService.additionalInfoForm = undefined;
    this.branch = '';
    // @ts-ignore
    this.location = null;
  }

  updateEvent(input: UpdateEventInput) {
    return this.updateEventGQL.mutate({
      input
    },{
      refetchQueries: [{
        query: this.eventsGQL.document,
        variables: {
          where: {
            or: [{
              status: {
                eq: EventStatus.Active
              }
            },{
              status: {
                eq: EventStatus.Blocked
              },
            },{
              status: {
                eq: EventStatus.ActiveCancelRequest
              },
            },{
              status: {
                eq: EventStatus.ReadyToComplete
              },
            }]
          },
          order: [
            {
              startDate: SortEnumType.Asc
            }
          ]
        }
      }]
    })
  }

  getCanceledDeployments(where?: CanceledShiftFilterInput) {
    return this.canceledDeploymentsGQL.watch({
      where
    }).valueChanges
  }

  markCanceledShifts(input: MarkCanceledShiftAsReadInput){
    return this.markCanceledShiftAsReadGQL.mutate({
      input
    }, {
      refetchQueries: [
        {
          query: this.canceledDeploymentsGQL.document
        }
      ]
    })
  }

  createCheckInMasters(input: CreateCheckInMastersInput) {
    return this.createCheckInMastersGQL.mutate({
      input
    },{
      refetchQueries: [
        {query: this.checkInListGQL.document, variables: {
          eventId: input.eventId
          }}
      ]
    })
  }

  releaseCheckInList(input: ReleaseCheckInListInput ) {
    return this.releaseCheckINGQL.mutate({
      input
    },{
      refetchQueries: [
        {
          query:  this.checkInListGQL.document,
          variables: {
            eventId: input.eventId,
          }
        }
      ]
    })
  }

  getTemplates() {
    return this.eventTemplatesGQL.watch().valueChanges;
  }

  getTemplate(id: string) {
    return this.eventTemplateGQL.watch({
      where: {
        id: {
          eq: id
        }
      }
    }).valueChanges;
  }

  confirmCheckInList(eventId: string) {
    return this.confirmCheckInListGQL.mutate({
      input: {
        id: eventId
      }
    },{
      refetchQueries: [
        {
          query:  this.checkInListGQL.document,
          variables: {
            eventId: eventId,
          }
        }
      ]
    })
  }

  completeCheckInList(eventId: string) {
    return this.completeCheckInListGQL.mutate({
      input: {
        id: eventId
      }
    },{
      refetchQueries: [
        {
          query:  this.checkInListGQL.document,
          variables: {
            eventId: eventId,
          }
        }
      ]
    })
  }

  getEventCompleteData(eventId: string) {
    return this.completeEventDataGQL.watch({
      eventId
    }).valueChanges;
  }

  getEvents(where?: EventFilterInput, order?: EventSortInput[], options?: WatchQueryOptionsAlone) {
    return this.eventsGQL.watch({
      where,
      order
    },options).valueChanges;
  }

  getArchive(where?: EventFilterInput, order?: EventSortInput[], options?: WatchQueryOptionsAlone) {
    return this.archiveGQL.watch({
      where,
      order
    },options).valueChanges;
  }

  getRequests(viewMode: string) {
    return this.eventRequestsGQL.watch({
      viewMode
    }).valueChanges;
  }

  updateEventRequest(input: UpdateEventRequestInput) {
    return this.updateEventRequestGQL.mutate({input}, {
      refetchQueries: [{
        query: this.eventRequestsGQL.document,
        variables: {
          viewMode: 'employee'
        }
      },{
        query: this.eventRequestsGQL.document,
        variables: {
          viewMode: 'event'
        }
      }]
    });
  }

  updateEventStatus(input: UpdateEventStatusInput) {
    return this.updateStatusGQL.mutate({input}, {
      refetchQueries: [{
        query: this.eventsGQL.document
      }]
    })
  }


  getCancelData(id: string) {
    return this.cancelEventData.watch({
      where: {
        id: {
          eq: id
        }
      }
    }).valueChanges;
  }

  getEvent(id: string) {
    return this.eventGQL.watch({
      where: {
        id: {
          eq: id
        }
      }
    }).valueChanges;
  }

  updateShift(input: UpdateShiftInput, eventId: string) {
    return this.updateShiftGQL.mutate({
      input
    }, {
      refetchQueries: [{
        query: this.shiftByFloorGQL.document, variables: {
          id: eventId
        }
      }]
    })
  }

  createLocation(input: CreateEventLocationInput) {
    return this.createEventLocationGQL.mutate({
      input
    }, {
      refetchQueries: [{
        query: this.locationsGQL.document
      }]
    })
  }

  updateLocation(input: UpdateEventLocationInput) {
    return this.updateLocationGQL.mutate({
      input
    }, {
      refetchQueries: [{
        query: this.locationsGQL.document
      }]
    })
  }

  getLocations() {
    return this.locationsGQL.watch().valueChanges;
  }

  setDeploymentTimes(input: SetDeploymentTimesInput, eventId: string) {
    return this.setDeploymentTimesGQL.mutate({
      input
    }, {
      refetchQueries: [{
        query: this.checkInListGQL.document, variables: {
          eventId
        }
      }]
    })
  }

  updateEventHelpRequest(input: UpdateEventHelpRequestInput) {
    this.updateEventHelpRequestGQL.mutate({
      input
    }, {
      refetchQueries: [
        {query: this.eventHelpRequestsGQL.document}
      ]
    }).subscribe((res) => {
      console.log(res)
    })
  }

  getCheckInList(eventId: string) {
    return this.checkInListGQL.watch({
      eventId
    },{
      // pollInterval: 5000,
    }).valueChanges
  }

  getEventHelpRequests() {
    return this.eventHelpRequestsGQL.watch().valueChanges;
  }

  createEventHelpRequest(inputs: CreateEventHelpRequestInput[], eventId: string) {
    return this.createEventHelpRequestsGQL.mutate({
      inputs,
      eventId
    })
  }

  getShiftsByFloor(id: string) {
    return this.shiftByFloorGQL.watch({
      id
    }).valueChanges;
  }

  getDashboardStats(eventId: string) {
    return this.eventDashboardGQL.watch({
      eventId
    }).valueChanges;
  }

  createEvent() {
    const createShiftInputs: CreateShiftInput[] = [];
    if (!this.location) {
      this.shiftForm.value.positions.forEach((position: any) => {
        position.shifts.forEach((shift: any) => {
          shift.fromDate.setSeconds(0);
          shift.toDate.setSeconds(0);
          createShiftInputs.push({
            skill: position.type,
            section: shift.section,
            startDate: shift.fromDate,
            endDate: shift.toDate,
            requiredQuantity: shift.required,
            employees: shift.employees,
          })
        })
      })
    } else {
      this.shiftForms.forEach(form => {
        form.value.positions.forEach((position: any) => {
          position.shifts.forEach((shift: any) => {
            shift.fromDate.setSeconds(0);
            shift.toDate.setSeconds(0);
            createShiftInputs.push({
              skill: position.type,
              section: shift.section,
              startDate: shift.fromDate,
              endDate: shift.toDate,
              requiredQuantity: shift.required,
              floorName: shift.floorName,
              employees: shift.employees,
            })
          })
        })
      })
    }
    this.baseForm?.value.startDate.setSeconds(0);
    this.baseForm?.value.endDate.setSeconds(0);
    const input: CreateEventInput = {
      // @ts-ignore
      addressInput: this.addressData,
      addressNotes: this.baseForm?.value.addressNotes,
      branchId: this.branch,
      comment: this.eventWizardService.additionalInfoForm.value.comment,
      createShiftInputs,
      description: this.eventWizardService.additionalInfoForm.value.description,
      isInternal: true,
      locationId: this.location?.id,
      name: this.baseForm?.value.title,
      numberOfGuests: this.baseForm?.value.numberOfGuests,
      startDate: this.baseForm?.value.startDate,
      endDate: this.baseForm?.value.endDate,
      type: this.eventType,
      withTemplate: this.template,
      personioProjectId: this.personioProjectId?.id,
      personioProjectName: this.personioProjectId?.attributes?.name,
      orderType: this.eventOrderType,

    }
    return this.createEventGQL.mutate({
        input
      }, {
        refetchQueries: [{
          query: this.eventsGQL.document,
          variables: {
            where: {
              or: [{
                status: {
                  eq: EventStatus.Active
                }
              },{
                status: {
                  eq: EventStatus.Blocked
                },
              },{
                status: {
                  eq: EventStatus.ActiveCancelRequest
                },
              },{
                status: {
                  eq: EventStatus.ReadyToComplete
                },
              }]
            },
            order: [
              {
                startDate: SortEnumType.Asc
              }
            ]
          }
        }]
      }
    )
  }

  onSelectType(type: EventType) {
    this.eventType = type;
  }

  onSetMessage(input: SetEventInfoMessageInput){
    return this.setEventInfoMessageGQL.mutate({
      input
    });
  }

  onSendNotification(input: SendEventPushNotificationsInput){
    return this.sendEventNotificationGQL.mutate({
      input
    });
  }

  getEventDocuments(where?: EventDocumentFilterInput) {
    return this.eventDocumentsGQL.watch({
      where,
    }).valueChanges.pipe(map(({data, errors, loading}) => {
        return {
          data,
          loading,
          errors
        }
      }
    ))
  }

}
