import { Component, OnInit } from '@angular/core';
import {NavigationModel, NavsGQL, NavsQuery} from '../../../generated/graphql';
import {Observable} from "rxjs";
import {ApolloQueryResult} from "@apollo/client";

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  items$: Observable<ApolloQueryResult<NavsQuery>> = new Observable<ApolloQueryResult<NavsQuery>>();
  constructor(
    private navsGQL: NavsGQL
  ) { }

  hasItems(item: any) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
  ngOnInit(): void {
    this.items$ = this.navsGQL.watch().valueChanges;
  }

}
