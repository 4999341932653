<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!--      <button type="button" class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn" id="vertical-menu-btn">-->
      <!--        <i class="fa fa-fw fa-bars"></i>-->
      <!--      </button>-->
    </div>


    <div class="d-flex align-items-center">
      <div class="px-3">
        <i class="pi pi-th-large text-lg text-gray-700"></i>
        <a [href]="platformUrl" target="_blank" class="text-lg font-medium ml-2 text-gray-700">Covento Plattform</a>
      </div>
      <div class="px-3">
        <i class="pi pi-th-large text-lg text-gray-700"></i>
        <a [href]="myCoventoUrl" target="_blank" class="text-lg font-medium ml-2 text-gray-700">My Covento</a>
      </div>
      <div ngbDropdown>
        <button pButton type="button" class="p-button-rounded p-button-text" ngbDropdownToggle>
          <i *ngIf="notifications" class="pi pi-bell" pBadge [value]="notifications.length.toString()"></i>
        </button>
        <div class="scroller_notification" ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <div class="p-3">
            <div class="flex flex-row justify-content-between align-items-center">
              <h5 class="m-0 font-size-16">Benachrichtigungen</h5>
              <p class="mb-0"><a type="button" class="small font-normal" (click)="readNotifications(notifications)">Alle als gelesen markieren</a></p>
            </div>
            <hr />
            <div *ngFor="let notification of notifications">
              <app-notification-item [notification]="notification"></app-notification-item>
            </div>
          </div>
        </div>
      </div>
      <div ngbDropdown class="mx-auto my-auto pr-6">
        <button pButton type="button" class="p-button-rounded p-button-text" ngbDropdownToggle>
          <p-avatar *ngIf="!loading" shape="circle" [style]="{'background-color': '#817CCD', 'color': '#ffffff'}"
                    [label]="me.firstName.charAt(0) +  me.lastName.charAt(0)" size="xLarge"></p-avatar>
          <span class="ml-2 text-dark" *ngIf="!loading">{{me.firstName}}</span>
          <i class="mdi mdi-chevron-down font-size-20 mb-0 text-dark"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="px-2">
          <div class="px-3 flex flex-row justify-content-start align-items-center">
            <p-avatar *ngIf="!loading" shape="circle" [style]="{'background-color': '#817CCD', 'color': '#ffffff'}"
                      [label]="me.firstName.charAt(0) +  me.lastName.charAt(0)" size="xLarge"></p-avatar>
            <div class="flex flex-column mt-2">
              <span class="ml-2 text-dark text-lg font-medium" *ngIf="!loading">{{me.firstName}} {{me.lastName}}</span>
              <span class="ml-2 text-gray-700 text-sm" *ngIf="!loading">{{me.email}}</span>
            </div>
          </div>
          <hr />
          <ul class="list-none px-3">
            <li class="mt-2 mb-2" style="cursor : pointer" (click)="onLogOut()">
              <i class="mdi mdi-exit-to-app text-lg text-primary"></i>
              <span class="text-lg ml-1">Logout</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>
