import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {EventService} from '../../event/event.service';

@Component({
  selector: 'app-assignment-progress',
  templateUrl: './assignment-progress.component.html',
  styleUrls: ['./assignment-progress.component.scss']
})
export class AssignmentProgressComponent implements OnInit, OnDestroy {

  @Input()
  progress: any;

  @Input()
  eventId: string;

  sub: Subscription;

  constructor(
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    if(this.eventId) {
      this.sub = this.eventService.getAssignmentProgress(this.eventId).subscribe((res) => {
        this.progress = res
      })
    }
  }

  ngOnDestroy() {
    if(this.sub) {
      this.sub.unsubscribe()
    }
  }

  getTooltip(color: string) {
    if(color === 'success') {
      return 'Alle Mitarbeiter veröffentlicht'
    }
    return 'Mitarbeiter nicht veröffentlicht'
  }

}
