<div id="layout-wrapper">
  <!--  {{isAuth$|async}}-->
  <ng-container>
    <app-topbar></app-topbar>
    <app-sidenav></app-sidenav>
  </ng-container>
  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <p-toast></p-toast>
        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- End Page-content -->
    <!--    <Footer />-->
  </div>
  <!-- end main content-->
  <!--  <RightSidebar />-->
</div>
