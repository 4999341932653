<p-toast></p-toast>
<div class="row surface-section px-4 py-2 md:px-6 lg:px-8">
  <div class="col-6">
    <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
    <div class="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
      <div>
        <div class="font-medium text-5xl text-900">{{title}}</div>
        <ng-content select="[branch]"></ng-content>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="mt-3 lg:mt-0 flex flex-row justify-content-end float-end">
      <ng-content select="button"></ng-content>
      <usecsv-button
        importerKey="7130ce65-ab57-40a1-8074-fda91ff38670"
        [onData]="onDataCallback.bind(this)"
        *ngIf="showEmployeeImportButton"
      >
        <button class="flatfile-button ms-2" customUsecsvButton>Import</button>
      </usecsv-button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ng-content select="[eventDashboardButtons]"></ng-content>
    </div>
  </div>
  <hr />
</div>
