<div class="bg-surface-0 hidden lg:block absolute lg:fixed left-0 top-0 z-10 select-none shadow w-[250px] h-screen overflow-y-auto" id="app-sidebar-12">
        <div class="flex flex-col">
          <div class="p-6 flex items-center justify-center">
            <img alt="Logo" class="h-[80px]" src="assets/images/logo.svg">
          </div>
          <div>
            <ul class="list-none px-4 pb-4 pt-0 m-0">
              @for (item of menuItems; track $index) {
                <li>
                  <div class="border-primary border-r-2 bg-primary-50 p-4 flex items-center justify-between text-surface-600">
                    <span class="font-medium text-sm text-surface-900">{{item.title | translate}}</span>
                  </div>
                  <ul class="list-none p-0 m-0">
                    @for(child of item.items; track $index) {
                      <li>
                        <a [routerLink]="item.link + child.link" routerLinkActive="border-primary border-r-2 bg-primary-50" class="flex items-center cursor-pointer p-4 text-surface-700 hover:bg-surface-100 duration-150 transition-colors text-purple-500" pRipple>
                          <i class="mr-2" [ngClass]="child.icon"></i>
                          <span class="font-medium">{{child.label | translate}}</span>
                        </a>
                      </li>
                    }
<!--                    <li>-->
<!--                      <a class="flex items-center cursor-pointer p-4 text-surface-700 dark:text-surface-100 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors border-r-2 border-transparent" enterActiveClass="animate-slidedown"-->
<!--                         enterClass="hidden" leaveActiveClass="animate-slideup" leaveToClass="hidden" pRipple pStyleClass="@next">-->
<!--                        <i class="pi pi-chart-line mr-2"></i>-->
<!--                        <span class="font-medium">Reports</span>-->
<!--                        <i class="pi pi-chevron-down ml-auto"></i>-->
<!--                      </a>-->
<!--                      <ul class="list-none py-0 pl-4 pr-0 m-0 hidden overflow-y-hidden transition-all duration-[400ms] ease-in-out">-->
<!--                        <li>-->
<!--                          <a class="flex items-center cursor-pointer p-4 text-surface-700 dark:text-surface-100 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors border-r-2 border-transparent" enterActiveClass="animate-slidedown"-->
<!--                             enterClass="hidden" leaveActiveClass="animate-slideup" leaveToClass="hidden" pRipple pStyleClass="@next">-->
<!--                            <i class="pi pi-chart-line mr-2"></i>-->
<!--                            <span class="font-medium">Revenue</span>-->
<!--                            <i class="pi pi-chevron-down ml-auto"></i>-->
<!--                          </a>-->
<!--                          <ul class="list-none py-0 pl-4 pr-0 m-0 hidden overflow-y-hidden transition-all duration-[400ms] ease-in-out">-->
<!--                            <li>-->
<!--                              <a class="flex items-center cursor-pointer p-4 text-surface-700 dark:text-surface-100 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors border-r-2 border-transparent" pRipple>-->
<!--                                <i class="pi pi-table mr-2"></i>-->
<!--                                <span class="font-medium">View</span>-->
<!--                              </a>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                              <a class="flex items-center cursor-pointer p-4 text-surface-700 dark:text-surface-100 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors border-r-2 border-transparent" pRipple>-->
<!--                                <i class="pi pi-search mr-2"></i>-->
<!--                                <span class="font-medium">Search</span>-->
<!--                              </a>-->
<!--                            </li>-->
<!--                          </ul>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                          <a class="flex items-center cursor-pointer p-4 text-surface-700 dark:text-surface-100 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors border-r-2 border-transparent" pRipple>-->
<!--                            <i class="pi pi-chart-line mr-2"></i>-->
<!--                            <span class="font-medium">Expenses</span>-->
<!--                          </a>-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a class="flex items-center cursor-pointer p-4 text-surface-700 dark:text-surface-100 hover:bg-surface-100 dark:hover:bg-surface-700 duration-150 transition-colors border-r-2 border-transparent" pRipple>-->
<!--                        <i class="pi pi-comments mr-2"></i>-->
<!--                        <span class="font-medium">Messages</span>-->
<!--                        <span class="inline-flex items-center justify-center ml-auto bg-purple-500 text-surface-0 dark:text-surface-900 rounded-full" style="min-width: 1.5rem; height: 1.5rem">3</span>-->
<!--                      </a>-->
<!--                    </li>-->
                  </ul>
                </li>
              }
            </ul>
          </div>
<!--          <div class="mt-auto border-t border-surface p-4 flex justify-between">-->
<!--            <a class="cursor-pointer inline-flex items-center justify-center border-2 border-surface hover:bg-surface-100 dark:hover:bg-surface-700 text-surface-600 dark:text-surface-200 transition-colors duration-150 rounded-full" pRipple style="width:40px;height:40px">-->
<!--              <i class="pi pi-file text-xl"></i>-->
<!--            </a>-->
<!--            <a class="cursor-pointer inline-flex items-center justify-center border-2 border-surface hover:bg-surface-100 dark:hover:bg-surface-700 text-surface-600 dark:text-surface-200 transition-colors duration-150 rounded-full" pRipple style="width:40px;height:40px">-->
<!--              <i class="pi pi-star text-xl"></i>-->
<!--            </a>-->
<!--            <a class="cursor-pointer inline-flex items-center justify-center border-2 border-surface hover:bg-surface-100 dark:hover:bg-surface-700 text-surface-600 dark:text-surface-200 transition-colors duration-150 rounded-full" pRipple style="width:40px;height:40px">-->
<!--              <i class="pi pi-cog text-xl"></i>-->
<!--            </a>-->
<!--            <a class="cursor-pointer inline-flex items-center justify-center bg-pink-500 text-white border-2 border-pink-600 hover:bg-pink-600 text-surface-600 dark:text-surface-200 transition-colors duration-150 rounded-full" pRipple style="width:40px;height:40px">-->
<!--              <i class="pi pi-sign-out text-xl"></i>-->
<!--            </a>-->
<!--          </div>-->
        </div>
      </div>



