import {Component, Input, OnInit} from '@angular/core';
import {Maybe} from '../../../generated/graphql';

@Component({
  selector: 'app-gmaps',
  templateUrl: './gmaps.component.html',
  styleUrls: ['./gmaps.component.scss']
})
export class GmapsComponent implements OnInit {

  @Input()
  longitude: number;
  @Input()
  latitude: number;
  @Input()
  address: Maybe<string>| undefined;
  @Input()
  addressNotes: Maybe<string>| undefined;
  constructor() { }

  ngOnInit(): void {}

}
