import {Component, Input, OnInit} from '@angular/core';
import {ShiftService} from '../../../services/shift.service';

@Component({
  selector: 'app-shift-dispatch-total-childs',
  templateUrl: './shift-dispatch-total-childs.component.html',
  styleUrls: ['./shift-dispatch-total-childs.component.scss']
})
export class ShiftDispatchTotalChildsComponent implements OnInit {

  @Input()
  shiftId: any;
  shifts: any;

  @Input()
  isOrganizer : boolean;
  constructor(
    private shiftService: ShiftService
  ) { }

  ngOnInit(): void {
  }

}
