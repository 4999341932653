import { Injectable } from '@angular/core';
import {FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class EventWizzardService {

  public additionalInfoForm: FormGroup;
  changeStartDate: boolean = false;
  constructor() { }
}
