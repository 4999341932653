import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  Branch,
  ChildShiftListModel, CoventoCustomer,
  Customer,
  Shift,
  User,
  UserRolePermissionScope, UserRolePermissionTarget
} from '../../../generated/graphql';
import {BranchService} from '../../services/branch.service';
import {ShiftService} from '../../services/shift.service';
import {CustomerService} from '../../customer/customer.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-shift-child-manage-list',
  templateUrl: './shift-child-manage-list.component.html',
  styleUrls: ['./shift-child-manage-list.component.scss']
})
export class ShiftChildManageListComponent implements OnInit , OnDestroy{


  @Input()
  me: User|undefined;
  @Input()
  shift: Shift;
  childs : ChildShiftListModel[]= []
  selectedShifts: any[] = [];
  @Input()
  branches: Branch[];
  customers: CoventoCustomer[];
  @Input()
  isOrganizer: boolean = false;
  selectedBranch: any;
  selectedCustomer: any;
  selectedQuantity: any;
  customerSub: Subscription;
  shiftSub: Subscription;

  constructor(
    private shiftService: ShiftService,
    private customerService: CustomerService,
    private branchService: BranchService
  ) { }

  ngOnInit(): void {
    this.shiftSub = this.shiftService.getChilds(this.shift.id).subscribe(({data}) => {
     this.childs = [...data.childShifts as ChildShiftListModel[]]
    });
    this.customerSub = this.customerService.getCustomers({
      customerPrices: {
        some: {
          skill : {
            eq: this.shift.skill
          }
        }
      }
    }).subscribe(({data}) => {
      this.customers = [...data.customers as CoventoCustomer[]];
    })
  }

  ngOnDestroy() {
    if(this.customerSub) {
      this.customerSub.unsubscribe();
    }
    if(this.shiftSub) {
      this.shiftSub.unsubscribe();
    }
  }

  showCustomers() {
    return this.me?.role?.permissions?.some(p => p.scope === UserRolePermissionScope.Create && p.target === UserRolePermissionTarget.Quote);
  }

  showBranches() {
    return this.me?.role?.permissions?.some(p => p.scope === UserRolePermissionScope.Create && p.target === UserRolePermissionTarget.EventHelpRequest);
  }

  showAddButton() {
    return !!this.selectedCustomer || !!this.selectedBranch;
  }

  getAddButtonDisabled() {
    return this.selectedQuantity > this.getMaxAddValue();
  }

  getRemoveButtonDisabled() {
    return this.selectedQuantity > this.getMaxRemoveValue();
  }

  showRemoveButton() {
    if(!this.showAddButton()) {
      return false;
    }
    return true;
    // if(this.isOrganizer) {
    //   return this.childs.some(c => c.account === this.selectedCustomer.name || c.branch === this.selectedBranch.name )
    // } else {
    //   return this.childs.some(c => c.branch === this.selectedBranch.name)
    // }
  }

  getMaxAddValue() {
    const filteredChild = this.childs.filter(s => (s.shiftId === this.shift.id))
    if(this.selectedShifts.length > 0 && filteredChild.length > 0) {
      const sumA = this.selectedShifts.reduce((accumulator, value) => {
        return accumulator + value.quantity;
      }, 0);
      const sumB = filteredChild.reduce((accumulator, value) => {
        return accumulator + value.quantity;
      }, 0);
      return this.shift.requiredQuantity - this.shift.currentQuantity - sumA - sumB;
    }
    if(this.selectedShifts.length > 0) {
      const sum = this.selectedShifts.reduce((accumulator, value) => {
        return accumulator + value.quantity;
      }, 0);
      return this.shift.requiredQuantity - this.shift.currentQuantity - sum
    }
    if(filteredChild.length > 0) {
      const sum = filteredChild.reduce((accumulator, value) => {
        return accumulator + value.quantity;
      }, 0);
      return this.shift.requiredQuantity - this.shift.currentQuantity - sum
    }
    return this.shift.requiredQuantity - this.shift.currentQuantity;
  }
  getMaxRemoveValue() {
    let child;
    if(this.isOrganizer) {
      child =  this.childs.find(c => c.account === this.selectedCustomer.name)
    } else {
      child =  this.childs.find(c => c.branch === this.selectedBranch.name)
    }
    if(child) {
      return child.quantity;
    }
    return 0;
  }

  onChangeBranch() {
    this.selectedCustomer = null;
  }

  onChangeCustomer() {
    this.selectedBranch = null;
  }

  onRemoveShift(shiftId: string) {
  }

  onAdd() {

  }

  onRemove() {

  }

  clearForm() {
    this.selectedBranch = null;
    this.selectedCustomer = null;
    this.selectedQuantity = null;
  }

}
